<template>
    <div>
        <div class="position-relative">
            <button class="remoteButtons" @click="previouspage">Previous Page</button>
        </div>
      <gmap-map
        :center="center"
        :options="mapStyle"
        :zoom="11" class="map_full">
        <gmap-polygon :paths="paths"></gmap-polygon>
      </gmap-map>
   
    </div>
  </template>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/gmap-vue@1.2.2/dist/gmap-vue.min.js"></script>
  <script>
  import axios from '@axios'
  export default {
    data() {
      return {
        latitude:null,
        longitude:null,
        center: { 
            lat: this.latitude, 
            lng: this.longitude,
        },      
        locations: [],
        setpolygonline:null,
        paths: [],
        Editable:false,
      //   markerOptions: {
      //   url: require('../../assets/img/pin.png'),
      //   size: {width: 60, height: 90, f: 'px', b: 'px',},
      //   scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      // },
        mapStyle: {
          fullscreenControl: false, 
          mapTypeControl: false,
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
    //   styles: [
    //       {elementType: 'geometry', stylers: [{color: '#e5e4e0'}]},
    //       {elementType: 'labels.text.stroke', stylers: [{color: '#e5e4e0'}]},
    //       {elementType: 'labels.text.fill', stylers: [{color: '#000'}]},
    //       {
    //           featureType: 'administrative.locality',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#526e84'}]
    //       },
    //       {
    //           featureType: 'poi',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#000'}]
    //       },
    //       {
    //           featureType: 'poi.park',
    //           elementType: 'geometry',
    //           stylers: [{color: '#b8dc9c'}]
    //       },
    //       {
    //           featureType: 'poi.park',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#000'}]
    //       },
    //       {
    //           featureType: 'road',
    //           elementType: 'geometry',
    //           stylers: [{color: '#f9a664'}]
    //       },
    //       {
    //           featureType: 'road',
    //           elementType: 'geometry.stroke',
    //           stylers: [{color: '#bf9343'}]
    //       },
    //       {
    //           featureType: 'road',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#6f5126'}]
    //       },
    //       {
    //           featureType: 'road.highway',
    //           elementType: 'geometry',
    //           stylers: [{color: '#f7fabf'}]
    //       },
    //       {
    //           featureType: 'road.highway',
    //           elementType: 'geometry.stroke',
    //           stylers: [{color: '#b1b864'}]
    //       },
    //       {
    //           featureType: 'road.highway',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#818181'}]
    //       },
    //       {
    //           featureType: 'transit',
    //           elementType: 'geometry',
    //           stylers: [{color: '#3d4e9e'}]
    //       },
    //       {
    //           featureType: 'transit.station',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#fff'}]
    //       },
    //       {
    //           featureType: 'water',
    //           elementType: 'geometry',
    //           stylers: [{color: '#8accec'}]
    //       },
    //       {
    //           featureType: 'water',
    //           elementType: 'labels.text.fill',
    //           stylers: [{color: '#fff'}]
    //       },
    //       {
    //           featureType: 'water',
    //           elementType: 'labels.text.stroke',
    //           stylers: [{color: '#fff'}]
    //       }
    //   ]
    }
      }
    },
  
    // props: {
    //   mapdata:[Object, Array],
    //   setpolygonline:[Object, Array, Number],
    // },
   
    mounted() {
        this.setLocationLatLng()
    },
   
    methods: {
      setLocationLatLng: function() {
        axios.post('showcustompolygon',{
                location:this.$route.params.location,
                city: this.$route.params.city,
                country: this.$route.params.country,
                // time:this.$route.params.time,
                datetime:this.$route.params.datetime,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.center.lat=response.data.Coordinates.latitude
                this.center.lng=response.data.Coordinates.longitude
                this.setpolygonline=response.data.Coordinates.coordinates
                this.paths = this.setpolygonline  
            }).catch(error => {
                console.log("Something went wrong in setpolygon()")
            })
        },
        previouspage(){
            console.log("working properly")
            this.$router.push({ name: 'dashboard'});
        }
      }
    }
  </script>
<style>
.back{
    position: absolute !important;
    top: 20px;
    left:25px;
}
</style>
  