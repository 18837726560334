<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <div id="map"></div>
          <b-button @click="setDrawingMode('MARKER')">Switch to Marker</b-button>
          <b-button @click="setDrawingMode('CIRCLE')">Switch to Circle</b-button>
          <b-button @click="setDrawingMode('POLYGON')">Switch to Polygon</b-button>
          <b-button @click="setDrawingMode('POLYLINE')">Switch to Polyline</b-button>
          <b-button @click="setDrawingMode('RECTANGLE')">Switch to Rectangle</b-button>
          <b-button @click="clearDrawing">Clear Drawing</b-button>
        </b-col>

        <b-modal v-model="showModal" title="My Modal" @ok="onOk">
          <!-- Modal content goes here -->
          <!-- <p>This is the modal content.</p> -->

          <b-form-input v-model="typeName" placeholder="Enter your name"></b-form-input>
          <p>Cordinates: {{ globalcoordinates }}</p>
          <p>Type: {{ globalpolygonType }}</p>
          <p>Min Lat: {{ globalminLat }}</p>
          <p>Max Lat: {{ globalmaxLat }}</p>
          <p>Min Long: {{ globalminLong }}</p>
          <p>Max Long: {{ globalmaxLong }}</p>



          <template #modal-footer>
            <b-button @click="onSubmit" variant="primary">Submit</b-button>
            <b-button @click="onCancel">Cancel</b-button>
          </template>

        </b-modal>
      </b-row>
    </b-container>
  </div>
</template>
  
<script>
import { BContainer, BRow, BCol, BButton, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton, BFormInput
  },
  data() {
    return {
      map: null,
      drawingManager: null,
      drawnShapes: [], // store drawn shapes to later clear them
      typeName: '',
      globalcoordinates: null,
      globalpolygonType: null,
      globalminLat: null,
      globalmaxLat: null,
      globalminLong: null,
      globalmaxLong: null,
      showModal: false,
      formDataArray: [], // Array to store form data
    };
  },
  mounted() {
    this.loadGoogleMapsApi();
  },
  methods: {
    loadGoogleMapsApi() {
      const apiKey = "AIzaSyCq8M1FYn1IUbs-4XH-H9RbhueFy6PDQBc";
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=drawing`;

      const scriptPromise = new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });

      document.head.appendChild(script);

      scriptPromise.then(() => {
        this.initMap();
      });
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
      });

      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          icon:
            "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        circleOptions: {
          fillColor: "#ffff00",
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      this.drawingManager.setMap(this.map);

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        event => {
          this.overlayCompleteHandler(event);
        }
      );
    },
    overlayCompleteHandler(event) {
      const overlay = event.overlay;
      this.drawnShapes.push(overlay); // store the drawn shape in the array
      const type = event.type;

      let coordinates;
      let polygonType;

      let minLat, maxLat, minLong, maxLong;

      switch (type) {
        case 'marker':
          this.globalpolygonType = 'marker';
          this.globalcoordinates = overlay.getPosition().toJSON();
          break;
        case 'circle':
          this.globalpolygonType = 'circle';

          const center = overlay.getCenter().toJSON();
          const radius = overlay.getRadius();
          this.globalcoordinates = { center, radius };

          ({ minLat, maxLat, minLong, maxLong } = this.calculateCircleBoundingBox(overlay));
          break;
        case 'polygon':
          this.globalpolygonType = 'polygon';
          this.globalcoordinates = overlay.getPath().getArray().map(latLng => latLng.toJSON());
          ({ minLat, maxLat, minLong, maxLong } = this.calculatePolygonBoundingBox(overlay.getPath()));
          break;
        case 'polyline':
          this.globalpolygonType = 'polyline';
          this.globalcoordinates = overlay.getPath().getArray().map(latLng => latLng.toJSON());
          ({ minLat, maxLat, minLong, maxLong } = this.calculatePolygonBoundingBox(overlay.getPath()));
          break;
        case 'rectangle':
          this.globalpolygonType = 'rectangle';

          const bounds = overlay.getBounds();
          const ne = bounds.getNorthEast().toJSON();
          const sw = bounds.getSouthWest().toJSON();
          this.globalcoordinates = { ne, sw };
          ({ minLat, maxLat, minLong, maxLong } = this.calculateRectangleBoundingBox(overlay.getBounds()));
          break;
        default:
          this.globalpolygonType = '';
          this.globalcoordinates = null;
          break;
      }
      alert(`this.globalCoordinates for ${type}: ${JSON.stringify(this.globalcoordinates)} ${this.globalpolygonType}`);

      if (minLat !== undefined && maxLat !== undefined && minLong !== undefined && maxLong !== undefined) {
        // Do something with the obtained information
        console.log('min_lat:', minLat);
        console.log('max_lat:', maxLat);
        console.log('min_long:', minLong);
        console.log('max_long:', maxLong);
        this.globalminLat = minLat;
        this.globalmaxLat = maxLat;
        this.globalminLong = minLong;
        this.globalmaxLong = maxLong;
        this.showModal = true


      }

    },
    calculatePolygonBoundingBox(path) {
      const bounds = new google.maps.LatLngBounds();

      path.getArray().forEach(latLng => {
        bounds.extend(latLng);
      });

      const minLat = bounds.getSouthWest().lat();
      const maxLat = bounds.getNorthEast().lat();
      const minLong = bounds.getSouthWest().lng();
      const maxLong = bounds.getNorthEast().lng();

      return { minLat, maxLat, minLong, maxLong };
    },

    calculateRectangleBoundingBox(bounds) {
      const minLat = bounds.getSouthWest().lat();
      const maxLat = bounds.getNorthEast().lat();
      const minLong = bounds.getSouthWest().lng();
      const maxLong = bounds.getNorthEast().lng();

      return { minLat, maxLat, minLong, maxLong };
    },

    calculateCircleBoundingBox(circle) {
      const center = circle.getCenter();
      const minLat = center.lat() - circle.getRadius() / 111.32;
      const maxLat = center.lat() + circle.getRadius() / 111.32;
      const minLong = center.lng() - circle.getRadius() / (111.32 * Math.cos((center.lat() * Math.PI) / 180));
      const maxLong = center.lng() + circle.getRadius() / (111.32 * Math.cos((center.lat() * Math.PI) / 180));

      return { minLat, maxLat, minLong, maxLong };
    },
    setDrawingMode(mode) {
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType[mode]);
    },
    clearDrawing() {
      // Clear all drawn shapes on the map
      this.drawnShapes.forEach(shape => {
        shape.setMap(null);
      });
      this.drawnShapes = [];
      this.resetFunction();
    },
    resetFunction(){
      this.formDataArray = [];
      this.typeName = '';
      this.globalcoordinates= null;
      this.globalpolygonType= null;
      this.globalminLat= null;
      this.globalmaxLat= null;
      this.globalminLong= null;
      this.globalmaxLong= null;
    },
    onOk() {
      console.log('OK button clicked');
      // You can perform additional actions here
    },
    onSubmit() {
      console.log('Submit button clicked');
      // Perform actions when the user clicks the "Submit" button
      // For example, you can submit a form or make an API call
      // After completing the action, you can close the modal if needed

      const formData = {
        name: this.typeName,
        coordinates: this.globalcoordinates,
        polygonType: this.globalpolygonType,
        minLat: this.globalminLat,
        maxLat: this.globalmaxLat,
        minLong: this.globalminLong,
        maxLong: this.globalmaxLong,
      };


      // Push the object into the array
      this.formDataArray.push(formData);

      // Optionally, you can reset the form data after submission
      this.typeName = '';
      // Reset other form data variables as needed

      // Log or use the formDataArray as needed
      console.log('Form Data Array:', this.formDataArray);
      this.showModal = false;
    },
    onCancel() {
      console.log('Cancel button clicked');
      // Perform actions when the user clicks the "Cancel" button
      // For example, you can reset form fields
      // After completing the action, you can close the modal if needed
      this.showModal = false;
    },
  },
};
</script>
  
<style scoped>
#map {
  height: 100vh;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
  