<template>
  <div>
    <l-map class="map_full" :zoom="zoom" :center="center">
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-polygon :lat-lngs="polygon" :color="color"></l-polygon>
    </l-map>
  </div>
</template>
  
<script>
import { LMap, LTileLayer, LPolygon } from 'vue2-leaflet'
import axios from 'axios'
import proj4 from 'proj4'

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
  },
  data() {
    return {
      zoom: 13,
      center: [51.505, -0.09],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      polygon: [
      [72.6545966, 18.9618557],
        [72.6545977, 18.9617513],
        [72.6544263, 18.9617549],
        [72.6545966, 18.9618557]
      ],
      color: 'red'
    }
  },
}
</script>
  
<style>
@import '~leaflet/dist/leaflet.css'
</style>