<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card >
                        <h5 class='pageHeading'>User Profile</h5>
                        <b-row>
                            <b-col md="9" class='mt-3'>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">User Name:</p>
                                        <p class="subHeadBase">{{ userName }}</p>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Name:</p>
                                        <p class="subHeadBase">{{ firstName }} {{ lastName }}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                    <p class="headBase">Email:</p>
                                        <p class="subHeadBase">{{ emailID }}</p>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Request Used:</p>
                                        <p class="subHeadBase">{{ requestUsed }}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Profile Image:</p>
                                        <b-form-file v-model="selectedImage" id="image" name="image" accept="image/*" :state="Boolean(selectedImage)" placeholder=""></b-form-file>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <b-button class="gap5" variant="primary" @click="handleFileUpload">Upload</b-button>
                                        <b-button class="gap5" variant="primary" @click="handleFileDelete">Remove</b-button>
                                    </div>
                                </div>
                            </b-col>
                            <b-col md="3" class='mt-3'>
                                <b-card class="bg-blue mb-2">
                                    <p class="headDetails">{{plan}}</p>
                                    <p class="mb-0">Plan Details</p>
                                </b-card>
                                <b-card class="bg-blue mb-2">
                                    <p class="headDetails">{{ numberOfRequest }}</p>
                                    <p class="mb-0">Number of request</p>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" class='mt-3'>
                                <b-card class="bg-blue2 mb-2">
                                    <p class="headDetails2" v-if="this.generate=='' && TokenCheck" ref="token">{{ this.token }}</p>
                                    <p class="headDetails2" v-else-if="TokenCheck" ref="token">{{ this.generate }}</p>
                                    <p class="headDetails2" v-else>Token Copied...</p>
                                </b-card>
                          </b-col>
                          <b-col md="6" class='mt-3'>
                            <b-button class="gap5" variant="primary" @click="copyToClipboard">Copy Token</b-button>
                            <b-button  class="gap5" v-if="userType == 'Admin'" variant="primary"  @click="showModal">Generate Token</b-button>
                          </b-col>
                        </b-row>
                        <!-- <b-button id="show-btn">Open Modal</b-button> -->
                        <b-modal ref="my-modal" hide-footer title="Generate New Token">
                            <div class="d-block text-center">
                                <h6 class="text-left">Are you sure, you want to generate new TOKEN KEY?</h6>
                            </div>
                            <div class="d-flex justify-content-end">
                                <b-button class="mt-3 mx-2" variant="outline-danger" block @click="hideModal">Close</b-button>
                                <b-button class="mt-3 mx-2" variant="outline-primary" block @click="generateToken">Generate Token</b-button>
                            </div>
                        </b-modal>
                </b-card>
            </b-col>           
        </b-row>
    </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script>
import axios from '@axios'
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormFile
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormFile
    },
    data() {
        return {
            userType: localStorage.getItem('userType'),
            userName:'',
            firstName:'',
            lastName:'',
            emailID:'',
            plan:'',
            numberOfRequest:'',
            requestUsed:'',
            token:'',
            selectedImage:null,
            image: {
                file: null,
            },
            TokenCheck:true,
            generate:'',
        }
    },
    methods: {
        showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
      },
        profileInfo(){
            axios.post('profile', {
                headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                // this.username = response.data
                console.log("response.data", response.data.profile_info)
                this.userName = response.data.profile_info.Username
                this.firstName = response.data.profile_info.First_name
                this.lastName = response.data.profile_info.Last_name
                this.emailID = response.data.profile_info.Email
                this.plan = response.data.profile_info.Plan
                this.numberOfRequest = response.data.profile_info.No_of_request
                this.requestUsed = response.data.profile_info.Request_used
                this.token=localStorage.getItem('token')

            }).catch(error => {
                console.log("Failed")
            })
        },
        handleFileUpload() {
            this.image.file = this.selectedImage;
            const formData = new FormData();
            formData.append('image', this.image.file);
            axios.post('uploadimage', formData)
            .then(response => {
                localStorage.setItem('image_url', response.data.imageurl)
                window.location.reload();
            }).catch(error=>{
                alert("Profile Image not updated ")
            })
        },
        handleFileDelete(){
            axios.post('deleteimage')
            .then(response => {
                localStorage.removeItem('image_url');
                window.location.reload();
            }).catch(error=>{
                alert("Failed")
            })
        },
        generateToken:function(){
            axios.post('createtoken/',{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response=>{
                // if(this.baseToken != ''){
                //     this.prevToken= this.baseToken
                // }else{
                //     this.prevToken= localStorage.getItem('token')
                // }
                localStorage.setItem('token', response.data.token)
                this.generate = localStorage.getItem('token')
            }).catch(error=>{
                alert("some error")
            })
            this.toggleModal();
        },
        copyToClipboard() {
        this.TokenCheck=false;
        const paragraphElement = this.$refs.token;
        const range = document.createRange();
        range.selectNode(paragraphElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        setTimeout(() => {
        this.TokenCheck =true;
                        }, 2000);
        },
    },
    mounted() {
        this.profileInfo();
    }
}

</script>
<style>
.headBase{
    font-size: 16px;
    font-weight: 600;
    width: 40%;
}
.headDetails{
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
}
.bg-blue{
    background-color: #e5f1ff !important;
    height: 100px !important;
}
.pageHeading{
    font-size: 20px;
    font-weight: 600;
}
</style>