<template>
    <div>
        <div class="position-relative maxGet">
            <div id="map"></div>
            <div class="remoteButtons" v-if="this.latitude !=0.0 && this.longitude !=0.0 && (this.citydata!=='' && this.citydata!==null )&& this.countrydata!==null">
              <b-button @click="setDrawingMode('POLYGON')" v-b-tooltip.hover title="Polygon"><img src="../../assets/img/polygon.png" alt="" /></b-button>
              <!-- <b-button @click="setDrawingMode('POLYLINE')" v-b-tooltip.hover title="Polyline"><img src="../../assets/img/polyline.png" alt="" /></b-button> -->
              <b-button @click="onSubmit2" v-b-tooltip.hover title="Save"><b-icon icon="save-fill"></b-icon></b-button>
              <b-button @click="modalConfrimation" v-b-tooltip.hover title="Save as"><b-icon icon="save2-fill"></b-icon></b-button>
              <b-button @click="clearDrawing" v-b-tooltip.hover title="Clear"><b-icon icon="x-circle"></b-icon></b-button>
            </div>
            <!-- <b-button @click="setDrawingMode('MARKER')">Switch to Marker</b-button>
            <b-button @click="setDrawingMode('CIRCLE')">Switch to Circle</b-button> -->
            
            <!-- <b-button @click="setDrawingMode('RECTANGLE')">Switch to Rectangle</b-button> -->
          
          <b-modal ref="my-modal" hide-footer title="Generate New Token">
              <div class="d-block text-center">
                  <h6 class="text-left">Polygon Co-ordinates with the same name already exist</h6>
                  <h6 class="text-left">Are you sure, you want to OverWrite Polygon?</h6>
              </div>
              <div class="d-flex justify-content-end">
                  <b-button class="mt-3 mx-2" variant="outline-danger" block @click="hideModal">Close</b-button>
                  <b-button class="mt-3 mx-2" variant="outline-primary" block @click="onSubmit">OverWrite Coordinates</b-button>
              </div>
          </b-modal>
          <b-modal v-model="showModal" title="Preview" @ok="onOk" v-if="this.countrydata!=null && this.citydata!=null" hide-footer>
            <!-- Modal content goes here -->
            <!-- <p>This is the modal content.</p> -->
  
            <b-form-input v-model="typeName" class="CustomPolyName" placeholder="Enter location name"></b-form-input>
            <b-form-input v-model="typeNamestate" class="CustomPolyName" placeholder="Enter state name"></b-form-input>
            <b-button class="submitbutton" @click="onSubmit" variant="primary">Submit</b-button>
            <b-button class="cancelbutton" @click="onCancel">Cancel</b-button>
            <!-- <p class="textBase"><span>Cordinates:</span> {{ globalcoordinates }}</p>
            <p class="textBase"><span>Type:</span> {{ globalpolygonType }}</p>
            <p class="textBase"><span>Min Lat:</span> {{ globalminLat }}</p>
            <p class="textBase"><span>Max Lat:</span> {{ globalmaxLat }}</p>
            <p class="textBase"><span>Min Long:</span> {{ globalminLong }}</p>
            <p class="textBase"><span>Max Long:</span> {{ globalmaxLong }}</p> -->
  
  
  
            <!-- <template #modal-footer>
              <b-button @click="onSubmit" variant="primary">Submit</b-button>
              <b-button @click="onCancel">Cancel</b-button>
            </template> -->
  
          </b-modal>
        </div>
    </div>
  </template>
    
  <script>
  import axios from '@axios'
  import { BButton, BFormInput } from 'bootstrap-vue';
  
  export default {
    components: {
      
      BButton, BFormInput
    },
    data() {
      return {
        apiUrl: process.env.VUE_APP_API_URL,
        getclickindex:{
          city:null,
          country:null,
          state:null,
          index:null
        },
        map: null,
        drawingManager: null,
        drawnShapes: [], // store drawn shapes to later clear them
        typeName: '',
        typeNamestate:'',
        overlayDataArray:[],
        globalcoordinates: null,
        globalpolygonType: null,
        globalminLat: null,
        globalmaxLat: null,
        globalminLong: null,
        globalmaxLong: null,
        showModal: false,
        formDataArray: [], // Array to store form data
        latitude:0.0,
        longitude:0.0,
        polygon_coordinates:[],
        polygondata:'',
        min_latitude:'',
        max_latitude:'',
        min_longitude:'',
        max_longitude:'',
        source:'',
      };
    },

    props: {
    citydata:[Object],
    countrydata:[Object],
    statedata:[Object],
    sourcedata:[Object],
    userdata:[Object,Number]
    // callFunction:[Object, Array, Number],
    },
    mounted() {
      this.loadGoogleMapsApi();
    },
    methods: {
      // overlayCompleteHandler(event) {
      // const overlay = event.overlay;
      // // Your existing overlayCompleteHandler logic
      // },
      executeOnButtonClick() {
        console.log("THIS FUNCTION ")
      // Add your Google Maps event listener code here
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          event => {
            this.overlayCompleteHandler(event);
          }
      );
    },
    saveverifiedcity(location,country,state){
      console.log("function is called verify")
      axios.post('saveverifiedcities',{
          location:location,
          country:country,
          state:state,
        },{
        // { headers: { 'Access-Control-Allow-Origin': '*' }}
        }).then(response => {
            this.verificationstatus=response.data
        }).catch(error => {
            console.log("Something went wrong in reportInfo()")
        })
    },
    // Your other methods here
      loadGoogleMapsApi() {
        const apiKey = process.env.VUE_APP_API_URL;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=drawing&language=en&region=US`;
        
        const scriptPromise = new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
        });
  
        document.head.appendChild(script);
  
        scriptPromise.then(() => {
          this.initMap();
        });
      },
      initMap() {
        console.log("init is called")
        if ( this.latitude !== 0.0 && this.longitude !== 0.0) {
          this.map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: this.latitude, lng: this.longitude },
            zoom: 11,
            disableDefaultUI: true,
          });
          console.log(this.polygon_coordinates,"initMap2")
          if (this.polygon_coordinates !== undefined){
            this.polygon_coordinates.forEach((item,index) => {
              var polygon = new google.maps.Polygon({
                paths: item.paths,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
              });
            polygon.setMap(this.map);
          });
        }
      }else{
            this.map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: this.latitude, lng: this.longitude },
            zoom:2,
            disableDefaultUI: true 
            });
          }
    
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: null,
          // drawingControl: true,
          // drawingControlOptions: {
          //   position: google.maps.ControlPosition.TOP_CENTER,
          //   drawingModes: [
          //     // google.maps.drawing.OverlayType.MARKER,
          //     // google.maps.drawing.OverlayType.CIRCLE,
          //     // google.maps.drawing.OverlayType.POLYGON,
          //     // google.maps.drawing.OverlayType.POLYLINE,
          //     // google.maps.drawing.OverlayType.RECTANGLE,
          //   ],
          // },
          markerOptions: {
            icon:
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          },
          circleOptions: {
            fillColor: "#ffff00",
            fillOpacity: 1,
            strokeWeight: 5,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        });
  
        this.drawingManager.setMap(this.map);
  
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          event => {
            this.overlayCompleteHandler(event);
          }
        );
      },
      overlayCompleteHandler(event) {
        const overlay = event.overlay;
        this.drawnShapes.push(overlay);
        const type = event.type;
  
        let coordinates;
        let polygonType;
        let minLat, maxLat, minLong, maxLong;
  
        switch (type) {
          case 'marker':
            this.globalpolygonType = 'marker';
            this.globalcoordinates = overlay.getPosition().toJSON();
            break;
          case 'circle':
            this.globalpolygonType = 'circle';
  
            const center = overlay.getCenter().toJSON();
            const radius = overlay.getRadius();
            this.globalcoordinates = { center, radius };
  
            ({ minLat, maxLat, minLong, maxLong } = this.calculateCircleBoundingBox(overlay));
            break;
          case 'polygon':
            this.globalpolygonType = 'polygon';
            this.globalcoordinates = overlay.getPath().getArray().map(latLng => latLng.toJSON());
            ({ minLat, maxLat, minLong, maxLong } = this.calculatePolygonBoundingBox(overlay.getPath()));
            break;
          case 'polyline':
            this.globalpolygonType = 'polyline';
            this.globalcoordinates = overlay.getPath().getArray().map(latLng => latLng.toJSON());
            ({ minLat, maxLat, minLong, maxLong } = this.calculatePolygonBoundingBox(overlay.getPath()));
            break;
          case 'rectangle':
            this.globalpolygonType = 'rectangle';
  
            const bounds = overlay.getBounds();
            const ne = bounds.getNorthEast().toJSON();
            const sw = bounds.getSouthWest().toJSON();
            this.globalcoordinates = { ne, sw };
            ({ minLat, maxLat, minLong, maxLong } = this.calculateRectangleBoundingBox(overlay.getBounds()));
            break;
          default:
            this.globalpolygonType = '';
            this.globalcoordinates = null;
            break;
        }
        // alert(`this.globalCoordinates for ${type}: ${JSON.stringify(this.globalcoordinates)} ${this.globalpolygonType}`);
  
        if (minLat !== undefined && maxLat !== undefined && minLong !== undefined && maxLong !== undefined) {
          this.globalminLat = minLat;
          this.globalmaxLat = maxLat;
          this.globalminLong = minLong;
          this.globalmaxLong = maxLong;
          // this.showModal = true
          const jsonData = {
            coordinates:this.globalcoordinates,
            min_lat: this.globalminLat,
            max_lat: this.globalmaxLat,
            min_long: this.globalminLong,
            max_long: this.globalmaxLong,
          };
          this.overlayDataArray.push(jsonData)
        }
  
      },
      
      modalConfrimation(){
        if (this.polygondata !=[] && this.overlayDataArray.length > 0){
          const jsonData2 = {
            coordinates:this.polygondata[0].paths,
            min_lat: this.min_latitude,
            max_lat: this.max_latitude,
            min_long: this.min_longitude,
            max_long: this.max_longitude,
          };
          this.overlayDataArray.push(jsonData2)
          this.showModal = true
        }else if (this.overlayDataArray.length > 0 ) {
          this.showModal = true
        } else {
          this.$swal({
              icon: 'Error',
              title: 'Atleast select one polygon',
              // text: this.response,
          });
        }
        
      },
      worldpolygoncoordinates(item,index){
        this.polygondata=item.coordinates
        this.min_latitude=item.min_lat
        this.max_latitude=item.max_lat
        this.min_longitude=item.min_long
        this.max_longitude=item.max_long
        this.polygon_coordinates=item.coordinates
        this.latitude=this.polygon_coordinates[0].paths[0].lat
        this.longitude=this.polygon_coordinates[0].paths[0].lng
        this.source=item.source
        this.initMap()
      },
      expediapolygoncoordinates(item,index){
        this.polygondata=item.coordinates
        this.min_latitude=item.min_lat
        this.max_latitude=item.max_lat
        this.min_longitude=item.min_long
        this.max_longitude=item.max_long
        this.polygon_coordinates=item.coordinates
        this.latitude=this.polygon_coordinates[0].paths[0].lat
        this.longitude=this.polygon_coordinates[0].paths[0].lng
        this.source=item.source
        this.initMap()
      },
      getIndex(item,index){
            // this.showloader=true
            // this.showFilterGoogleMap = false;
            this.getclickindex.city = item.City
            this.getclickindex.state = item.State
            this.getclickindex.country = item.Country
            this.getclickindex.index = index
            axios.post('/checkpolygon2',this.getclickindex).then(response => {
              console.log(response.data)
              this.polygondata=response.data.coordinates
              this.min_latitude=response.data.min_lat
              this.max_latitude=response.data.max_lat
              this.min_longitude=response.data.min_long
              this.max_longitude=response.data.max_long
              this.polygon_coordinates=response.data.coordinates
              this.latitude=this.polygon_coordinates[0].paths[0].lat
              this.longitude=this.polygon_coordinates[0].paths[0].lng
              this.source=response.data.source
              this.initMap()   
            }).catch(error => {
              console.log(error)
                // console.log("Something went wrong in setpolygon()")
            })
        
        },
      // worldpolygoncoordinates(item,index){
      //   console.log("function worldpolygoncoordinates is triggered")
      //   axios.post('fetchworldlatlong', {
      //       location: item.location,
      //       country: item.country,
      //       state:item.state,
      //       district:item.district,
      //   }, {
      //       // headers: { 'Access-Control-Allow-Origin': '*' }
      //   }).then(response => {
      //     console.log(response.data)
      //     console.log(response.data.info.coordinates,"worldpolygoncoordinates",typeof response.data.info)
      //     this.polygondata=response.data.info.min_lat
      //     console.log(this.polygondata,"worldpolygoncoordinates")
      //     this.initMap()
      //   }).catch(error => {
      //       console.log("Something went wrong in CenterCoordinatess()")
      //   })
      // },
      calculatePolygonBoundingBox(path) {
        const bounds = new google.maps.LatLngBounds();
  
        path.getArray().forEach(latLng => {
          bounds.extend(latLng);
        });
  
        const minLat = bounds.getSouthWest().lat();
        const maxLat = bounds.getNorthEast().lat();
        const minLong = bounds.getSouthWest().lng();
        const maxLong = bounds.getNorthEast().lng();
  
        return { minLat, maxLat, minLong, maxLong };
      },
  
      calculateRectangleBoundingBox(bounds) {
        const minLat = bounds.getSouthWest().lat();
        const maxLat = bounds.getNorthEast().lat();
        const minLong = bounds.getSouthWest().lng();
        const maxLong = bounds.getNorthEast().lng();
  
        return { minLat, maxLat, minLong, maxLong };
      },
  
      calculateCircleBoundingBox(circle) {
        const center = circle.getCenter();
        const minLat = center.lat() - circle.getRadius() / 111.32;
        const maxLat = center.lat() + circle.getRadius() / 111.32;
        const minLong = center.lng() - circle.getRadius() / (111.32 * Math.cos((center.lat() * Math.PI) / 180));
        const maxLong = center.lng() + circle.getRadius() / (111.32 * Math.cos((center.lat() * Math.PI) / 180));
  
        return { minLat, maxLat, minLong, maxLong };
      },
      setDrawingMode(mode) {
        this.resetFunction2();
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType[mode]);
      },
      clearDrawing() {
        // Clear all drawn shapes on the map
        this.drawnShapes.forEach(shape => {
          shape.setMap(null);
        });
        this.drawnShapes = [];
        this.resetFunction();
      },
      resetFunction(){
        this.overlayDataArray=[]
        this.formDataArray = [];
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates= null;
        this.globalpolygonType= null;
        this.globalminLat= null;
        this.globalmaxLat= null;
        this.globalminLong= null;
        this.globalmaxLong= null;
      },
      resetFunction2(){
        this.polygon_coordinates=[];
        this.polygondata='';
        this.min_latitude='';
        this.max_latitude='';
        this.min_longitude='';
        this.max_longitude='';
      },
      onOk() {
        console.log('OK button clicked');
        // You can perform additional actions here
      },
      onSubmit2(){
        console.log("save function is called")
        console.log(this.polygon_coordinates)
        if (this.polygon_coordinates==undefined || this.polygon_coordinates.length==0){
          this.onSubmit();
        }else if (this.polygon_coordinates.length !=0){
          this.$refs['my-modal'].show()
        }
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        this.$refs['my-modal'].toggle('#toggle-btn')
      },
      onSubmit(){
        // this.toggleModal();
        if (this.typeName=='' && this.polygondata !='' && this.overlayDataArray.length >= 1){
          const jsonData3 = {
            coordinates:this.polygon_coordinates[0].paths,
            min_lat: this.min_latitude,
            max_lat: this.max_latitude,
            min_long: this.min_longitude,
            max_long: this.max_longitude,
          };
          this.overlayDataArray.push(jsonData3)
          axios.post('savepolygonmultiple', {
          city:this.citydata,
          location:this.citydata,
          state:this.statedata,
          country:this.countrydata,
          coordinates:this.overlayDataArray,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {

            console.log('Submit button clicked')
            this.saveverifiedcity(this.citydata,this.countrydata,this.statedata)
            this.$swal({
                icon: 'success',
                title: 'Custom polygon map created successfully',
                text: this.response,
            });
          }).catch(error => {
              console.log("Something went wrong in outersuppliers()")
          })
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates=null;
        this.overlayDataArray=[];
        this.showModal = false;
        }else if(this.typeName=='' && this.overlayDataArray.length >= 1){
          axios.post('savepolygonmultiple', {
          city:this.citydata,
          location:this.citydata,
          country:this.countrydata,
          state:this.statedata,
          coordinates:this.overlayDataArray,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
            this.saveverifiedcity(this.citydata,this.countrydata,this.statedata)
            this.$swal({
                icon: 'success',
                title: 'Custom polygon map created successfully',
                text: this.response,
            });
          }).catch(error => {
              console.log("Something went wrong in outersuppliers()")
          })
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates=null;
        this.overlayDataArray=[];
        this.showModal = false;
        }else if (this.typeName=='' && this.polygondata!=[]){
          axios.post('/savecoordinates_clickhouse',{
          location:this.citydata,
          city:this.citydata,
          country:this.countrydata,
          state:this.statedata,
          coordinates:this.polygon_coordinates[0].paths,
          min_lat: this.min_latitude,
          max_lat: this.max_latitude,
          min_long: this.min_longitude,
          max_long: this.max_longitude,
          source:this.source,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
                this.savepolygon=response.data
                this.saveverifiedcity(this.citydata,this.countrydata,this.statedata)
                this.$swal({
                    icon: 'success',
                    title: 'Polygon Updated Successfully',
                    text: this.response,
                });
                // console.log("savepolygon",this.savepolygon)
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        }else if (this.typeName!='' && this.overlayDataArray.length > 1){
          axios.post('savepolygonmultiple', {
          city:this.typeName,
          location:this.citydata,
          country:this.countrydata,
          state:this.typeNamestate,
          coordinates:this.overlayDataArray,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {

            console.log('Submit button clicked')

            this.$swal({
                icon: 'success',
                title: 'Custom polygon map created successfully',
                text: this.response,
            });
              
          }).catch(error => {
              console.log("Something went wrong in outersuppliers()")
          })
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates=null;
        this.overlayDataArray=[];
        this.showModal = false;
        }else if (this.typeName==''){
          if(this.globalcoordinates==null) {
            this.$swal({
              icon: 'Error',
              title: 'Atleast select one polygon',
              // text: this.response,
          });
          }else{
            axios.post('savecreatedpolygon', {
                city:this.citydata,
                location:this.citydata,
                country:this.countrydata,
                state:this.typeNamestate,
                polygon_type:this.globalpolygonType,
                coordinates:this.globalcoordinates,
                min_latitude:this.globalminLat,
                max_latitude:this.globalmaxLat,
                min_longitude:this.globalminLong,
                max_longitude:this.globalmaxLong,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
              this.saveverifiedcity(this.citydata,this.countrydata,this.statedata)
              console.log('Submit button clicked')

              this.$swal({
                  icon: 'success',
                  title: 'Custom polygon map created successfully',
                  text: this.response,
              });
                
            }).catch(error => {
                console.log("Something went wrong in outersuppliers()")
            })
          this.typeName = '';
          this.typeNamestate='';
          this.globalcoordinates=
          this.showModal = false;
          }
      }else{
        if(this.polygondata!=[] && this.overlayDataArray.length > 0 ){
          axios.post('savepolygonmultiple', {
          city:this.typeName,
          location:this.citydata,
          state:this.statedata,
          country:this.countrydata,
          coordinates:this.overlayDataArray,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
            this.saveverifiedcity(this.typeName,this.countrydata,this.statedata)
            console.log('Submit button clicked')

            this.$swal({
                icon: 'success',
                title: 'Custom polygon map created successfully',
                text: this.response,
            });
              
          }).catch(error => {
              console.log("Something went wrong in outersuppliers()")
          })
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates=null;
        this.overlayDataArray=[];
        this.showModal = false;
        }else{
          axios.post('savecreatedpolygon', {
          city:this.typeName,
          location:this.citydata,
          country:this.countrydata,
          state:this.typeNamestate,
          polygon_type:this.globalpolygonType,
          coordinates:this.globalcoordinates,
          min_latitude:this.globalminLat,
          max_latitude:this.globalmaxLat,
          min_longitude:this.globalminLong,
          max_longitude:this.globalmaxLong,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
            this.saveverifiedcity(this.typeName,this.countrydata,this.statedata)
            this.$swal({
                icon: 'success',
                title: 'Custom polygon map created successfully',
                text: this.response,
            });
              
          }).catch(error => {
              console.log("Something went wrong in outersuppliers()")
          })
        this.typeName = '';
        this.typeNamestate='';
        this.globalcoordinates=null,
        this.showModal = false;
        }
      }
    },
      onCancel() {
        this.showModal = false;
      },
      CenterCoordinates: function(){
        console.log("this function is called cen")
        axios.post('citycenter', {
            city: this.citydata,
            country: this.countrydata,
            state:this.statedata,
            source:this.sourcedata,
            userid:this.userdata,
        }, {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
          if ((this.citydata!=='' && this.citydata!==null) && this.countrydata!==null){
            this.latitude=response.data.Coordinates.latitude
            this.longitude=response.data.Coordinates.longitude
            this.polygon_coordinates=response.data.Coordinates_data.coordinates
            this.initMap()
          }
        }).catch(error => {
            console.log("Something went wrong in CenterCoordinatess()")
        })
      },
    },
  };
  </script>
    
  <style scoped>
  #map {
    height: 100vh;
  }
  
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .submitbutton{
    margin-left: 140px;
  }
  .cancelbutton{
    margin-left: 20px
  }
  </style>