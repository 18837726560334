<template>
    <div class="row">
        <div class="col-lg-3">
            <div class="map__section">
                <h3 class="headingMain">HotelSearch API (OTID/Code)</h3>
                <p class="subheadingMain">Enter Details Here To Fetch Json Response Of HotelCode With Associated Suppliers</p>
                <hr>
                <div class="form__map">
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-select v-model="selectedCountry" class="form-control" :options="countryData"
                                @change="getcustompolygonlist"></b-form-select>
                            <label>Select Country</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedValue" @keyup.enter="applymapfilter" required
                                spellcheck="false" autocomplete="off" list="optionsList"></b-form-input>
                            <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData"></b-form-datalist>
                            <label>Enter City</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedSupplierCode" @keyup.enter="applymapfilter"
                                required spellcheck="false" autocomplete="off" list="optionsList"></b-form-input>
                            <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData"></b-form-datalist>
                            <label>Enter OTID/Code</label>
                        </div>
                    </div>
                </div>
                <div class="navigation__filter text-end">
                    <b-button @click="applymapfilter">Show Data &nbsp;</b-button>
                </div>
                <div class="close__map__filter" @click="showfilter = !showfilter"><b-icon icon="list"
                        class="trans90"></b-icon></div>
            </div>
        </div>
        <div class="col-lg-9 p-4">
            <div class="card p-2 baseheight">
                <div v-if="loading" >
                    <div class="loader"></div>
                    <h3 align="center" v-if="loading" >Loading Data...</h3>
                </div>
                <div v-if="!loading">
                    <h4>Response data:</h4>
                    <pre>{{ this.hoteldatabyid }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const userType = localStorage.getItem('userType');
</script>

<script>
import axios from '@axios'
import GoogleMap from '../googlemap/GoogleMap.vue'
import {
    BButton,
    // BSpinner,
    BFormInput,
    // BCard,
    // BCardHeader,
    // BCollapse,
    // BCardBody,
    BFormSelect,
    BPopover,
} from 'bootstrap-vue'

export default {
    data() {
        return {
            loading:false,
            selectedSupplierCode: null,
            selectedValue: null,
            selectedCountry: null,
            min_lat: '',
            max_lat: '',
            min_long: '',
            max_long: '',
            showfilter: false,
            showfilter2: true,
            // supplierData:null,
            // colabListData:null,
            // insideListData:null,
            // insideListDatabackup:null,
            // outsideListData:null,
            // outsideListDatabackup:null,
            // externalListData:null,
            // externalListDatainside:null,
            // unknownListData:null,
            // unknownListDatabackup:null,
            // outersupplierData:null,
            // showloader:false,
            // setpolygondata:[],
            countryData: [],
            hoteldatabyid: null,
            // cityData:[],
            // alertmsg:null,
            // nullBase:null,
            // externalnullBase:null,
            // gotoclicklocation:null,
            // usertype:null,
            // isEditable:false,
            // polygonmodified:'',
            // polygondeleted:'',
            // updatedcoordinates:[],
            // filterhotel1:null,
            // filterhotel2:null,
            // filterhotel3:null,
            // insidecount:null,
            // outsidecount:null,
            // supplier_insidecount:null,
            // supplier_unknowncount:null,
            // supplier_outsidecount:null,
            // supplier_externalcount:null,
            // supplier_externalinsidecount:null,
            // plotting_outsidecount:null,
            // plotting_insidecount:null,
            // plotting_unknowncount:null,
            // unknownnullBase:null,
            // showDatalist: false,
            // testgreen:true,
            // testred:true,
            // testblue:true,
        }
    },
    components: {
        BFormInput,
        BButton,
        // BSpinner,
        // BCard,
        // BCardHeader,
        // BCollapse,
        // BCardBody,
        BFormSelect,
        // BPopover,
    },
    methods: {
        callMethodFromOtherComponent() {
            GoogleMap.openWindow();
        },
        // getcustompolygonlist:function(){
        //     axios.post('fetchcreatedpolygondestination', {
        //         country :this.selectedCountry,
        //     },{
        //         // headers: { 'Access-Control-Allow-Origin': '*' }
        //     }).then(response => {
        //         this.cityData = response.data
        //     }).catch(error => {
        //         console.log("Something went wrong in countryGetList()")
        //     })
        // },
        countryGetList: function () {
            axios.get('/country_list', {
            }).then(response => {
                this.countryData = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        // setpolygon: function(){
        //     axios.post('/polygon/', {
        //         city: this.selectedValue,
        //         country: this.selectedCountry,
        //         KM:this.selectedValueKM
        //     }, {
        //         // headers: { 'Access-Control-Allow-Origin': '*' }
        //     }).then(response => {
        //         this.setpolygondata=response.data.coordinates
        //         this.min_lat=response.data.min_lat
        //         this.max_lat=response.data.max_lat
        //         this.min_long=response.data.min_long
        //         this.max_long=response.data.max_long      
        //     }).catch(error => {
        //         console.log("Something went wrong in setpolygon()")
        //     })
        // },
        // outersuppliers: function(){
        //     axios.post('/suppliers_datanot/',{
        //         city: this.selectedValue,
        //         country: this.selectedCountry,
        //         data:this.selectedOption,
        //         KM:this.selectedValueKM
        //     }, {
        //         // headers: { 'Access-Control-Allow-Origin': '*' }
        //     }).then(response => {
        //         console.log("polygonview",response.data)
        //         if(response.data.outside_plotting){
        //             this.externalListDatainside=response.data.outside_plotting.inside
        //             this.externalListData = response.data.outside_plotting.outside
        //             this.supplier_externalinsidecount=response.data.outside_plotting.externalinsidecount
        //             this.supplier_externalcount=response.data.outside_plotting.externalcount
        //         }
        //         else{
        //             this.externalListData = response.data.output
        //             this.supplier_externalcount=response.data.externalcount
        //         }
        //         this.externalnullBase = this.externalListData[0].city_id;
        //         return "aala";
        //     }).catch(error => {
        //         console.log("Something went wrong in outersuppliers()")
        //     })
        // },
        // updateOptions() {
        // this.showDatalist = this.selectedValue.trim() !== '';
        // },
        applymapfilter() {
            this.loading=true,
            axios.post('/hotelid', {
                city: this.selectedValue,
                country: this.selectedCountry,
                suppliercode: this.selectedSupplierCode,
            }, {
            }).then(response => {
                this.loading=false,
                this.hoteldatabyid = response.data
                // console.log('testing response',response.data)
                // const obj1=response.data.map_inout.inside;
                // const obj2=this.externalListDatainside;
                // if (obj2 != null) {
                //     const concatdata = [];
                //     for (const key in obj1) {
                //         if (obj1.hasOwnProperty(key)) {
                //         concatdata.push(obj1[key]);
                //         }
                //     }
                //     for (const key in obj2) {
                //         if (obj2.hasOwnProperty(key)) {
                //             if (!concatdata.includes(obj2[key])) {
                //                 concatdata.push(obj2[key]);
                //             }
                //         }
                //     }
                //     this.insideListData = concatdata;
                //     this.insideListDatabackup=concatdata;
                // } else {
                //     this.insideListData = obj1;
                //     this.insideListDatabackup=obj1;
                // }
                // if(response.data.map_inout.outside && this.externalListData){
                //     const obj3=response.data.map_inout.outside;
                //     const obj4=this.externalListData;
                //     if (obj3 !=null && obj3.length==1 && obj3[0].hotel_name=='null' && obj4[0].hotel_name !='null'){
                //         this.outsideListData = obj4;
                //         this.outsideListDatabackup = obj4;
                //     }else if (obj3 != null && obj3[0].hotel_name!='null' ) {
                //         const concatdataexternal = [];
                //         for (const key in obj3) {
                //             if (obj3.hasOwnProperty(key)) {
                //                 concatdataexternal.push(obj3[key]);
                //             }
                //         }
                //         for (const key in obj4) {
                //             if (obj4.hasOwnProperty(key)) {
                //                 if (!concatdataexternal.includes(obj4[key])) {
                //                     concatdataexternal.push(obj4[key]);
                //                 }
                //             }
                //         }
                //         this.outsideListData = concatdataexternal;
                //         this.outsideListDatabackup = concatdataexternal;
                //     }else if (obj4==null){
                //         this.outsideListData = obj3;
                //         this.outsideListDatabackup = obj3;
                //     }
                // }else if(!response.data.map_inout.outside){
                //     this.outsideListData = this.externalListData;
                //     this.outsideListDatabackup = this.externalListData;
                // }
                // this.supplier_insidecount=response.data.map_inout.insidecount
                // this.supplier_outsidecount=response.data.map_inout.outsidecount
                // this.supplier_unknowncount=response.data.map_inout.unknowncount
                // if (this.supplier_externalcount.length !=0 && this.supplier_outsidecount.length !=0){
                //     const array1 = this.supplier_outsidecount;
                //     const array2 = this.supplier_externalcount;
                //     const combinedArray = array1.concat(array2);    
                //     const result = {};
                //     combinedArray.forEach(item => {
                //         const supplier = item.supplier;
                //         const count = result[supplier] ? result[supplier].count : 0;
                //         if (item.outside_counts !== undefined) {
                //             result[supplier] = { 'supplier': supplier, 'count': count + item.outside_counts };
                //         } else if (item.external_counts !== undefined) {
                //             result[supplier] = { 'supplier': supplier, 'count': count + item.external_counts };
                //         } else {
                //             result[supplier] = { 'supplier': supplier, 'count': count };
                //         }
                //     });
                //     const resultArray = Object.values(result);
                //     this.plotting_outsidecount=resultArray
                // }else if (this.supplier_outsidecount.length !=0){
                //     const array1 = this.supplier_outsidecount;
                //     const result = {};
                //     array1.forEach(item => {
                //     const supplier = item.supplier;
                //     const count = result[supplier] ? result[supplier].count : 0;
                //     if (item.outside_counts) {
                //         result[supplier] = { 'supplier': supplier, 'count': count + item.outside_counts };
                //     }
                //     });
                //     const resultArray = Object.values(result);
                //     this.plotting_outsidecount=resultArray
                // }else if(this.supplier_externalcount.length !=0){
                //     const array2 = this.supplier_externalcount;
                //     const result = {};
                //     array2.forEach(item => {
                //     const supplier = item.supplier;
                //     const count = result[supplier] ? result[supplier].count : 0;
                //     if (item.external_counts) {
                //         result[supplier] = { 'supplier': supplier, 'count': count + item.external_counts };
                //     }
                //     });
                //     const resultArray = Object.values(result);
                //     this.plotting_outsidecount=resultArray
                // }
                // if ( (this.supplier_externalinsidecount != null && this.supplier_externalinsidecount.length !=0) && this.supplier_insidecount.length !=0){
                //     const array3 = this.supplier_insidecount;
                //     const array4 = this.supplier_externalinsidecount;
                //     const combinedArray2 = array3.concat(array4);
                //     const result2 = {};
                //     combinedArray2.forEach(item => {
                //     const supplier2 = item.supplier;
                //     const count2 = result2[supplier2] ? result2[supplier2].count : 0;
                //         if (item.inside_counts !== undefined) {
                //             result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.inside_counts };
                //         } else if (item.external_insidecounts !== undefined) {
                //             result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.external_insidecounts };
                //         } else {
                //             result2[supplier2] = { 'supplier': supplier2, 'count': count2 };
                //         }
                //     });
                //     const resultArray2 = Object.values(result2);
                //     this.plotting_insidecount=resultArray2
                // }else if (this.supplier_insidecount.length !=0 && this.supplier_externalinsidecount==null){
                //     const array3 = this.supplier_insidecount;
                //     const result2 = {};
                //     array3.forEach(item => {
                //     const supplier2 = item.supplier;
                //     const count2 = result2[supplier2] ? result2[supplier2].count : 0;
                //     if (item.inside_counts) {
                //         result2[supplier2] = { 'supplier': supplier2, 'count': count2 + item.inside_counts };
                //     }
                // });
                //     const resultArray2 = Object.values(result2);
                //     this.plotting_insidecount=resultArray2
                // }   
                // if (this.supplier_unknowncount != null && this.supplier_unknowncount.length !=0){
                //     const array5 =this.supplier_unknowncount;
                //     const result3 = {};
                //     array5.forEach(item => {
                //     const supplier3 = item.supplier;
                //     const count3 = result3[supplier3] ? result3[supplier3].count : 0;
                //     if (item.unknown_counts) {
                //         result3[supplier3] = { 'supplier': supplier3, 'count': count3 + item.unknown_counts };
                //         }
                //     });
                //     const resultArray3 = Object.values(result3);
                //     this.plotting_unknowncount=resultArray3
                // }
                // this.unknownListData=response.data.map_inout.unknown
                // this.unknownListDatabackup=response.data.map_inout.unknown
                // this.unknownnullBase=response.data.map_inout.unknown.length
                // this.insidecount=this.insideListData.length
                // this.outsidecount=this.outsideListData.length
                // this.unknowncount=this.unknownListData.length
                // this.nullBase = this.outsideListData[1].city_id
                // this.colabListData = response.data.map_inout
                // this.showGoogleMap = true
                // this.supplierData=response.data
                // this.showloader=false
                // this.showalertmsg=false
            }).catch(error => {
                this.showloader = true
                if (this.selectedValue != null && this.selectedCountry != null && this.setpolygondata == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Special Character Not allowed"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null && this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City and Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Suppliers Data Not Found"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = true
                    this.showloader = false
                }
            })
        },
        closelist() {
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
        },
    },
    mounted() {
        this.countryGetList();
    },
    //     computed:{
    //     fetchaccuratedata(){
    //     this.insideListData=null
    //     this.insideListData=this.insideListDatabackup
    //     if(this.filterhotel1){
    //         const datafiltered = Object.fromEntries(
    //             Object.entries(this.insideListData)
    //             .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterhotel1.toLowerCase()))
    //             // .filter(([key,value]) => value.hotel_name.toLowerCase() === this.filterhotel1.toLowerCase())
    //         );
    //         this.insideListData=datafiltered
    //         return this.insideListData

    //     }else{
    //         this.insideListData=this.insideListDatabackup
    //         return this.insideListData;
    //     }    
    //     },
    //     fetchaccuratedata2(){
    //     this.outsideListData=null
    //     this.outsideListData=this.outsideListDatabackup
    //     if(this.filterhotel2){
    //         const datafiltered = Object.fromEntries(
    //             Object.entries(this.outsideListData)
    //             .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterhotel2.toLowerCase()))
    //         );
    //         this.outsideListData=datafiltered
    //         return this.outsideListData

    //     }else{
    //         this.outsideListData=this.outsideListDatabackup
    //         return this.outsideListData;
    //     }    
    //     },
    //     fetchaccuratedata3(){
    //     this.unknownListData=null
    //     this.unknownListData=this.unknownListDatabackup
    //     if(this.filterhotel3){
    //         const datafiltered = Object.fromEntries(
    //             Object.entries(this.unknownListData)
    //             .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterhotel3.toLowerCase()))
    //         );
    //         this.unknownListData=datafiltered
    //         return this.unknownListData

    //     }else{
    //         this.unknownListData=this.unknownListDatabackup
    //         return this.unknownListData;
    //     }    
    //     },
    // }
}

</script>

<style>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  margin-top: 200px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.infogap {
    margin-left: 10px;
}

input:focus~label,
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.checkHeight {
    height: 12px !important;
}

.margBase {
    margin: 0 0 1rem 0;
}

.checkHeight input:checked~.checkmark {
    background-color: #2196F3 !important;
}

.input-field input,
.input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.checkmark {
    color: rgb(29, 81, 224);
    margin-left: 10px;
}

.bg-under {
    background-color: #00ae08 !important;
}

.bg-outside {
    background-color: #c81b00 !important;
}

.bg-info {
    background-color: blue !important;
}

.city-text {
    text-transform: uppercase;
}

.green {
    color: #00ae08;
}

.red {
    color: #c81b00;
}

.blue {
    color: blue
}

input[type="checkbox"] {
    transform: scale(1.5);
}

.destination_save {
    width: 140px;
    margin-left: 10px;
    margin-right: 10px;
}

.look {
    color: black;
}

.search1 {
    height: 35px;
    width: 290px;
}

.red {
    color: #c81b00;
}

.black {
    color: black;
}
.baseheight{
    height:80vh !important;
    overflow-y: auto;
}
</style>