import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBcYBsboFgAyVNues3roQiujCj7KlaF-YA',
    libraries: 'places,drawing',
  }
})


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-select/dist/vue-select.css"
import './assets/css/style.scss'

new Vue({
  router,
  store,
  render: h => h(App),  
}).$mount('#app')
