<template>
    <div>
      <div v-if="loading" class="loader"></div>
      <h3 align="center" v-if="loading" >Loading Data...</h3>
      <div v-if="!loading">
        <div>
          <b-row>
            <h3 align="center">{{  this.user.toUpperCase() }}  LOGS</h3>
            <b-col lg="12">
              <b-row>
                <b-col lg="3">
                  <div class="ssbx bg_1 dashboard_height">
                    <h5><b-avatar icon="file-bar-graph" class="space-between"></b-avatar>
                      Total Hits</h5>
                    <h5>{{ allCountValue }}<span class="fontsize" v-if="this.allCountValue!=null">Hits</span></h5>
                    <p>Date : {{ startDateValue }} - {{ endDateValue }}</p>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="ssbx bg_2 dashboard_height">
                    <h5><b-avatar icon="sort-numeric-up-alt" class="space-between"></b-avatar><span>Daily Hits</span></h5>
                    <h5>{{ dailyCountValue }}<span class="fontsize" v-if="this.dailyCountValue!=null">Hits</span></h5>
                    <p>Date : {{ dailyCountDate }}</p>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="ssbx bg_3 dashboard_height">
                    <h5><b-avatar icon="geo-alt" class="space-between"></b-avatar>Trending City</h5>
                    <h5>{{ trending_city }} <span class="fontsize" v-if="this.trending_city!=null">Hits : {{ trending_city_count }}</span></h5>
                    <p><span>Date : {{ trending_city_date }}</span></p>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="ssbx bg_1 dashboard_height">
                    <h5><b-avatar icon="list-ol" class="space-between"></b-avatar>City Hit List</h5>
                    <div class="scroll">
                      <h5 v-for="(item, index) in city_hit_list" :key="index">{{ item.city_count.toLowerCase() }}</h5>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <br>
              <!-- <h6 align="right"><b>( Success : {{this.test2}} / Failed : {{ this.test3 }} / Alert : {{ this.test1 }})</b></h6> -->
              <input class="gap" type="date" v-model="filterText5" placeholder="Date..." />
              <input class="gap" type="text" v-model="filterText1" placeholder="Locations..." />
              <input class="gap" type="text" v-model="filterText2" placeholder="Country..." />
              <b-form-select class="gap2" type="text" v-model="filterText4" id="statusDropdown">
                <b-form-select-option value="" disabled>Select Status</b-form-select-option>
                <b-form-select-option value="Alert">Alert</b-form-select-option>
                <b-form-select-option value="Failed">Failed</b-form-select-option>
                <b-form-select-option value="Success">Success</b-form-select-option>
              </b-form-select>
              <b-button class="gapbutton" variant="primary" :to="{name:'clientcustomlist',params: {user:this.$route.params.user,id:this.$route.params.id}}">Polygon List</b-button>
              <b-button class="gap4" variant="primary" @click="handleModalClose">Reset</b-button>
              
              <table>
                <thead>
                  <tr>
                    <th v-for="(col) in activelogs.columns" :key="col.id">
                      {{ col.toUpperCase() }}
                      <b-icon v-if="col == 'response_status'" id="link-button" icon="exclamation-circle"></b-icon>
                      <b-popover v-if="col == 'response_status'" target="link-button" triggers="hover">
                      <p><b class="info"><u>200</u> : </b>Indicates Success</p>
                      <p><b class="info"><u>403</u> : </b>Indicates Error</p>
                      </b-popover>
                      <b-icon v-if="col == 'action'" id="link-button2" icon="exclamation-circle"></b-icon>
                      <b-popover v-if="col == 'action'" target="link-button2" triggers="hover">
                        <p><b><u>Open Map Button</u> : </b> Clicking on Open Map Button redirect user to another page where the user can save polygon coordinates according to him selecting the OSM Response.</p>
                      </b-popover>
                    </th>
                  </tr>
                </thead>          
                <tbody>
                  <tr v-for="(rw, item) in paginatedData" :key="item">
                    
                    <!-- <td v-if="userType =='Admin'">{{ rw.user_id }}</td>
                    <td v-if="userType =='Admin'">{{ rw.username }}</td> -->
                    <td>{{ rw.date }}<span class="info2"></span>{{ rw.time }}</td>
                    <td>{{ rw.location }}</td>
                    <td>{{ rw.country }}</td>
                    <!-- <td>{{ rw.time }}</td> -->
                    <td>{{ rw.error }}</td>
                    <td>{{ rw.status }}</td>
                    <td v-if="userType !='Admin'">{{ rw.flag }}</td>
                    <td>
                      <b-button v-if="rw.action =='None'" class="gap3" variant="primary" :to="{name:'boundary', params: {location:rw.location,country:rw.country,index:'null'}}">Map</b-button>
                      <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button>
                      <p v-if="rw.action =='null'">{{ rw.action }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <b-button variant="primary" @click="previousPage" :disabled="currentPage === 1" class="mx-2 py-1">Previous</b-button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <b-button variant="primary" @click="nextPage" :disabled="currentPage === totalPages"  class="mx-2 py-1">Next</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from '@axios'
  import { BRow, BCol, BButton, BFormSelect, BFormSelectOption, BPopover, BIcon}from "bootstrap-vue"
  export default {
    components: {
      BRow,
      BCol,BButton,
      BFormSelect, BFormSelectOption, BPopover, BIcon
    },
    data() {
      return {
        loading: true,
        activelogs:null,
        selectedDate: null,
        status:null,
        filterText1: '',
        filterText2: '',// Filter text
        filterText3:'',
        filterText4:'',
        filterText5:'',
        currentPage: 1,
        itemsPerPage: 25, // Number of items per page
        allCountValue: [],
        startDateValue: [],
        endDateValue: [],
        dailyCountValue: [],
        dailyCountDate: [],
        trending_city: [],
        trending_city_date: [],
        trending_city_count: [],
        city_hit_list: [],
        Alert:'',
        Success:'',
        Failed:'',
        user:'',
      }
    },
    methods:{
      previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleModalClose(){
      this.filterText1=''
      this.filterText2=''
      this.filterText3=''
      this.filterText4=''
      this.filterText5=''
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    Error: function(){
        axios.post('clientlogsinfo',{
            user:this.$route.params.user,
            id:this.$route.params.id,
          }, {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
              this.activelogs = response.data.logs
              this.test1=this.activelogs.data.filter(item =>
              item.status.toLowerCase().includes('alert')).length
              this.test2=this.activelogs.data.filter(item =>
              item.status.toLowerCase().includes('success')).length
              this.test3=this.activelogs.data.filter(item =>
              item.status.toLowerCase().includes('failed')).length
              this.user=response.data.client
          }).catch(error => {
              // console.log("Something went wrong in countryGetList()")
          })
        },
        reportinfo(row, index){
          this.Reportdata={
            location:row.location,
            country: row.country,
            user: row.user_id,
            time:row.time,
            date:row.date,
            
          }
          this.$router.push({ name: 'report', params:{ id:this.Reportdata}});
        },
      //   Refreshcount: function(){
      //   axios.post('testrestart', {
      //       // headers: { 'Access-Control-Allow-Origin': '*' }
      //   }).then(response => {
      //       const resetdata=0;
      //       this.$store.commit('setCounter',resetdata)
      //       this.status = response.data
      //   }).catch(error => {
      //       // console.log("Something went wrong in countryGetList()")
      //   })
      // },
      masterDatacall: function () {
      axios.get('masterdata', {
        // headers: { 'Access-Control-Allow-Origin': '*' }
      }).then(response => {
        this.masterdata = response.data
        console.log("masrterdataresponse", this.masterdata)
      }).catch(error => {
      })
    },
      thirdSubmit(filtertext5) {
        // this.startdate=[];
        // this.enddate=[];
        this.allCountValue = [];
        this.startDateValue = [];
        this.endDateValue = [];
        this.dailyCountValue = [];
        this.dailyCountDate = [];
        this.trending_city = [];
        this.trending_city_date = [];
        this.trending_city_count = [];
        this.city_hit_list =[];

        const urls = [
          "clientallcount", //0
          "clientdailycount", //1
          "clienttrendingcity",
          "dailycithitlist", //2
        ]
        Promise.all(urls.map(url => axios.post(url, { selectedDate:this.filterText5, user:this.$route.params.user, }))).then(responses => {
          this.allCountValue = responses[0].data.all_data.total_count
          this.startDateValue = responses[0].data.all_data.start_date
          this.endDateValue = responses[0].data.all_data.end_date
          this.dailyCountValue = responses[1].data.daily_data.count_for_date
          this.dailyCountDate = responses[1].data.daily_data.date
          this.trending_city = responses[2].data.trending_data.trending_city
          this.trending_city_date = responses[2].data.trending_data.date
          this.trending_city_count=responses[2].data.trending_data.count
          this.city_hit_list=responses[3].data.cityhitlist
        }).catch(error => {
          console.log(error)
        })
      },
    },
    mounted() {
      this.masterDatacall()
      // this.Error()
      setTimeout(() => {
        this.Error()
        this.thirdSubmit(this.filterText5)
        this.loading = false;
      }, 1000);
    
    },
    computed: {
    filteredData() {
      if (this.filterText3 && this.filterText2 && this.filterText1 && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2 && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3  && this.filterText2 && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1  && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if ( this.filterText1  && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText2 && this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item => 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText1 && this.filterText2 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 &&  this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) 
        );
      } else if (this.filterText3 && this.filterText1  && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText1) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText3 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextlower1 = Number(this.filterText3);
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText1 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText1 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText2 && this.filterText4) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText2 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText4 && this.filterText5) {
        // this.thirdSubmit(this.filterText5)
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3) {
        const filterTextlower1 = Number(this.filterText3);
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1
        );
      } else if (this.filterText1) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText2) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText4) {
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText5) {
        this.thirdSubmit(this.filterText5)
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.date.includes(filterDatelower5)
        );
        console.log(this.activelogs.data)
      } else {
        console.log("this is else condition")
        return this.activelogs.data;
      }
      console.log("is it printing")
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    },
    getColumnClass(index) {
      // Add a specific class to the second column
      return index === 3 ? "modified-column" : "";
    },
    getColumnContent(col, index) {
      // Modify the content of the second column
      if (index === 3) {
        return `<i class="fas fa-user"></i> ${col}`;
      } else {
        return col;
      }
    },
  },
  }
  </script>
  <style>
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
    margin-top: 200px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .gap{
  margin-right: 5px;
  border-color:black;
  width: 250px;
  }
  .gap2{
  margin-top: 10px;
  border-color:black;
  width:15%;
  background:white;
  }
  .gap3{
  margin-left: 5px;
  margin-top: 5px;
  }
  .gap4{
  margin-left:20px;
  width: 100px;
  height: 48px;
  }
  .gapbutton{
  margin-left:20px;
  width: 200px;
  height: 48px;
  }
  .gap5{
  margin-left:20px;
  width: 120px;
  height: 48px;
  }
  .info{
    padding-left:20px;
  }
  .scroll{
    overflow-y:auto;
    max-height: 50px;
  }
  </style>
  <script setup>
  const userType = localStorage.getItem('userType');
  </script>
  