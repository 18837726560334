<template>
    <div>
        <section class="log__page">
            <img src="/static/img/bac3.jpg" alt="background image">
            <img src="https://www.bizinso.com/wp-content/uploads/2022/07/Logo-for-website-100px-width.svg" class="logo__img"
                alt="logo image">
            <div class="login__form wiregisterfrm">
                <div class="text-center">
                    <h3>Register user</h3>
                    <p>Hey, Enter your details to get sign up <br>to your account</p>
                </div>
                <form >
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="input-field">
                                <input type="text" name = "first_name" v-model="userData.first_name" required spellcheck="false">
                                <label>Enter Firstname</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-field">
                                <input type="text" name = "last_name" v-model="userData.last_name" required spellcheck="false">
                                <label>Enter Surname</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-field">
                                <input type="text" name = "username" v-model="userData.username" required spellcheck="false">
                                <label>Enter Username</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-field">
                                <input type="text" name = "email" v-model="userData.email" required spellcheck="false">
                                <label>Enter Email</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-field">
                                <input type="password" name = "password" v-model="userData.password" required spellcheck="false">
                                <label>Enter Password</label>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6">
                            <div class="input-field">
                                <input type="text" name = "confirm_password"  required spellcheck="false">
                                <label>Enter Confirm Password</label>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-lg-12">
                        <button type="button" @click="register">Sign up</button>
                    </div>
                    <p class="signup__line">Already have an account? <router-link to="/log"><b>Login now</b></router-link></p>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
import axios from '@axios'

export default {
  name: 'register',
  components: {
    },
    data() {
        return {
            userData: {
                first_name:null,
                last_name:null,
                username:null,
                email:null,
                password:null,
            }
        }
    },
    methods: {
        register() {
            axios.post('register_store/', this.userData).then(response=>{
                this.$router.push({name : 'login'})
            }).catch(error=>{
                alert("Inavlid Detail!!")
            })
        }
    }
}
</script>