<template>
    <div class="row">
        <div class="col-lg-2">
            <div v-if="loading" >
                <div class="loader"></div>
                <h3 align="center" v-if="loading" >Loading Data...</h3>
            </div>
            <div v-if="!loading">
            <br>
            <br>
            <div><b>Suppliers Inside [<span><img src="../../assets/img/pingreen.png" alt="map"></span>]<b class="checkmark"></b>:</b></div>
            <div v-for="(item, index) in inside" :key="index">
                <div>
                    <b>{{ item.supplier }}</b>
                    <span class="green infogap">{{ item.inside_counts }}</span>
                </div>
            </div>
            <br>
            <div><b>Suppliers Outside [<span><img src="../../assets/img/pin.png" alt="map"></span>] <b class="checkmark"></b>:</b></div>
            <div v-for="(item2, index2) in outside" :key="index2">
                <div>
                    <b>{{ item2.supplier }}</b>
                    <span class="red infogap">{{ item2.outside_counts }}</span>
                </div>
            </div>
        </div>
        </div>
        <div class="col-lg-10 p-4">
            <div class="card p-2 baseheight">
                <div v-if="loading" >
                    <div class="loader"></div>
                    <h3 align="center" v-if="loading" >Loading Data...</h3>
                </div>
                <div v-if="!loading">
                    <h4>Response data inside:</h4>

                    <div class="jham" v-for="sup in base" :key="sup.supplier">
                        <span class="minssa">{{sup.supplier}}- {{ sup.count }}</span>
                        <table border="1">
                            <thead>
                                <tr>
                                <th>Supplier</th>
                                <th>Supplier Code</th>
                                <!-- <th>Country Name</th> -->
                                <th>Hotel Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in sup.data" :key="index">
                                <td>{{ item.supplier }}</td>
                                <td>{{ item.supplier_code }}</td>
                                <!-- <td>{{ item.country_name }}</td> -->
                                <td>{{ item.hotel_name }}</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                </div>
                <div v-if="!loading">
                    <h4>Response data outside:</h4>

                    <div class="jham" v-for="sup in outbase" :key="sup.supplier">
                        <span class="minssa">{{sup.supplier}}- {{ sup.count }}</span>
                        <table border="1">
                            <thead>
                                <tr>
                                <th>Supplier</th>
                                <th>Supplier Code</th>
                                <!-- <th>Country Name</th> -->
                                <th>Hotel Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in sup.data" :key="index">
                                <td>{{ item.supplier }}</td>
                                <td>{{ item.supplier_code }}</td>
                                <!-- <td>{{ item.country_name }}</td> -->
                                <td>{{ item.hotel_name }}</td>
                                </tr>
                            </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const userType = localStorage.getItem('userType');
</script>

<script>
import axios from '@axios'
import GoogleMap from '../googlemap/GoogleMap.vue'
import {
    // BButton,
    // BFormInput,
    // BFormSelect,
    BPopover,
} from 'bootstrap-vue'

export default {
    data() {
        return {
            loading: false,
            selectedHotelname: null,
            selectedOption: "Hotels Master",
            selectedValue: null,
            selectedSupplier: null,
            min_lat: '',
            max_lat: '',
            min_long: '',
            max_long: '',
            showfilter: false,
            showfilter2: true,
            supplierData: [],
            hoteldetails: null,
            base:null,
            outbase:null,
            inside:null,
            outside:null,
        }
    },
    components: {
        // BFormInput,
        // BButton,
        // BFormSelect,
    },
    methods: {
        callMethodFromOtherComponent() {
            GoogleMap.openWindow();
        },
        countryGetList: function () {
            axios.post('/supplier', {
            }).then(response => {
                this.supplierData = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        applymapfilter() {
            this.loading=true,
            axios.post('/hotelinfo', {
                suppliername: this.selectedSupplier,
                supplierid: this.selectedValue,
            }, {
            }).then(response => {
                console.log(response.data)
                this.loading=false,
                this.hoteldetails = response.data
            }).catch(error => {
                this.loading=false
                this.$swal({
                    icon: 'error',
                    title: 'Data not available',
                });
                // this.showloader = true
                // document.getElementById("alertshowhide").classList.toggle("active__alert")
                // this.alertmsg = "Data Not Available"
                // setTimeout(function () {
                //     this.showalertmsg = false
                //     // document.getElementById("alertshowhide").classList.toggle("active__alert")
                // }, 8000)
                }
            )
        },
        closelist() {
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
        },
        hotelsdata(){
            this.loading=true,
            axios.post('hotelsdata',{
            location:this.$route.params.location,
            country:this.$route.params.country,
            user:this.$route.params.user,
            username:this.$route.params.username,
            state:this.$route.params.state,
            supplier:this.$route.params.supplier
            },{
            // { headers: { 'Access-Control-Allow-Origin': '*' }}
            }).then(response => {
                console.log(response.data.data)
                this.base = response.data.data.supplier_inside;
                this.outbase = response.data.data.supplier_outside;
                this.inside=response.data.data.inside_count;
                this.outside=response.data.data.outside_count;
                this.loading=false
            }).catch(error => {
                console.log("Something went wrong in reportInfo()")
            })
        },
    },
    mounted() {
        this.countryGetList();
        this.hotelsdata()

    }
}

</script>

<style>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  margin-top: 200px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.infogap {
    margin-left: 10px;
}

input:focus~label,
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.checkHeight {
    height: 12px !important;
}

.margBase {
    margin: 0 0 1rem 0;
}

.checkHeight input:checked~.checkmark {
    background-color: #2196F3 !important;
}

.input-field input,
.input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.checkmark {
    color: rgb(29, 81, 224);
    margin-left: 10px;
}

.bg-under {
    background-color: #00ae08 !important;
}

.bg-outside {
    background-color: #c81b00 !important;
}

.bg-info {
    background-color: blue !important;
}

.city-text {
    text-transform: uppercase;
}

.green {
    color: #00ae08;
}

.red {
    color: #c81b00;
}

.blue {
    color: blue
}

input[type="checkbox"] {
    transform: scale(1.5);
}

.destination_save {
    width: 140px;
    margin-left: 10px;
    margin-right: 10px;
}

.look {
    color: black;
}

.search1 {
    height: 35px;
    width: 290px;
}

.red {
    color: #c81b00;
}

.black {
    color: black;
}
.baseheight{
    height:80vh !important;
    overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px !important;
}
.jham{
    font-size: 16px;
    font-weight: 700;
}
.minssa{
    text-transform: capitalize;
    border-left: 6px solid #435eab;
    padding-left: 8px;
}
th{
    background-color: #435eab;
}
th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

thead {
  background-color: #f4f4f4;
}

tr:hover {
  background-color: #f9f9f9;
}

</style>