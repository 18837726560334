<template>
  <div id="root" class="container mt-4">
    <h1 class="text-center">Drawing Manager Example</h1>

    <!-- Buttons outside gmap -->
    <b-row class="mb-3">
      <b-col>
        <b-button @click="setDrawingMode('rectangle')" variant="primary">Rectangle</b-button>
      </b-col>
      <b-col>
        <b-button @click="setDrawingMode('circle')" variant="primary">Circle</b-button>
      </b-col>
      <b-col>
        <b-button @click="setDrawingMode('polyline')" variant="primary">Line</b-button>
      </b-col>
      <b-col>
        <b-button @click="setDrawingMode('polygon')" variant="primary">Polygon</b-button>
      </b-col>
      <b-col>
        <b-button @click="deleteSelection" variant="danger">Delete</b-button>
      </b-col>
      <b-col>
        <b-button @click="save" variant="success">Save</b-button>
      </b-col>
    </b-row>

    <!-- Map Section -->
    <gmap-map
      ref="mapRef"
      :center="mapCenter"
      :zoom="7"
      map-type-id="roadmap"
      style="width: 100%; height: 800px; display:flex; justify-content: center; align-items: flex-start;"
      :options="mapOptions"
    >
      <template #visible>
        <gmap-drawing-manager
          v-if="mapMode === 'edit'"
          :rectangle-options="rectangleOptions"
          :circle-options="circleOptions"
          :polyline-options="polylineOptions"
          :polygon-options="polygonOptions"
          :shapes="shapes"
          @overlaycomplete="handleOverlayComplete"
        >
          <template v-slot="{ setDrawingMode, deleteSelection }">
            <!-- Drawing toolbar buttons are now in a separate component -->
            <drawing-toolbar
              @drawingmode_changed="setDrawingMode"
              @delete_selection="deleteSelection"
              @save="save"
            />
          </template>
        </gmap-drawing-manager>
      </template>
    </gmap-map>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapCenter: { lat: 4.5, lng: 99 },
      mapMode: null,
      mapDraggable: true,
      mapCursor: null,
      shapes: [],
      rectangleOptions: {
        fillColor: '#777',
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: '#999',
        draggable: false,
        editable: false,
        clickable: true,
      },
      circleOptions: {
        fillColor: '#777',
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: '#999',
        draggable: false,
        editable: false,
        clickable: true,
      },
      polylineOptions: {
        fillColor: '#777',
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: '#999',
        draggable: false,
        editable: false,
        clickable: true,
      },
      polygonOptions: {
        fillColor: '#777',
        fillOpacity: 0.4,
        strokeWeight: 2,
        strokeColor: '#999',
        draggable: false,
        editable: false,
        clickable: true,
      },
    };
  },
  computed: {
    mapOptions() {
      return {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        draggable: this.mapDraggable,
        draggableCursor: this.mapCursor,
      };
    },
  },
  watch: {
    mapMode(newMode, oldMode) {
      if (newMode === 'ready') {
        if (oldMode === 'edit') {
          this.mapDraggable = true;
          this.mapCursor = null;
          return;
        }
      }

      if (newMode === 'edit') {
        this.mapDraggable = false;
        this.mapCursor = 'default';
      }
    },
  },
  methods: {
    setDrawingMode(mode) {
      this.mapMode = 'edit';
      this.drawingMode = mode;
    },
    handleOverlayComplete(overlay) {
      // Handle overlay complete event
      console.log('Overlay complete:', overlay);
    },
    deleteSelection() {
      // Implement delete logic
      console.log('Delete selection');
    },
    save() {
      // Implement save logic
      console.log('Save');
    },
  },
};
</script>

<style scoped>
/* Add any specific styles if needed */
</style>
