<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card >
                        <h2 class='pageHeading'>Logs</h2>
                        <b-row>
                            <b-col md="12" class='mt-3'>
                                <!-- <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">User Name:</p>
                                        <p class="subHeadBase">{{  }}</p>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Error:</p>
                                        <p class="subHeadBase" >{{ error }}</p>
                                    </div>
                                </div> -->
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">User Name:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ userName }}</p>
                                            </b-col>
                                        </b-row>  
                                        
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Error:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ error }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                    <p class="headBase">City:</p>
                                        <p class="subHeadBase">{{ city }}</p>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Country:</p>
                                        <p class="subHeadBase">{{ country }}</p>
                                    </div>
                                </div> -->
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">City:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ city }}</p>
                                            </b-col>
                                        </b-row>  
                                        
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Country:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ country }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Start Time:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ time }}</p>
                                            </b-col>
                                        </b-row>  
                                        
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">End Time:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ end_time }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Supplier:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ suppliers }}</p>
                                            </b-col>
                                        </b-row>  
                                        
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Date:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ date }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Status :</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ status }}</p>
                                            </b-col>
                                        </b-row>  
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Logs From:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ flag }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Time Taken:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ time_taken }}</p>
                                            </b-col>
                                        </b-row>  
                                        
                                    </b-col>
                                    <b-col md="6">
                                        <b-row>
                                            <b-col md="4">
                                                <p class="headBase2">Polygon Data From:</p>
                                            </b-col>
                                            <b-col md="8">
                                                <p class="subHeadBase">{{ source }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase2">Time Taken:</p>
                                        <p class="subHeadBase">{{ time_taken }}</p>
                                    </div>
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase2">Polygon Data From:</p>
                                        <p class="subHeadBase">{{ source }}</p>
                                    </div> -->
                                    <!-- <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Response Status:</p>
                                        <p class="subHeadBase">{{ response_status }}</p>
                                    </div> -->
                                    <!-- <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">End Time:</p>
                                        <p class="subHeadBase">{{ end_time }}</p>
                                    </div> -->
                                <!-- </div> -->
                                <!-- <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-start w-50 px-2">
                                        <p class="headBase">Polygon Data From:</p>
                                        <p class="subHeadBase">{{ source }}</p>
                                    </div>
                                </div> -->
                            </b-col>
                            <!-- <b-col md="3" class='mt-3'>
                                <b-card class="bg-blue mb-2">
                                    <p class="headDetails">{{status}}</p>
                                    <p class="mb-0">Status</p>
                                </b-card>
                                <b-card class="bg-blue mb-2">
                                    <p class="headDetails">{{ flag }}</p>
                                    <p class="mb-0">Logs From</p>
                                </b-card>
                            </b-col> -->
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <!-- <b-button class="gap5" variant="primary" @click="toggleBasics()">
                                    <span v-if="hiddenPart">Hide Logs</span>
                                    <span v-else>Show Logs</span>
                                </b-button> -->
                                <b-button class="gap10" variant="primary" @click="previous"> Previous Page</b-button>
                                <b-button class="gap10" variant="primary" @click="downloadData"> Download Logs</b-button>
                                <b-button v-if="this.source =='osm'|| this.source =='location iq' || this.source =='local_osm' || this.source =='nominatim'" class="gap10" variant="primary" @click="osmresponse"> Download Osm Response</b-button>
                            </b-col>
                            <!-- <b-col v-if="hiddenPart">
                                <div class="d-flex justify-content-between">
                                    <div class="content-box">
                                        <h5 class="subHeadBase2"><u>Search Logs</u></h5>
                                        <p class="subHeadBase">{{ search }}</p>
                                    </div>
                                    <div class="content-box2">
                                        <h5 class="subHeadBase2"><u>Response Logs</u></h5>
                                        <p class="subHeadBase">{{ response }}</p>
                                    </div>
                                </div>
                            </b-col> -->
                        </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.7.1/jszip.min.js"></script>
<script>
import axios from '@axios'
import JSZip from 'jszip';
// const JSZip = window.JSZip;
import {
    BRow,
    BCol,
    BCard,
    BButton
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton
    },
    data() {
        return {
            userName:'',
            city:'',
            country:'',
            date:'',
            time:'',
            error:'',
            status:'',
            suppliers:'',
            flag:'',
            search:'',
            response:'',
            response_status:'',
            end_time:'',
            time_taken:'',
            source:'',
            osm_response:'',
            hiddenPart : false,
            jsonData1:{
                Title:'Search Logs',
                Time:'',
                Date:'',
                Search:'',
            },
            jsonData2:{
                Title:'Response Logs',
                Time:'',
                Date:'',
                Time_Taken:'',
                Response:'',
            },
            jsonData3:{
                Response:''
            }
        }
    },
    methods: {
        toggleBasics(){
            this.hiddenPart = !this.hiddenPart;
        },
        previous(){
            this.$router.go(-1);
        },
        reportInfo:function(){
            axios.post('searchreport',{
                location:this.$route.params.location,
                country:this.$route.params.country,
                user:this.$route.params.user,
                time:this.$route.params.time,
                date:this.$route.params.date,
            },{
            // { headers: { 'Access-Control-Allow-Origin': '*' }}
            }).then(response => {
                this.userName = response.data.report.username
                this.city = response.data.report.city
                this.country = response.data.report.country
                this.date = response.data.report.date
                this.time = response.data.report.time
                this.error = response.data.report.error
                this.status = response.data.report.status
                this.suppliers = response.data.report.suppliers
                this.flag = response.data.report.flag
                this.search=response.data.report.search
                this.response=response.data.report.response
                this.response_status=response.data.report.response_status
                this.end_time=response.data.report.end_time
                this.time_taken=response.data.report.time_taken
                this.source=response.data.report.source
                this.osm_response=response.data.report.osm_response
            }).catch(error => {
                console.log("Something went wrong in reportInfo()")
            })
        },
        // downloadData1() {
        //     this.jsonData1.Time=this.time
        //     this.jsonData1.Date=this.date
        //     this.jsonData1.Search=this.search
        //     // this.jsonData.Polygon_Data_From=this.source
        //     // this.jsonData.Osm_Response=this.osm_response
        //     const jsonData = JSON.stringify(this.jsonData1, null, 2); // Convert JSON object to a nicely formatted JSON string
        //     this.downloadTextFile(jsonData,`${this.userName}_search_log_${this.city}_${this.date}.json`);
        //     // zip.file(`${this.userName}_search_log_${this.city}_${this.date}.json`, jsonData);
        // },
        // downloadData2() {
        //     this.jsonData2.Time=this.time
        //     this.jsonData2.Date=this.date
        //     this.jsonData2.Time_Taken=this.time_taken
        //     this.jsonData2.Response=this.response
        //     // this.jsonData.Polygon_Data_From=this.source
        //     // this.jsonData.Osm_Response=this.osm_response
        //     const jsonData = JSON.stringify(this.jsonData2, null, 2); // Convert JSON object to a nicely formatted JSON string
        //     this.downloadTextFile(jsonData,`${this.userName}_response_log_${this.city}_${this.date}.json`);
        //     // zip.file(`${this.userName}_search_log_${this.city}_${this.date}.json`, jsonData);
        // },
        downloadData1() {
            const jsonData1={
                Date:this.date,
                Time:this.time,
                Search:this.search,
            };
            return JSON.stringify(jsonData1, null, 2);
        },
        downloadData2() {
            const jsonData2={
                Date:this.date,
                Start_Time:this.time,
                End_Time:this.end_time,
                Time_Taken:this.time_taken,
                Response:this.response,
            }
            return JSON.stringify(jsonData2, null, 2);
        },
        osmresponse(){
            this.jsonData3.Response=this.osm_response
            const jsonData = JSON.stringify(this.jsonData3, null, 2); // Convert JSON object to a nicely formatted JSON string
            this.downloadTextFile(jsonData,`${this.userName}_osmresponse_${this.city}_${this.country}.json`);

        },
        downloadTextFile(text, filename) {
            const blob = new Blob([text], { type: "application/json" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        },
        downloadZip() {
            const zip = new JSZip();

            // Adding JSON data to zip
            zip.file(`${this.userName}_search_log_${this.city}_${this.date}.json`, this.downloadData1());
            zip.file(`${this.userName}_response_log_${this.city}_${this.date}.json`, this.downloadData2());

            // Generate the zip file
            console.log("function is called for zip")
            zip.generateAsync({ type: "blob" })
            .then(content => {
                // Create a link element
                const link = document.createElement("a");
                link.href = URL.createObjectURL(content);
                link.download = `${this.userName}_logs_${this.city}_${this.date}.zip`;

                // Trigger the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        // downloadTextFile2(text, filename) {
        //     const blob = new Blob([text], { type: "application/json" });
        //     const url = window.URL.createObjectURL(blob);
        //     const a = document.createElement("a");
        //     a.href = url;
        //     a.download = filename;
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        // },
        // downloadTextFile3(text, filename) {
        //     const blob = new Blob([text], { type: "application/json" });
        //     const url = window.URL.createObjectURL(blob);
        //     const a = document.createElement("a");
        //     a.href = url;
        //     a.download = filename;
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        // },
        
        downloadData(){
            // this.downloadData1()
            // this.downloadData2()
            this.downloadZip();

        },
    },
    mounted() {
        this.list = this.$route.params.id;
        this.reportInfo()
    }
}

</script>
<style>
.subHeadBase{
    color:black;
    font-weight: bold;
}
.content-box {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    width: 300px;
    max-height: 200px;
    overflow: auto;
}
.content-box2 {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px;
    width: 1000px;
    max-height: 200px;
    overflow: auto;
}
.download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: none;
}
.gap5{
  margin-left:10px;
  width: 200px;
  height: 48px;
}
.gap10{
  width: 250px;
  height: 48px;
  margin-right: 30px;
}
.subHeadBase2{
    color:black;
    font-weight: bold;
    margin-bottom: 20px;
}
.headBase2{
    font-size: 16px;
    font-weight: 600;
}
</style>