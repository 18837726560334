<template>
    <div>
        <div class="map__section">
            <div class="map__filter" :class="[showfilter ? 'collapse__menu' : '']">
            <!-- <div v-if="userType=='Admin'" class="map__filter" :class="[showfilter ? 'collapse__menu' : '']"> -->
                <!-- <h3><b-icon icon="map"></b-icon> Custom Map <span class="close__icon" @click="closelist"><b-icon icon="x-square"></b-icon></span></h3>
                <p>Enter location details here to create custom map!</p>
                <hr>
                <div class="form__map">
                    <div class="form__group">
                        <div class="input-field" >
                                <b-form-select v-model="selectedCountry" class="form-control" :options="countryData" ></b-form-select>
                            <label>Select Country</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedValue" @keyup.enter="applymapfilter" required spellcheck="false"
                                autocomplete="off"></b-form-input>
                            <label>Enter City</label>
                        </div>
                    </div>
                </div>
                <div class="navigation__filter text-end height-button">
                    <b-button @click="multiplefunction">Show Location &nbsp;</b-button>
                </div> -->
                <div class="card_space">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="Success" class="w-100 d-flex justify-content-between size-header bg-info">
                                <h5 class="text-white mb-0">Polygon Coordinates</h5>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <div class="polygon__listing">
                                    <div v-if="userType=='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in osmlist" :key="index"  @click="gotolocation2(item,index)">
                                            <p><b>OSM  :</b> <span class="space_between">{{ item.Location}}</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                    <div v-if="userType=='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in expediapolygondata" :key="index"  @click="gotolocation3(item,index)">
                                            <p><b>EXPEDIA  : </b> <span class="space_between">{{ item.name_full}}</span><span class="space_between">({{ item.destination_type}})</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                    <div v-if="userType!='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in osmlist" :key="index"  @click="gotolocation2(item,index)">
                                            <p><span class="space_between">{{ item.Location}}</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                    <div v-if="userType!='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in expediapolygondata" :key="index"  @click="gotolocation3(item,index)">
                                            <p><span class="space_between">{{ item.name_full}}</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                    <div v-if="userType!='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in worldpolygondata" :key="index"  @click="gotolocation1(item,index)">
                                            <p><span class="space_between">{{ item.location}}</span><span class="space_between">{{ item.district}}</span><span class="space_between">{{ item.country }}</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                    <div v-if="userType=='Admin'">
                                        <div class="polygon__listbox basicFinger" v-for="(item, index) in worldpolygondata" :key="index"  @click="gotolocation1(item,index)">
                                            <p><b>WLL  : </b> <span class="space_between">{{ item.location}}</span><span class="space_between">{{ item.district}}</span><span class="space_between">{{ item.country }}</span><span class="look">[{{ item.sq_km }}]</span></p>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
                <!-- <div class="card_space">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="Success" class="w-100 d-flex justify-content-between size-header bg-info">
                                <h5 class="text-white mb-0">Polygon [Osm]</h5>
                            </b-button>
                        </b-card-header>
                        <b-collapse id="accordion-2" visible accordion="my-accordion1" role="tabpanel">
                            <b-card-body>
                                <div class="polygon__listing">
                                    <div class="polygon__listbox basicFinger" v-for="(item, index) in osmlist" :key="index"  @click="gotolocation2(item,index)">
                                        <p><b>Location  :</b> <span class="space_between">{{ item.Location.toUpperCase() }}</span>,<span>{{ item.Type }} <span class="look">({{ item.sq_km  }})</span></span></p>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div> -->
                <div class="close__map__filter" @click="showfilter = !showfilter"><b-icon icon="list"
                    class="trans90"></b-icon></div>
                </div>                
            <PolygonMap ref="PolygonMapComponent" :citydata="$route.params.city" :countrydata="$route.params.country" :statedata="$route.params.state" :sourcedata="$route.params.source" :userdata="$route.params.userid" v-if="showPolygonMap" />
        </div>
        <div id="alertshowhide" class="alert__msg"> 
            <div class="hed__div">Error!</div>
            <p>{{alertmsg}}</p>
        </div>
    </div>
</template>

<script setup>
    const userType = localStorage.getItem('userType');
</script>

<script>
import axios from '@axios'
import PolygonMap from '../googlemap/PolygonMap.vue'
import {BButton} from 'bootstrap-vue'

export default {
    data() {
        return {
            // selectedCountry:'',
            // selectedValue:'',
            countryData:[],
            showPolygonMap:true,
            showloader:false,
            showfilter: false,
            showfilter2: true,
            worldpolygondata:[],
            expediapolygondata:[],
            osmlist:null,
        }
    },
    components: {
        // BFormInput, 
        BButton,
        PolygonMap,
        // BFormSelect,
    },
    methods: {
        callChildFunctionfinal() {
            if (this.selectedCountry !=null && this.selectedValue !=null){
                this.callChildFunction()
            }
        },
        osmdata:function(){
            axios.post('/osmresponse',{
                location:this.$route.params.city,
                state:this.$route.params.state,
                country:this.$route.params.country,
                index:this.$route.params.index,
                user:this.$route.params.user,
            },{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.osmlist = response.data.locations;
                this.testlocation = this.$route.params.location;
                this.testcountry = this.$route.params.country;
                this.teststate=this.$route.params.state;
                this.testindex = this.$route.params.index;
                this.current_index = response.data.index;
                this.index_value = response.data.index_value;
                this.multiplefunction();
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        callChildFunction() {
            this.selectedValue=this.$route.params.city,
            this.selectedCountry=this.$route.params.country,
            this.selectedsource=this.$route.params.country
            // console.log("function is called test")
            this.showPolygonMap=true
            this.$refs.PolygonMapComponent.CenterCoordinates();
            // this.showPolygonMap=true
        },
        countryGetList: function(){
            axios.get('/country_list', {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.countryData = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        expediapolygon:function(){
            axios.post('/expediapolygon',{
                city:this.selectedValue,
                country:this.selectedCountry,
            }, {
                    // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.expediapolygondata = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        worldpolygon: function(){
            axios.post('/worldpolygon',{
                city:this.selectedValue,
                country:this.selectedCountry,
            }, {
                    // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.worldpolygondata = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        closelist(){
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
        },
        gotolocation1(item,index){
            this.$refs.PolygonMapComponent.worldpolygoncoordinates(item,index);
        },
        gotolocation2(item,index){
            this.$refs.PolygonMapComponent.getIndex(item,index);
        },
        gotolocation3(item,index){
            this.$refs.PolygonMapComponent.expediapolygoncoordinates(item,index);
        },
        multiplefunction() {
            this.callChildFunction();
            this.worldpolygon();
            this.expediapolygon();
        },
    },
    mounted() {
        this.selectedValue=this.$route.params.city,
        this.selectedCountry=this.$route.params.country,
        this.osmdata()
    },
}

</script>
<script setup>
    const userType = localStorage.getItem('userType');
</script>

<style>
/* .sidebar__right .navbar__section {
    padding: 0px;    
}
    .sidebar__right .navbar__section:before {
    height: 0 !important;
}
.sidebar__right .navbar__section h3 {
    display: none;
}
.sidebar__right .navbar__section p {
    display: none;
}
.sidebar__right .navbar__section .other__navpart {
    display: inline-flex;
    position: absolute;
    right: 20px;
    top: 15px;
} */
select{
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
.infogap{
    margin-left: 10px;
}
input:focus~label, 
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}
.checkHeight{
    height: 12px !important;
}
.margBase{
    margin: 0 0 1rem 0;
}
.checkHeight input:checked ~ .checkmark {
  background-color: #2196F3 !important;
}
.input-field input, .input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
.checkmark{
    color:rgb(29, 81, 224) ;
    margin-left: 10px;
}
.bg-under{
    background-color: #00ae08 !important;
}
.bg-outside{
    background-color: #c81b00 !important;
}
.bg-info{
    background-color:blue !important;
}
.city-text{
    text-transform: uppercase;
}
.green{
    color: #00ae08;
}
.red{
    color:#c81b00;
}
input[type="checkbox"] {
  transform: scale(1.5); /* Adjust the scale factor to make it larger */
}
.destination_save{
    width:140px;
    margin-left:10px;
    margin-right:10px;
}
.look{
    color: black;
}
.search1{
    height:35px;
    width:290px;
}
.red{
    color: #c81b00;
}
.black{
    color: black;
}
.polygon__listbox{
    border-radius: 0;
    padding-bottom: 0px;
    margin-bottom: 11px;
    border-bottom: 1px solid #dadce0;
    /* overflow-y: auto;
    max-height: 220px;  */
}
.polygon__listing{
    height: 80vh;
    overflow: scroll;
    /* width */
    &::-webkit-scrollbar {
        width: 4px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}
.size-header{
    height:35px;
}
.height-button{
    height:45px;
}
.space_between{
    margin-right: 5px;
}
.card_space{
    margin-top: 20px;
}
</style>
