<template>
    <div>
        <div class="map__section">
            <div class="map__filter" :class="[showfilter ? 'collapse__menu' : '']">
                <h4>Save Polygon Response</h4>
                <p>Enter location details here to view polygon response</p>
                <hr>
                <div class="form__map">
                    <div class="form__group">
                        <div class="input-field" >
                                <b-form-select v-model="selectedCountry" class="form-control" :options="countryData" ></b-form-select>
                            <label>Select Country</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedCity" @keyup.enter="applymapfilter" required spellcheck="false"
                                autocomplete="off"></b-form-input>
                            <label>Enter City</label>
                        </div>
                    </div>
                </div>
                <div class="navigation__filter text-end height-button">
                    <b-button @click="osmdata()">Show Response&nbsp;</b-button>
                </div>
                </div>
                <br>
                <div class="map__filter2" :class="[showfilter2 ? 'collapse__menu2' : '']">
                <h3><b-icon icon="map"></b-icon>&nbsp;&nbsp;Polygon Response<span class="close__icon" @click="closelist"><b-icon icon="x-square"></b-icon></span></h3>
                <hr>                
                <div class="text-center mar_top" v-if="showloader">
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
                <div v-else>
                    <h4>Source :<span>{{ this.source }}</span></h4>
                    <b-card no-body class="mb-1" v-if="osmlist != 'null'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="primary" class="w-100 d-flex justify-content-between align-items-center">
                            <h5 v-if="osmlist != 'null'" class="city-text"><b-icon icon="geo-alt"></b-icon> Response for {{ this.selectedCity1 }}</h5>
                            <b-icon id="popover-target-3" icon="exclamation-circle"></b-icon>
                            <b-popover target="popover-target-3" triggers="hover" placement="top">
                                RESPONSE FOR <b class="city-text">{{ this.selectedCity1 }}</b>
                            </b-popover>
                            <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div v-if="osmmlist != 'null'"  class="osm__listing">
                                <div v-for="(item3, index) in osmlist" :key="index">                    
                                    <div class="supplier__listbox basicFinger"  @click="getIndex(index,item3)" >
                                        <!-- <label class="catdivid" :for="`catdivid${index}`">
                                            <div>
                                                <p><b>Type : </b> <span>{{ item3.Type }}</span></p>
                                                <p><b>Location :</b> <span>{{ item3.Location }}</span></p>
                                            </div>
                                            <div>
                                                <input type="radio" name="locatn" :id="`catdivid${index}`">
                                            </div>
                                        </label> -->
                                        <div>
                                            <p><b>Type : </b> <span>{{ item3.Type }} </span> <span class="numrightspan">{{ item3.sq_km}}km²</span></p>
                                            <p><b>Location :</b> <span>{{ item3.Location }}</span></p>
                                            <p class="current">
                                                <b class=" verify" v-if="index == item3.current">current</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-button variant="primary" class="save" @click="final">Save</b-button>
                </div>
                <!-- <div>
                    <b-button variant="primary" class="save" @click="semi_final">Delete</b-button>
                </div> -->
            </div>
           
            <FilterGoogleMap :setpolygonline="setboundarydata" v-if="showFilterGoogleMap" />

            <div class="text-center" v-if="showloader2">
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <div>
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
            </div>
            <div class="text-center" v-else>
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <p class="map__message">Please select appropriate polygon Co-ordinates.</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@axios'
import FilterGoogleMap from '../googlemap/FilterGoogleMap.vue'
import {BButton, BSpinner,
BCard,
BCardHeader,
BCollapse,
BCardBody,
BPopover,} from 'bootstrap-vue'

export default {
    // setup() {
    //     // const list = this.$route.params.id;
    //     // console.log("setup list", list)
    //     // console.log("setup this.$route", this.$route)
    //     // return{
    //     //     list
    //     // }

    // },
    data() {
        return {
            selectedCountry:null,
            selectedCity:null,
            selectedCountry1:null,
            selectedCity1:null,
            countryData:[],
            showfilter: false,
            showfilter2: true,
            showFilterGoogleMap:false,
            showloader:false,
            showloader2:false,
            alertmsg:null,
            nullBase:null,
            osmlist:null,
            getclickindex:{
                city:null,
                country:null,
                index:null
            },
            list: {
                country: null,
                location: null,
                user: null
            },
            setboundarydata:[],
            savepolygon:[],
            deletedata:null,
            source:null,
        }
    },
    components: {
        BButton,
        FilterGoogleMap,
        BSpinner,
        BCard,
        BCardHeader,
        BCollapse,
        BCardBody,
        BPopover,
    },
    methods: {
        closelist(){
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
            // this.selectedValue=''
        },
        countryGetList: function(){
            axios.get('/country_list', {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.countryData = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        osmdata:function(){
            this.showloader=true
            this.showfilter = !this.showfilter
            this.showfilter2 = !this.showfilter2
            axios.post('/osmresponse',{
                location:this.selectedCity,
                country:this.selectedCountry,
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.osmlist=response.data.locations
                this.source=response.data.source
                this.selectedCountry1=this.selectedCountry
                this.selectedCity1=this.selectedCity
                // if (response.data.locations)
                this.showloader=false
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        getIndex(ind , item){
            this.showloader2=true
            this.showFilterGoogleMap = false;
            this.getclickindex.city = item.City
            this.getclickindex.country = item.Country
            this.getclickindex.index = ind
            axios.post('/checkpolygon',this.getclickindex).then(response => {
                this.setboundarydata=response.data
                if(this.setboundarydata.length > 0 ){
                    this.showFilterGoogleMap = true;
                    this.list = this.$route.params.id;
                    this.showloader2=false
                }            
            }).catch(error => {
                // console.log("Something went wrong in setpolygon()")
            })
        
        },
        final :function(){
            this.showloader=true
            this.showFilterGoogleMap = false;
            axios.post('/adminsavecoordinates',this.getclickindex
                // headers: { 'Access-Control-Allow-Origin': '*' }
            ).then(response => {
                this.savepolygon=response.data
                this.showFilterGoogleMap = true;
                this.list = this.$route.params.id;
                this.showloader=false
                this.$swal({
                    icon: 'success',
                    title: 'Polygon Updated Successfully',
                    text: this.response,
                });
                // console.log("savepolygon",this.savepolygon)
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        semi_final :function(){
            axios.post('/deletedata',this.getclickindex
                // headers: { 'Access-Control-Allow-Origin': '*' }
            ).then(response => {
                this.deletedata=response
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },

    },
    mounted() {
        this.countryGetList();
    }
}

</script>

<style>
select{
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
input:focus~label, 
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.input-field input, .input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
.bg-under{
    background-color: #00ae08 !important;
}
.bg-outside{
    background-color: #c81b00 !important;
}
.city-text{
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
}
#accordion-2{
    height: 450px;
    overflow-y: auto;
}
.map__filter p {
    font-size: 12px;
    font-weight: 500;
    color: inherit !important;
}
.supplier__listbox{
    border-bottom: thin solid #ddd;
    margin-bottom: 18px;
    padding: 12px;
    border-radius: 12px;
}
.supplier__listbox:hover{
    border-bottom: thin solid #ddd;
    background-color: #0d6efd;
    color: #fff !important;
}
.supplier__listbox:hover .numrightspan{
    color: #fff;
}
.basicFinger{
    cursor: pointer;
}
.card-body{
    padding: 8px !important;

    
}
.save{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1px;
}
.current{
    text-align: end;
}
.verify{
    color: green;
}
</style>


