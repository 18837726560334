<template>
    <div>

        <b-row class="masterCollapse">
            <b-col lg="12" class="mt-3">

                <div class="d-flex justify-content-start align-items-center">

                    <b-button :class="visible ? 'activeindicatorButtons' : 'collapsed indicatorButtons'"
                        :aria-expanded="visible ? 'true' : 'false'" aria-controls="collapse-1" @click="downloadFun">
                        Download Data
                    </b-button>
                    <b-button :class="invisible ? 'activeindicatorButtons' : 'collapsed indicatorButtons'"
                        :aria-expanded="invisible ? 'true' : 'false'" aria-controls="collapse-4" @click="uploadFun">
                        Assign Data to Agent
                    </b-button>
                </div>
                <b-collapse id="collapse-1" v-model="visible" class="">
                    <b-card class="p-2">
                        <h4 class='headingMain mb-0'>Download Data</h4>
                        <div>
                            <div class="form__group mainFormFilter">
                                <b-form-select v-model="selectedcountry" class="form-list"
                                    :options="countrylist"></b-form-select>
                                <b-form-input class="form-list" type="text" v-model="selectedcity" placeholder="City..." />
                                <b-button class="button_size_csv browseBTN" variant="primary"
                                    @click="Gethoteldata">
                                    <b-spinner small v-if="loaderSearchButton" class="mx-1"></b-spinner>SEARCH</b-button>
                                <b-button class="button_size_csv browseBTN" variant="primary" v-if="hotellist"
                                    @click="DownloadCSV(hotellist, `Hotels_List_${selectedcity}_${selectedcountry}.csv`)">DOWNLOAD</b-button>
                            </div>
                        </div>
                        <b-card-body class="ListTable" v-if="hotellist">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="sideDivider">
                                    <h3>Agent List</h3>
                                </div>
                                <div class="centerdivider">
                                    Search Filter:
                                    <b-form-input class="form-list inputfilter" type="text" v-model="filterdata"
                                        placeholder="Filter..." />
                                </div>
                            </div>
                            <div class="text-center position-loader" v-if="showloader">
                                <div>
                                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                                </div>
                            </div>

                            <div>
                                <div class="supplier__listbox_new supplier__listbox_header d-flex align-items-center">
                                    <div class="mb-0 magnitude">
                                        <p class="firstName">Names</p>
                                        <p>Rating</p>
                                        <p>Latitude: </p>
                                        <p>Longitude:</p>
                                        <p>Supplier: </p>
                                    </div>
                                </div>
                                <div class="supplier__listbox_new d-flex align-items-center"
                                    v-for="(item, index) in paginatedHotels" :key="index">
                                    <div class="mb-0 magnitude">
                                        <p class="firstName">{{ item.hotel_name }} ({{ item.supplier_code }})</p>
                                        <p class="d-flex justify-content-start align-items-center">
                                            <span class="d-flex justify-content-between">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 1 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 2 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 3 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 4 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 5 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <span v-if="item.rating == 0 || item.rating == 'SC'"><b>SC</b></span>
                                            </span>
                                        </p>
                                        <p>{{ item.latitude }}</p>
                                        <p>{{ item.longitude }}</p>
                                        <p>{{ item.supplier }}</p>
                                    </div>
                                </div>
                            </div>
                            <b-pagination v-model="currentPage" :total-rows="filteredHotels ? filteredHotels.length : 0"
                                :per-page="perPage"></b-pagination>
                        </b-card-body>

                    </b-card>
                </b-collapse>
                <b-collapse id="collapse-4" v-model="invisible" class="">
                    <b-card class="p-2">
                        <h4 class='headingMain mb-0'>Assign Data</h4>

                        <b-row class="mt-3">
                            <b-col md="4" class="alignerPlate">  
                            <div class="input-field multipless">
                                <div class="centerdivider">
                                    <!-- Search Filter: -->
                                    <b-form-input class="form-list inputfilter mb-1" type="text" v-model="filterdataAgent"
                                        placeholder="Agent Search..." />
                                </div>
                                <div class="dynamo">
                                    <div class="customChecks">
                                        <b-form-checkbox id="checkbox-select-all" v-model="selectAll"
                                            @change="selectAllCheck">
                                            Select All
                                        </b-form-checkbox>
                                        <b-form-checkbox v-for="(checkbox, index) in filteredAgentList" :key="index"
                                            :id="'checkbox-' + index" :value="checkbox" v-model="selectedagent">
                                            {{ checkbox }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            </b-col>
                            <b-col md="8" class="mainUploader">

                                <div class="file-drop-area" @dragover.prevent="handleDragOver"
                                    @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop"
                                    :class="{ 'dragover': isDragOver }">
                                    <b-icon icon="upload" class="iconforUpload"></b-icon>
                                    <p>Drag &amp; drop your files here or <span @click="handleFileInputClick"
                                            class="browseBTN">browse</span></p>
                                    <b v-if="resName != ''">Selected File: {{ resName }}</b>
                                    <b-form-file v-model="selectedFile" type="file" ref="fileInput" accept=".csv"
                                        placeholder="" class="d-none"></b-form-file>
                                </div>

                                <b-button class=" mt-3 w-100" variant="primary" @click="handleFileUpload">
                                    <b-spinner small v-if="loaderPendingButton" class="mx-1"></b-spinner>
                                    Upload</b-button>
                            </b-col>
                        </b-row>
                        <div class="form__group">
                            <div class="input-field multipless">
                                <!-- <div class="centerdivider">
                                    Search Filter:
                                    <b-form-input class="form-list inputfilter" type="text" v-model="filterdataAgent" placeholder="Filter..." />
                                    </div>
                                    <div class="dynamo">
                                    <div class="customChecks" :style="{ height: dynamicHeight }">
                                        <b-form-checkbox id="checkbox-select-all" v-model="selectAll" @change="selectAllCheck">
                                        Select All
                                        </b-form-checkbox>
                                        <b-form-checkbox v-for="(checkbox, index) in filteredAgentList" :key="index" :id="'checkbox-' + index"
                                        :value="checkbox" v-model="selectedagent">
                                        {{ checkbox }}
                                        </b-form-checkbox>
                                    </div>
                                    </div> -->
                                <!-- <div class="d-flex justify-content-start w-50 magnetBase">
                                    <p class="headBase uploadcsv">Upload CSV</p>
                                    <b-form-file v-model="selectedFile" type="file" ref="fileInput" accept=".csv"
                                        placeholder=""></b-form-file>
                                </div>
                                <b-button class="gap5" variant="primary" @click="handleFileUpload">Upload</b-button> -->
                            </div>
                        </div>

                    </b-card>
                </b-collapse>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col lg="12">
                <b-card class="card-distance">
                    <h4>Upload</h4>
                    <div class="form__group">
                        <div class="input-field multipless">
                            <div class="dynamo">
                                <div class="customChecks" :style="{ height: dynamicHeight }" >
                                    <b-form-checkbox
                                        id="checkbox-select-all"
                                        v-model="selectAll"
                                        @change="selectAllCheck"
                                        >
                                        Select All
                                        </b-form-checkbox>
                                    <b-form-checkbox
                                    v-for="(checkbox, index) in agentlist"
                                    :key="index"
                                    :id="'checkbox-' + index"
                                    :value="checkbox"
                                    v-model="selectedagent">
                                    {{ checkbox }}
                                </b-form-checkbox>
                            </div>
                            <b-button @click="changeHeight" class="romingBTN">
                                <b-icon icon="chevron-down" v-if="cheked"></b-icon>
                                <b-icon icon="chevron-up" v-else></b-icon>
                            </b-button>
                        </div>
                        <div class="d-flex justify-content-start w-50 magnetBase">
                            <p class="headBase uploadcsv">Upload CSV</p>
                            <b-form-file v-model="selectedFile" type="file" ref="fileInput" accept=".csv" placeholder="" ></b-form-file>
                        </div>
                        <b-button class="gap5" variant="primary" @click="handleFileUpload">Upload</b-button>
                    </div>
                </div>
                </b-card>
                <b-card>
                    <h4>Download Data</h4>
                    <div>
                        <div class="form__group">
                            <b-form-select v-model="selectedcountry" class="form-list"
                                :options="countrylist"></b-form-select>
                            <input class="form-list" type="text" v-model="selectedcity"
                                placeholder="City..." />
                            <b-button class="button_size_csv" variant="primary"
                                @click="Gethoteldata">SEARCH</b-button>
                            <b-button class="button_size_csv" variant="primary"
                            @click="DownloadCSV(hotellist,`Hotels_List_${selectedcity}_${selectedcountry}.csv`)">DOWNLOAD</b-button>
                        </div>
                    </div>
                    <b-card-body class="verticalMovement">
                        <div class="text-center position-loader" v-if="showloader">
                            <div>
                                <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                                <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                                <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                            </div>
                        </div>
                        <div>
                            <div class="supplier__listbox d-flex align-items-center" v-for="(item, index) in hotellist" :key="index">
                                <div class="mb-0 magnitude">
                                    <p>{{ item.hotel_name }}  ({{ item.supplier_code }})</p>
                                    <p class="d-flex justify-content-start align-items-center">
                                        <span class="d-flex justify-content-between">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 1 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 2 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 3 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 4 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 5 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <span v-if="item.rating==0 || item.rating=='SC'" ><b>SC</b></span>
                                        </span>
                                    </p>
                                    <p><b class="">Latitude: </b>{{ item.latitude }}</p>
                                    <p><b class="">Longitude: </b> {{ item.longitude }}</p>
                                    <p><b class="">Supplier: </b> {{ item.supplier }}</p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row> -->
    </div>
</template>
<script>
import axios from '@axios'
import {
    BRow, BCol, BButton, BFormCheckbox, BIcon,
    BCollapse,
    BCard,
} from "bootstrap-vue"
export default {
    components: {
        BRow,
        BCol, BButton,
        BFormCheckbox, BIcon,
        BCollapse,
        BCard,
    },
    data() {
        return {
            filterdata: '',
            filterdataAgent: '',
            perPage: 10,
            currentPage: 1,
            visible: true,
            invisible: false,
            dynamicHeight: '50px',
            minHeight: '50px',
            maxHeight: '150px',
            isToggled: false,
            dynamicHeight2: '50px',
            minHeight2: '50px',
            maxHeight2: '150px',
            isToggled2: false,
            agentlist: null,
            agentlistID: null,
            countrylist: null,
            selectedcountry: [],
            selectedagent: [],
            supplierlist: null,
            selectedsupplier: [],
            // supplierlist: [], // Your list of suppliers
            // selectedsupplier: [], // Array to store selected suppliers
            selectAll: false,
            selectAll2: false,
            shouldHideButton: false,
            cheked: true,
            cheked2: true,
            columnToRemove: ['checked'],
            selectedFile: null,
            // Files: {
            //     file: null,
            // },
            // selectedFile: null,
            isDragOver: false,
            response: "",
            resName: '',
            hotellist: null,
            showloader: false,
            loaderPendingButton: false,
            loaderSearchButton: false,

        }
    },
    methods: {
        handleFileInputClick() {
            this.$refs.fileInput.$el.querySelector('input[type=file]').click();
        },
        handleDragOver() {
            this.isDragOver = true;
        },
        handleDragLeave() {
            this.isDragOver = false;
        },
        handleDrop(event) {
            this.isDragOver = false;
            this.handleFileChange(event.dataTransfer.files);
        },
        handleFileChange(files) {
            const file = files[0];

            if (file) {
                // Implement file type validation (CSV)
                if (file.type === "text/csv") {
                    this.selectedFile = file;

                    // Update resName only if selectedFile.name is not blank
                    if (this.selectedFile.name.trim() !== "") {
                        this.resName = this.selectedFile.name;
                    }
                } else {
                    console.error("Invalid file type. Please select a CSV file.");
                }
            }
        },

        downloadCSV() {
            // Implement your download logic here
        },
        convertToStars(rating) {
            // Assuming the rating is a number (e.g., 2.0)
            const numberOfStars = Math.round(parseFloat(rating));
            return '⭐'.repeat(numberOfStars);
        },
        downloadFun() {
            this.visible = true;
            this.invisible = false;
        },
        uploadFun() {
            this.visible = false;
            this.invisible = true;
        },
        selectAllCheck() {
            if (this.selectAll) {
                // If "Select All" is checked, select all checkboxes
                this.selectedagent = [...this.agentlist];
            } else {
                // If "Select All" is unchecked, deselect all checkboxes
                this.selectedagent = [];
            }
        },
        selectAllCheck2() {
            if (this.selectAll2) {
                // If "Select All" is checked, select all checkboxes
                this.selectedsupplier = [...this.supplierlist];
            } else {
                // If "Select All" is unchecked, deselect all checkboxes
                this.selectedsupplier = [];
            }
        },
        handleFileUpload() {
            this.loaderPendingButton=true;
            // if (this.selectedFile != null){
            if (this.selectedFile) {
                console.log("Uploading file:", this.selectedFile.name);
                this.resName = this.selectedFile.name;
                // Add your upload logic
            } else {
                console.error("No file selected");
            }
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('agent', JSON.stringify(this.selectedagent));
            axios.post('agentcsv', formData)
                .then(response => {
                    this.response = response.data.message;
                    this.$swal({
                        icon: 'success',
                        title: 'Upload Successful',
                        text: this.response,
                    });
                    // setTimeout(() => {
                    //     this.response='';
                    // }, 2000);
                    
                     setTimeout(() => {
                        this.selectedFile=''
                        this.resName=''
                        this.loaderPendingButton=false;
                    }, 2000);
                    

                })
                .catch(error => {
                    // Handle error here
                    this.$swal({
                        icon: 'error',
                        title: error.response.data.message,
                        text: this.response,
                    });
                    this.loaderPendingButton=false;
                });
        },
        Gethoteldata() {
            this.loaderSearchButton = true
            this.hotellist = null
            this.showloader = true
            axios.post('hotellist', {
                city: this.selectedcity,
                country: this.selectedcountry,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.hotellist = response.data.Hoteldata
                this.showloader = false
                this.loaderSearchButton = false
            }).catch(error => {

            })
        },
        changeHeight() {
            // Toggle between minHeight and maxHeight based on isToggled flag
            this.dynamicHeight = this.isToggled ? this.minHeight : this.maxHeight;
            this.isToggled = !this.isToggled; // Invert the flag for the next click
            this.cheked = !this.cheked;

        },
        changeHeight2() {
            // Toggle between minHeight and maxHeight based on isToggled flag
            this.dynamicHeight2 = this.isToggled2 ? this.minHeight2 : this.maxHeight2;
            this.isToggled2 = !this.isToggled2; // Invert the flag for the next click
            this.cheked2 = !this.cheked2;

        },
        Fetchagentlist() {
            axios.get('agentassigndata', {
            }).then(response => {
                if (response.data.length == 0) {
                    alert("Agent Data Not Uploaded")
                } else {
                    this.agentlist = response.data.agent_name
                    this.agentlistID = response.data.agent_id
                }
            }).catch(error => {

            })
        },
        Fetchsupplierslist() {
            axios.get('supplierslist', {
            }).then(response => {
                this.supplierlist = response.data
            }).catch(error => {

            })
        },
        Fetchcountrylist() {
            axios.get('country_list', {
            }).then(response => {
                this.countrylist = response.data
            }).catch(error => {

            })
        },
        DownloadCSV(data, filename) {
            const headers = Object.keys(data[0]).filter(header => header !== "checked");
            headers.unshift('Action');

            const csvContent = 'data:text/csv;charset=utf-8,' +
                headers.join(',') + '\n' +
                data.map(row => { const newRow = { ...row, "Action": 0 }; return headers.map(header => `"${newRow[header]}"`).join(',') }).join('\n');

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.$swal({
                title: 'Do you want to assign data to agent?',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
                customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                    denyButton: 'order-3',
                },
            }).then(result => {
                if (result.isConfirmed) {
                    this.uploadFun();
                } else if (result.isDenied) {

                }
            })
        },
        changedata() {
            if (this.showData == false && this.showData2 == true) {
                this.showData = true
                this.showData2 = false
            } else if (this.showData == true && this.showData2 == false) {
                this.showData = false
                this.showData2 = true
            }
        },
        addCheckboxColumn(data) {
            data.forEach(row => {
                row['Checkbox'] = 'unchecked';
            });

            return data;
        },
    },
    computed: {
        filteredHotels() {
            const filterText = this.filterdata.toLowerCase().trim();

            if (!filterText) {
                return this.hotellist;
            }

            return this.hotellist.filter(hotel => {
                return Object.values(hotel).some(value => {
                    if (value) {
                        return value.toString().toLowerCase().includes(filterText);
                    }
                    return false;
                });
            });
        },
        paginatedHotels() {
            if (this.filteredHotels && this.filteredHotels.length > 0) {
                const startIndex = (this.currentPage - 1) * this.perPage;
                const endIndex = startIndex + this.perPage;
                return this.filteredHotels.slice(startIndex, endIndex);
            } else {
                return [];
            }
        },

        filteredAgentList() {
            const filter = this.filterdataAgent.toLowerCase();
            if (this.agentlist && this.agentlist.length > 0) {
                return this.agentlist.filter(agent => agent.toLowerCase().includes(filter));
            } else {
                return []; // Return an empty array if agentlist is null or empty
            }
        }
    },
    mounted() {
        this.Fetchagentlist()
        this.Fetchsupplierslist()
        this.Fetchcountrylist()
    },
}
</script>
<style>
.file-drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.file-drop-area.dragover {
    border-color: #007bff;
    background-color: #f0faff;
}

.position-loader {
    margin-top: 200px;
}

.form-list {
    width: 250px !important;
    margin-left: 20px;
}

.card-distance {
    margin-bottom: 10px !important;
}

.magnitude {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: 20px;
}

.magnitude p {
    font-size: 12px !important;
    width: 25%;
    /* text-align: center; */
    margin-bottom: 0px;
}

.size_option {
    height: 50px !important;
}

.font_gap {
    margin-left: 50px;
}

.checkHeight1 {
    height: 10px !important;
}

.cardFixHeight {
    min-height: 70vh;
}

.button_size_csv {
    width: 150px;
    margin-left: 10px;
    height: 45px;
}

.buttonDePerlin {
    width: 100px;
    margin-left: auto;
}

input[type="checkbox"] {
    transform: scale(1.2) !important;
}

.verticalMovement {
    height: 60vh;
    overflow-y: scroll;
}

.multipless {
    align-items: flex-start;
    display: flex;
}

.button_size2 {
    width: 200px;
    height: 50px;
    margin-left: 20px;
}

.button_size3 {
    width: 100px;
    height: 50px;
    margin-left: 20px;
}

.button_size4 {
    width: 200px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.starImg {
    width: 15px !important
}

.test {
    overflow-y: scroll;
    height: 100px;
}

.uploadcsv {
    margin-top: 10px;
    margin-left: 20px;
}
</style>
<script setup>
const userType = localStorage.getItem('userType');
</script>

