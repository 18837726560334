<template>
    <div>

        <b-row class="masterCollapse">
            <b-col lg="12" class="mt-3">

                <div class="d-flex justify-content-start align-items-center">

                    <b-button :class="visible ? 'activeindicatorButtons' : 'collapsed indicatorButtons'"
                        :aria-expanded="visible ? 'true' : 'false'" aria-controls="collapse-1" @click="downloadFun">
                        Check Assigned Agents
                    </b-button>
                </div>
                <b-collapse id="collapse-1" v-model="visible" class="">
                    <b-card class="p-2">
                        <h4 class='headingMain mb-0'>Filter</h4>
                        <div>
                            <div class="input-field multipless">
                                <!-- <div class="alignerPlate">
                                    <div class="centerdivider">
                                    <b-form-input class="form-list inputfilter mb-1" type="text" v-model="filterdataAgent"
                                        placeholder="Agent Search..." />
                                </div>
                                <div class="form__group mainFormFilter">
                                    <div class="dynamo">
                                        <div class="customChecks">
                                            <b-form-checkbox v-for="(checkbox, index) in filteredAgentList" :key="index"
                                                :id="'checkbox-' + index" :value="checkbox" v-model="selectedagent">
                                                {{ checkbox }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                </div> -->
                                <b-form-select v-model="selectedagent" class="form-list" :options="agentlist"></b-form-select>
                                <!-- <b-form-select v-model="selectedagent" class="form-list" value-field="agent_id" text-field="agent_name" :options="agentlist"></b-form-select> -->
                                <b-form-select v-if="selectedagent!=null" v-model="selectedcountry" class="form-list"
                                    :options="countrylist"></b-form-select>
                                <b-form-input v-if="selectedcountry != null" class="form-list" type="text" v-model="selectedcity"
                                    placeholder="City..." />
                                <b-button v-if="selectedcity != null" class="button_size browseBTN" variant="primary"
                                    @click="Gethoteldata">
                                    <b-spinner small v-if="loaderSearchButton" class="mx-1"></b-spinner>
                                    Search</b-button>
                                <b-button v-if="this.insidedata !=null" class="button_size browseBTN" variant="primary" 
                                @click="DownloadCSV(insidedata,selecteddata,`Hotels_Data_${selectedcity}_${selectedcountry}.csv`)">Download</b-button>
                    
                            </div>
                        </div>
                        <div class="text-center position-loader" v-if="showloader">
                                <div>
                                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                                </div>
                            </div>
                        <b-card-body class="ListTable" v-else-if="showData || showData2">
                            <div class="d-flex justify-content-between align-items-center"  >
                                <div class="sideDivider">
                                    <h3>Agent List</h3>
                                </div>
                                <div class="centerdivider">
                                    Search Filter:
                                    <b-form-input class="form-list inputfilter" type="text" v-model="filterdata"
                                        placeholder="Filter..." />
                            <b-button class="button_size browseBTN" variant="primary" @click="resetvariable">Refresh</b-button>
                            <b-button class="button_size browseBTN" variant="primary" @click="savehoteldata">Save</b-button>
                            <b-button class="button_size browseBTN" variant="primary" @click="changedata">Switch</b-button>
                            <b-button class="button_size browseBTN" variant="primary" :to="{name:'verifyplotting', params:{name:this.selectedagent,city:this.selectedcity,country:this.selectedcountry}}">View</b-button>
                                </div>
                            </div>
                            <div v-if="showData">
                                <div class="supplier__listbox_new supplier__listbox_header d-flex align-items-center">
                                    <div class="mb-0 magnitude">
                                        <p>Checks</p>
                                        <p>System_Id</p>
                                        <p class="firstName">Names</p>
                                        <p>Rating</p>
                                        <p>Latitude: </p>
                                        <p>Longitude:</p>
                                        <p>Supplier: </p>
                                    </div>
                                </div>
                                <div class="supplier__listbox_new d-flex align-items-center"
                                    v-for="(item, index) in paginatedHotelsPart1" :key="index">
                                    <div class="mb-0 magnitude">
                                        <p>
                                            <input class="checkHeight1" type="checkbox" v-model="item.checked" :value="item"
                                                :checked="true">
                                        </p>
                                        <p>{{ item.system_id }}</p>
                                        <p class="firstName">{{ item.hotel_name }}</p>
                                        <p class="d-flex justify-content-start align-items-center">
                                            <span class="d-flex justify-content-between">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 1 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 2 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 3 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 4 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 5 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <span v-if="item.rating == 0 || item.rating == 'SC'"><b>SC</b></span>
                                            </span>
                                        </p>
                                        <p>{{ item.latitude }}</p>
                                        <p>{{ item.longitude }}</p>
                                        <p>{{ item.supplier }} ({{ item.supplier_code }})</p>
                                    </div>
                                </div>
                                <b-pagination v-model="currentPage" :total-rows="filteredHotels ? filteredHotels.length : 0"
                                    :per-page="perPage"></b-pagination>
                            </div>



                            <div v-if="showData2">
                                <div class="supplier__listbox_new supplier__listbox_header d-flex align-items-center">
                                    <div class="mb-0 magnitude">
                                        <p>Checks</p>
                                        <p>System_Id</p>
                                        <p class="firstName">Names</p>
                                        <p>Rating</p>
                                        <p>Latitude: </p>
                                        <p>Longitude:</p>
                                        <p>Supplier: </p>
                                    </div>
                                </div>
                                <div class="supplier__listbox_new d-flex align-items-center"
                                    v-for="(item, index) in paginatedHotelsPart2" :key="index">
                                    <div class="mb-0 magnitude">
                                        <p>
                                        <input class="checkHeight1" type="checkbox" v-model="selectedItems" :value="item">
                                        </p>
                                        <p>{{ item.system_id }}</p>
                                        <p class="firstName">{{ item.hotel_name }}</p>
                                        <p class="d-flex justify-content-start align-items-center">
                                            <span class="d-flex justify-content-between">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 1 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 2 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 3 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 4 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <img src="/static/vue/img/star.png"
                                                    :class="item.rating >= 5 ? 'd-block' : 'd-none'" alt="bizlogo"
                                                    class="starImg">
                                                <span v-if="item.rating == 0 || item.rating == 'SC'"><b>SC</b></span>
                                            </span>
                                        </p>
                                        <p>{{ item.latitude }}</p>
                                        <p>{{ item.longitude }}</p>
                                        <p>{{ item.supplier }} ({{ item.supplier_code }})</p>
                                    </div>
                                </div>
                                <b-pagination v-model="currentPage2" :total-rows="filteredHotels2 ? filteredHotels2.length : 0"
                                    :per-page="perPage2"></b-pagination>
                            </div>
                        </b-card-body>

                    </b-card>
                </b-collapse>
                
            </b-col>
        </b-row>


































<!-- 
        <b-row>
            <b-col lg="12">
                <b-card class="card-distance">
                    <h4>Agent</h4>
                    <div class="form__group">
                        <div class="input-field multipless">
                            <b-form-select v-model="selectedagent" class="form-list" :options="agentlist"></b-form-select>
                            
                            <b-form-select v-if="selectedagent!=null" v-model="selectedcountry" class="form-list"
                                :options="countrylist"></b-form-select>
                            <input v-if="selectedcountry != null" class="form-list" type="text" v-model="selectedcity"
                                placeholder="City..." />
                            <b-button v-if="selectedcity != null" class="button_size" variant="primary"
                                @click="Gethoteldata">SEARCH</b-button>
                        </div>
                    </div>
                </b-card>
                <b-card class="card-distance cardFixHeight">
                    <h4 v-if="this.insidedata !=null">Filter</h4>
                    <b-button v-if="this.insidedata !=null" class="button_size4" variant="primary" @click="DownloadCSV(insidedata,selecteddata,`Hotels_Data_${selectedcity}_${selectedcountry}.csv`)">Download Data</b-button>
                    <div class="form__group">
                        <div class="input-field" v-if="this.insidedata !=null">
                            <input class="form-list" type="text" v-model="filterText1" placeholder="Hotel Name..." />
                            <input class="form-list" type="text" v-model="filterText2" placeholder="Supplier Code..." />
                            <input class="form-list" type="text" v-model="filterText3" placeholder="Supplier..." />
                            <b-button class="button_size" variant="primary" @click="resetvariable">Refresh</b-button>
                            <b-button class="button_size" variant="primary" @click="savehoteldata">Save</b-button>
                            <b-button class="button_size d-none" variant="primary" @click="filteredData">Search</b-button>
                            <b-button class="button_size" variant="primary" @click="changedata">Switch</b-button>
                            <b-button class="button_size" variant="primary" :to="{name:'verifyplotting', params:{name:this.selectedagent,city:this.selectedcity,country:this.selectedcountry}}">View</b-button>
                        </div>
                    </div>
                    <div class="text-center position-loader" v-if="showloader">
                        <div>
                            <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                            <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                            <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                        </div>
                    </div>
                    <b-card-body class="verticalMovement"  v-else>
                        <div v-if="showData">
                            <div class="supplier__listbox d-flex align-items-center" v-for="(item, index) in selecteddata" :key="index">
                                <label>
                                    <input class="checkHeight1" type="checkbox" v-model="item.checked" :value="item"
                                        :checked="true">
                                </label>
                                <div class="mb-0 magnitude">
                                    <p>{{ item.hotel_name }}  ({{ item.supplier_code }})</p>
                                    <p class="d-flex justify-content-start align-items-center">
                                        <span class="d-flex justify-content-between">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 1 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 2 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 3 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 4 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 5 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <span v-if="item.rating==0 || item.rating=='SC'" ><b>SC</b></span>
                                        </span>
                                    </p>
                                    <p><b class="">Latitude: </b>{{ item.latitude }}</p>
                                    <p><b class="">Longitude: </b> {{ item.longitude }}</p>
                                    <p><b class="">Supplier: </b> {{ item.supplier }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="showData2">
                            <div class="supplier__listbox d-flex align-items-center" v-for="(item, index) in insidedata " :key="index">
                            <label>
                                <input class="checkHeight1" type="checkbox" v-model="selectedItems" :value="item">
                            </label>
                            <div class="mb-0 magnitude">
                                <p>{{ item.hotel_name }}  ({{ item.supplier_code }})</p>
                                    <p class="d-flex justify-content-start align-items-center">
                                        <span class="d-flex justify-content-between">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 1 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 2 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 3 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 4 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <img src="/static/vue/img/star.png" :class="item.rating >= 5 ? 'd-block' : 'd-none' " alt="bizlogo" class="starImg">
                                            <span v-if="item.rating==0 || item.rating=='SC'" ><b>SC</b></span>
                                        </span>
                                    </p>
                                    <p><b class="">Latitude: </b>{{ item.latitude }}</p>
                                    <p><b class="">Longitude: </b> {{ item.longitude }}</p>
                                    <p><b class="">Supplier: </b> {{ item.supplier }}</p>
                            </div>
                        </div>
 
                        </div>
                    </b-card-body>
 
                   
                </b-card>
            </b-col>
        </b-row> -->
    </div>
</template>
<script>
import axios from '@axios'
import { BRow, BCol, BButton, BFormSelect,BFormCheckbox,BIcon } from "bootstrap-vue"
export default {
    components: {
        BRow,
        BCol, BButton,
        BFormSelect
        // ,BIcon
        // BFormCheckbox
    },
    data() {
        return {
            visible:true,
            dynamicHeight: '50px', // Initial height
            minHeight: '50px', // Define the minimum height
            maxHeight: '150px', // Define the maximum height
            isToggled: false, // Flag to track the current height state
            selectedagent: null,
            agentlist: null,
            selectedsupplier: [],
            // supplierlist: [], // Your list of suppliers
            // selectedsupplier: [], // Array to store selected suppliers
            selectAll: false,
            supplierlist: null,
            selectedcity: null,
            countrylist: null,
            selectedcountry: null,
            hotellist: null,
            insidedata:null,
            selecteddata: null,
            selectedItems: [],
            uncheckeddata: [],
            savedataresponse: null,
            filterText1: '',
            filterText2: '',
            filterText3: '',
            showloader:false,
            filteredinfo:null,
            finaldata:null,
            shouldHideButton:false,
            showData:false,
            showData2:false,
            cheked:true,
            columnToRemove:['checked'],
            filterdata: '',
            filterdataAgent: '',
            perPage: 10,
            currentPage: 1,
            perPage2: 10,
            currentPage2: 1,
            loaderSearchButton:false,
        }
    },
    methods: {
        selectAllCheck() {
      if (this.selectAll) {
        // If "Select All" is checked, select all checkboxes
        this.selectedsupplier = [...this.supplierlist];
      } else {
        // If "Select All" is unchecked, deselect all checkboxes
        this.selectedsupplier = [];
      }
    },
    changeHeight() {
      // Toggle between minHeight and maxHeight based on isToggled flag
      this.dynamicHeight = this.isToggled ? this.minHeight : this.maxHeight;
      this.isToggled = !this.isToggled; // Invert the flag for the next click
      this.cheked = !this.cheked;
   
    },
        Fetchagentlist() {
            axios.get('agentassigndata', {
            }).then(response => {
                if (response.data.length==0){
                    alert("Agent Data Not Uploaded")
                }else{
                    console.log(response.data.agent_id)
                    this.agentlist = response.data.agent
                    console.log(this.agentlist)
                }
            }).catch(error => {
 
            })
        },
        Fetchsupplierslist() {
            axios.get('supplierslist', {
            }).then(response => {
                this.supplierlist = response.data
            }).catch(error => {
 
            })
        },
        Fetchcountrylist() {
            axios.get('country_list', {
            }).then(response => {
                this.countrylist = response.data
                console.log(this.selectedcountry, "this.selectedcountry")
            }).catch(error => {
 
            })
        },
        Gethoteldata() {
            this.loaderSearchButton = true
            this.showloader=true
            this.insidedata=null
            this.selecteddata=null
            this.selectedItems=[]
            axios.post('viewhotellist', {
                agentname: this.selectedagent,
                city: this.selectedcity,
                country: this.selectedcountry,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.insidedata = response.data.map_inout.inside
                this.finaldata = response.data.map_inout.inside
                this.selecteddata = response.data.map_inout.selected
                this.showloader=false;
                this.showData2 = true;
                this.loaderSearchButton = false;
            }).catch(error => {
                alert("Multiple Osm Co-ordinates or Data Not Available")
                this.showloader=false
            })
        },
        savehoteldata() {
            if (this.insidedata!=null && this.selecteddata==null && this.selectedItems.length==0){
                alert("Unable To Save As Data Not Selected")
            }else if (this.insidedata!=null && this.selecteddata==null){
                    axios.post('savehoteldata', {
                    agentname: this.selectedagent,
                    // supplier: this.selectedsupplier,
                    city: this.selectedcity,
                    country: this.selectedcountry,
                    hotellist: this.selectedItems,
                }, {

                }).then(response => {
                    this.savedataresponse = response.data
                    this.Gethoteldata()
                }).catch({

                })
            }else if (this.insidedata!=null && this.selecteddata !=null){
                this.uncheckeddata = this.selecteddata.filter(item => !item.checked);
                if(this.selectedItems.length == 0 && this.uncheckeddata.length != 0) {
                    axios.post('savehoteldata', {
                        agentname: this.selectedagent,
                        // supplier: this.selectedsupplier,
                        city: this.selectedcity,
                        country: this.selectedcountry,
                        hotellist: this.selectedItems,
                        unselectedlist: this.uncheckeddata,
                        savedlist: this.selecteddata,
                    }, {
    
                    }).then(response => {
                        this.savedataresponse = response.data
                        this.Gethoteldata()
                    }).catch({
    
                    })
                }else if(this.selectedItems.length != 0 && this.uncheckeddata.length != 0){
                    axios.post('savehoteldata', {
                        agentname: this.selectedagent,
                        // supplier: this.selectedsupplier,
                        city: this.selectedcity,
                        country: this.selectedcountry,
                        hotellist: this.selectedItems,
                        unselectedlist: this.uncheckeddata,
                        savedlist: this.selecteddata,
                    }, {
    
                    }).then(response => {
                        this.savedataresponse = response.data
                        this.Gethoteldata()
                    }).catch({
    
                    })
                }else if(this.selectedItems.length != 0 && this.uncheckeddata.length == 0){
                    axios.post('savehoteldata', {
                        agentname: this.selectedagent,
                        // supplier: this.selectedsupplier,
                        city: this.selectedcity,
                        country: this.selectedcountry,
                        hotellist: this.selectedItems,
                        unselectedlist: this.uncheckeddata,
                        savedlist: this.selecteddata,
                    }, {
    
                    }).then(response => {
                        this.savedataresponse = response.data
                        this.Gethoteldata()
                    }).catch({
    
                    })
                }else if(this.selectedItems.length == 0 &&  this.uncheckeddata.length == 0){
                    alert("Unable To Save As Data Not Selected")
                }
            }else if (this.insidedata==null){
                alert("Data Not Available")
            }       
        },
        resetvariable(){
            this.filterText1=null
            this.filterText2=null
            this.filterText3=null
        },
        changedata(){
            if (this.showData==false && this.showData2 ==true){
                this.showData=true
                this.showData2=false
            }else if (this.showData==true && this.showData2 ==false){
                this.showData=false
                this.showData2=true
            }
        },
        addCheckboxColumn(data) {
            data.forEach(row => {
                row['Checkbox'] = 'unchecked';
            });

            return data;
        },
        DownloadCSV(data,data1,filename){
            if (data1==null){
                console.log("it is null")
                const headers = Object.keys(data[0]).filter(header => header !== "checked");
                headers.unshift('Action');

                const csvContent =  'data:text/csv;charset=utf-8,' + 
                headers.join(',') + '\n' +
                data.map(row => {const newRow = { ...row, "Action": 0 }; return headers.map(header => `"${newRow[header]}"`).join(',')}).join('\n');

                const encodedUri = encodeURI(csvContent);
                const link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                
            }else{
                console.log("it is not null")
                const headers = Object.keys(data[0]).filter(header => header !== "checked");
                headers.unshift('Action');
                
                const csvContent =  
                // headers.join(',') + '\n' +
                    data.map(row => {const newRow = { ...row, "Action": 0 }; return headers.map(header => `"${newRow[header]}"`).join(',')}).join('\n');

                const headers1 = Object.keys(data1[0]).filter(header => header !== "checked");
                headers1.unshift('Action');
                
                const csvContent1 =  'data:text/csv;charset=utf-8,' + 
                    headers1.join(',') + '\n' +
                    data1.map(row => {const newRow = { ...row, "Action": 1 }; return headers1.map(header => `"${newRow[header]}"`).join(',')}).join('\n');
                // const csvContent2
                
                const combinedCSVContent = csvContent1 + '\n' + csvContent;


                const encodedUri = encodeURI(combinedCSVContent);
                const link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

            }
        },
    },
    mounted() {
        this.Fetchagentlist()
        this.Fetchsupplierslist()
        this.Fetchcountrylist()
    },
    computed:{
        filteredAgentList() {
            const filter = this.filterdataAgent.toLowerCase();
            if (this.agentlist && this.agentlist.length > 0) {
                return this.agentlist.filter(agent => agent.toLowerCase().includes(filter));
            } else {
                return []; // Return an empty array if agentlist is null or empty
            }
        },
        filteredData(){
        this.insidedata=null
        this.insidedata=this.finaldata
        if (this.filterText1 && this.filterText2 && this.filterText3) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterText1.toLowerCase()) && value.supplier_code.includes(this.filterText2) && value.supplier.toLowerCase().includes(this.filterText3.toLowerCase()))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if(this.filterText1 && this.filterText2) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterText1.toLowerCase()) && value.supplier_code.includes(this.filterText2))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if(this.filterText2 && this.filterText3) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) => value.supplier_code.toLowerCase().includes(this.filterText2) && value.supplier.toLowerCase().includes(this.filterText3.toLowerCase()))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if(this.filterText1 && this.filterText3) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterText1.toLowerCase()) && value.supplier.toLowerCase().includes(this.filterText3.toLowerCase()))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if (this.filterText1) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterText1.toLowerCase()))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if (this.filterText2) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) =>  value.supplier_code.includes(this.filterText2))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else if (this.filterText3) {
            const datafiltered = Object.fromEntries(
                Object.entries(this.insidedata)
                .filter(([key,value]) =>  value.supplier.toLowerCase().includes(this.filterText3.toLowerCase()))
            );
            this.insidedata=datafiltered
            return this.insidedata
        }else {
            this.insidedata=this.finaldata
            return this.insidedata;
        }
 
    },

    filteredHotels() {
            const filterText = this.filterdata.toLowerCase().trim();

            if (!filterText) {
                return this.selecteddata;
            }

            return this.selecteddata.filter(hotel => {
                return Object.values(hotel).some(value => {
                    if (value) {
                        return value.toString().toLowerCase().includes(filterText);
                    }
                    return false;
                });
            });
        },
        paginatedHotelsPart1() {
            if (this.filteredHotels && this.filteredHotels.length > 0) {
                const startIndex = (this.currentPage - 1) * this.perPage;
                const endIndex = startIndex + this.perPage;
                return this.filteredHotels.slice(startIndex, endIndex);
            } else {
                return [];
            }
        },

        filteredHotels2() {
            const filterText = this.filterdata.toLowerCase().trim();

            if (!filterText) {
                return this.insidedata;
            }

            return this.insidedata.filter(hotel => {
                return Object.values(hotel).some(value => {
                    if (value) {
                        return value.toString().toLowerCase().includes(filterText);
                    }
                    return false;
                });
            });
        },
        paginatedHotelsPart2() {
            if (this.filteredHotels2 && this.filteredHotels2.length > 0) {
                const startIndex = (this.currentPage2 - 1) * this.perPage2;
                const endIndex = startIndex + this.perPage2;
                return this.filteredHotels2.slice(startIndex, endIndex);
            } else {
                return [];
            }
        },
},
}
</script>
<style>
.position-loader{
    margin-top:200px;
}
.form-list {
    width: 250px !important;
    margin-left: 20px;
}
 
.card-distance {
    margin-bottom: 10px !important;
}
.magnitude {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: 20px;
}
.magnitude p{
    font-size: 12px !important;
    width:25%;
    /* text-align: center; */
    margin-bottom: 0px;
}
 
.size_option {
    height: 50px !important;
}
 
.font_gap {
    margin-left: 50px;
}
 
.checkHeight1 {
    height: 10px !important;
}
.cardFixHeight{
    min-height: 70vh;
}
.button_size {
    width: 110px;
    margin-left: 10px;
    height:45px;
}
.buttonDePerlin{
    width: 100px;
    margin-left: auto;
}
 
input[type="checkbox"] {
    transform: scale(1.2) !important;
}
.verticalMovement{
    height: 60vh;
    overflow-y: scroll;
}
.multipless{
    align-items: flex-start;
    display: flex;
}
.button_size2{
    width: 200px;
    height: 50px;
    margin-left: 20px;
}
.button_size3{
    width: 100px;
    height: 50px;
    margin-left: 20px;
}
.button_size4{
    width:200px;
    height: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
}
.starImg{
    width:15px !important
}
.test{
    overflow-y:scroll;
    height: 100px;
}
</style>
<script setup>
const userType = localStorage.getItem('userType');
</script>
 
