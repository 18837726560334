<template>
    <div>
        <!-- <b-row>
            <b-col md="12">
                <b-card>
                    <b-row>
                        <b-col md="9" class='mt-3 w-100'>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex justify-content-start w-50 px-2">
                                    <p class="headBase">Upload CSV</p>
                                    <b-form-file v-model="selectedFile" type="file" ref="fileInput" accept=".csv" placeholder=""></b-form-file>
                                </div>
                                <div class="d-flex justify-content-start w-50 px-2">
                                    <b-button class="gap5" variant="primary" @click="handleFileUpload">Upload</b-button>
                                    <b-button class="format" variant="primary" @click="downloadCSV">Format&nbsp;<b-icon icon="file-earmark-arrow-down"></b-icon></b-button>
                                   
                                    <b-button class="button-design" variant="primary" :to="{name:'updateagentdata'}"><b-icon icon="person-fill"></b-icon><span class="design">Assign</span></b-button>
                                    <b-button class="button-design" variant="primary" :to="{name:'agentassign'}"><span class="design">Update</span></b-button>
                                     </div>
                            </div>
                            <div>
                                <b-card class="bg-blue-1 mb-2">
                                    <h5>{{ this.response }}</h5>
                                </b-card>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row> -->

        <!-- <b-row>
            <b-col md="12"> -->
                <b-row>
                    <b-col md="12" class='mt-3 w-100'>
                            <b-card class="mainUploader">
                            <div class="">
                                <div >
                                    <b-row>
                                        <b-col md="12">
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <h5 class='headingMain mb-0'>Agent Settings</h5>
                                        <div class="d-flex justify-content-end w-50 px-2">
                                            <b-button class="browseBTN magl" variant="primary"
                                                :to="{ name: 'updateagentdata' }"><b-icon icon="person-fill"></b-icon><span
                                                    class="design">Assign</span></b-button>
                                            <b-button class="browseBTN magl" variant="primary"
                                                :to="{ name: 'agentassign' }"><b-icon icon="cloud-arrow-up"></b-icon><span class="design">Update</span></b-button>
                                        </div>
                                    </div>
                                    </b-col>
                                    </b-row>


                                    <b-row>
                                        <b-col md="12">

                                            <div class="file-drop-area mt-3" @dragover.prevent="handleDragOver"
                                                @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop"
                                                :class="{ 'dragover': isDragOver }">
                                                <b-icon icon="upload" class="iconforUpload"></b-icon>
                                                <p>Drag &amp; drop your files here or <span
                                                        @click="handleFileInputClick" class="browseBTN">browse</span></p>
                                                <b v-if="resName != ''">Selected File: {{ resName }}</b>
                                                <b-form-file v-model="selectedFile" type="file" ref="fileInput"
                                                    accept=".csv" placeholder="" class="d-none"></b-form-file>
                                            </div>

                                            <b-button class=" mt-3 w-100" variant="primary"
                                                @click="handleFileUpload">
                                                <b-spinner small v-if="loaderPendingButton" class="mx-1"></b-spinner>
                                                Upload</b-button>
                                            <p class="format" @click="downloadCSV">Click here to download Sample
                                                Format&nbsp;</p>

                                        </b-col>

                                    </b-row>
                                </div>
                            </div>
                            <!-- <div>
                                <b-card class="bg-blue-1 mb-2">
                                    <h5>{{ this.response }}</h5>
                                </b-card>
                            </div> -->
                        </b-card>
                        </b-col>
                    </b-row>
            <!-- </b-col>
        </b-row> -->


    </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script>
import axios from '@axios'
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormFile
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormFile
    },
    data() {
        return {
            loaderPendingButton:false,    
      selectedFile: null,
      isDragOver: false,
      response: "",
            userType: localStorage.getItem('userType'),
            // selectedFile:null,
            resName:'',
            Files: {
                file: null,
            },
            response:'',
            csvdata:[
            {"id":1,"agency_name":"Travel associate","first_name":"hemangi","middle_name":null,"last_name":"sawant","agent_type":"CS","address":"qtech","city":71649.0,"pincode":123456.0,"time_zone_offset":4.0},
            {"id":2,"agency_name":"Travel associate","first_name":"hemangi","middle_name":null,"last_name":"sawant","agent_type":"CC","address":"qtechsoftware","city":64440.0,"pincode":600063.0,"time_zone_offset":4.0},
            {"id":3,"agency_name":"qtech","first_name":"hiral","middle_name":null,"last_name":"patel","agent_type":"CC","address":"goregaon","city":71649.0,"pincode":600063.0,"time_zone_offset":4.0},
            {"id":4,"agency_name":"qtech","first_name":"khyati","middle_name":null,"last_name":"doshi","agent_type":"CC","address":"121 Phoenix mills Lower Parel Mumbai-68","city":64440.0,"pincode":400068.0,"time_zone_offset":4.0},
            {"id":5,"agency_name":"yasisland","first_name":"yas","middle_name":null,"last_name":"island","agent_type":"CC","address":"yasisland","city":71650.0,"pincode":45.0,"time_zone_offset":4.0},
            {"id":6,"agency_name":"agentyas","first_name":"agentyas","middle_name":"agentyas","last_name":"agentyas","agent_type":"CC","address":"abu dhabi","city":71650.0,"pincode":324.0,"time_zone_offset":4.0},
            {"id":7,"agency_name":"NIRVANA TRAVEL","first_name":"hoda","middle_name":null,"last_name":"kiwan","agent_type":"CC","address":"ABU DHABI","city":71650.0,"pincode":41818.0,"time_zone_offset":4.0},
            {"id":8,"agency_name":"NIRVANA TRAVEL","first_name":"ranjith","middle_name":null,"last_name":"ranjith","agent_type":"CC","address":"abu dhabi","city":71650.0,"pincode":41818.0,"time_zone_offset":4.0}
            ],
        }
    },
    methods: {
        
    handleFileInputClick() {
        this.$refs.fileInput.$el.querySelector('input[type=file]').click();
    },
        handleDragOver() {
      this.isDragOver = true;
    },
    handleDragLeave() {
      this.isDragOver = false;
    },
    handleDrop(event) {
      this.isDragOver = false;
      this.handleFileChange(event.dataTransfer.files);
    },
    handleFileChange(files) {
      const file = files[0];

      if (file) {
        // Implement file type validation (CSV)
        if (file.type === "text/csv") {
          this.selectedFile = file;
          
          // Update resName only if selectedFile.name is not blank
          if (this.selectedFile.name.trim() !== "") {
            this.resName = this.selectedFile.name;
          }
        } else {
          console.error("Invalid file type. Please select a CSV file.");
        }
      }
    },
    handleFileUpload() {
      // Implement your file upload logic here
      if (this.selectedFile) {
        console.log("Uploading file:", this.selectedFile.name);
        this.resName = this.selectedFile.name;
        // Add your upload logic
      } else {
        console.error("No file selected");
      }
    },
    downloadCSV() {
      // Implement your download logic here
    },

        handleFileUpload() {
            this.loaderPendingButton = true;
            this.Files.file = this.selectedFile;
            const formData = new FormData();
            formData.append('file', this.Files.file);
            if (this.selectedFile != null){
                axios.post('uploadfile', formData)
                .then(response => {
                    this.response=response.data.message;

                    this.$swal({
                        icon: 'success',
                        title: 'Upload Successful',
                        text: this.response,
                    });
                    // setTimeout(() => {
                    //     this.response='';
                    // }, 2000);

                    setTimeout(() => {
                        // this.response='';
                        this.selectedFile='';
                        this.resName='';
                        this.loaderPendingButton = false;
                    }, 1000);
                }).catch(error=>{
                })
            }else{
                this.response='File not Selected'
                this.$swal({
                    icon: 'error',
                    title: 'Upload Unsuccessful',
                    text: this.response,
                });
                this.$swal(this.response);
                setTimeout(() => {
                        this.response='';
                        
                    this.resName=''
                    }, 2000);
                }
        },
        convertObjectToCSV(csvdata) {
            const columns = Object.keys(csvdata[0]);
            const header = columns.join(',');
            // const rows = csvdata.map(obj =>
            //     columns.map(col => obj[col]).join(',')
            // );
            return [header].join('\n');
        },
        downloadCSV() {
            // const csvContent = this.convertObjectToCSV(this.csvdata);
            const csvContent = 'id,agency_name,first_name,middle_name,last_name,agent_type,address,city,pincode,time_zone_offset'
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const downloadLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.setAttribute('download', 'Agent_data.csv');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
    },
    mounted() {

    }
}

</script>
<style>
.bg-blue-1 {
    margin-top: 10px;
    background-color: #e5f1ff !important;
    height: 40px !important;
    width: 1420px;
}

.design {
    padding-left: 10px;
}

.button-design {
    width: 150px;
    height: 50px;
    margin-left: auto;
}

.format {
    width: 150px;
    height: 50px;
    margin-left: 20px;
}



.file-drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.file-drop-area.dragover {
    border-color: #007bff;
    background-color: #f0faff;
}
</style>


<!-- // axios.post('uploadfile', formData)
    // .then(response => {
    //     console.log("INSIDE AXIOS",response.data)
    //     if (response.data == null){
    //         this.response='File not Selected'
    //     }else{
    //         console.log(response.data)
    //         this.response=response.data.message;
    //         setTimeout(() => {
    //             this.response='';
    //         }, 2000);
    //     }
    //     // this.selectedFile=null
    // }).catch(error=>{
    //     // this.response='File not Selected'
    // }) -->