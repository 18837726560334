<template>
    <div>
      <!-- <input class="gap_admin" type="number" v-model="filterText1" min="1" placeholder="User_id..." />
      <input class="gap_admin" type="text" v-model="filterText2" placeholder="Locations..." /> -->
        <table>
            <thead>
                <tr>
                <th>UserId</th>
                <th>Credit</th>
                <th>Count</th>
                <th>Date</th>
                <th>Expiry Date</th>
                <th>Days Remaining</th>
                <th>Status</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="column_width">{{ item.user_id}}</td>
                    <td class="column_width">{{ item.credit}}</td>
                    <td class="column_width">{{ item.count }}</td>
                    <td class="column_width">{{ item.start_date }}</td>
                    <td class="column_width">{{ item.expiry_date }}</td>
                    <td v-if="item.date_remaining =='null'" class="column_width">{{ item.date_remaining }} </td>
                    <td v-if="item.date_remaining !='null'" class="column_width">{{ item.date_remaining }} days to go ..</td>
                    <td class="column_width">{{ item.status }}</td>
                    <td>
                        <button @click="edit_plan(item,index)" class="me-1 btn btn-sm btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Edit Plan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <button @click="add_credits(item.user_id)" class="me-1 btn btn-sm btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Credits&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <b-modal v-model="modalstatus" ok-title="Save" cancel-title="Cancel" id="modal_chkbxgrp" title="Edit Plan" @ok="handleOk" @cancel="handleCancel" @close="handleModalClose">
            <b-form-group>
                <div class="d-flex justify-content-between align-items-center">
                    <label for="input-1">User Credit </label>
                    <b>Credit Value  :&nbsp;&nbsp;&nbsp;{{ this.field1backup }}</b>
                </div>
                <b-form-input
                    id="input-1"
                    v-model="field1"
                    placeholder="User Credit"
                ></b-form-input>
            </b-form-group>
            <br>
            <b-form-group>
                <div class="d-flex justify-content-between align-items-center">
                    <label for="datepicker-1">Expiry Date </label>
                    <b>Current Expiry Date  :&nbsp;&nbsp;&nbsp;{{ this.selectedDatebackup }}</b>
                </div>
                <input type="date" class="w-100" v-model="selectedDate" @input="formatDate" />
                <!-- <b-form-datepicker
                id="datepicker-1"
                v-model="selectedDate"
                placeholder="Choose a date"
                @input="formatDate"
                /> -->
                <!-- <b-form-datepicker
                    id="datepicker-1"
                    v-model="selectedDate"
                    placeholder="Choose a date"
                ></b-form-datepicker> -->
                <!-- <b-form-datepicker
                    id="datepicker-1"
                    v-model="selectedDate"
                    placeholder="Choose a date"
                ></b-form-datepicker> -->
            </b-form-group>
        </b-modal>
    </div>
</template>
<script>
import axios from '@axios'
// import { BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'

export default {
    data() {
        return{
            // showDropdown: false,
            // selectedCheckboxes:true,
            // unselectedCheckboxes:[],
            tableData:[],
            updatecredit:'',
            modalstatus:false,
            field1:'',
            selectedDate:'',
            field1backup:'',
            selectedDatebackup:'',
            userdata:'',

        }
    },
    // components: {
    // BIconArrowUp,
    // BIconArrowDown
    // },
    mounted(){
        this.clientlist()
    },
      
    methods: {
        clientlist: function(){
            this.tableData=[]
            axios.post('creditdetails',{userid:this.$route.params.id},{
            }).then(response=>{
                this.tableData = response.data.logs
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        },
        handleOk(){
            axios.post('updatecredits',{userdata:this.userdata,date:this.selectedDate,credit:this.field1},{
            }).then(response=>{
                this.updatecredit = response.data.message
                if (this.updatecredit=='Plan is Active'){
                    this.$swal({
                        icon: 'error',
                        title: 'Plan is Active',
                        text:'',
                    });
                }else{
                    this.$swal({
                        icon: 'success',
                        title: 'Plan Updated Successfully',
                        text:'',
                    });
                }
                this.clientlist()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        },
        handleCancel(){
            window.location.reload();
        },
        handleModalClose(){
            window.location.reload();
        },
        edit_plan(item,index){
            this.userdata=item
            this.field1=item.credit
            this.selectedDate=item.expiry_date
            this.field1backup=item.credit,
            this.selectedDatebackup=item.expiry_date,
            this.modalstatus=true;
        },
        add_credits(user){
            axios.post('addcredits',{userid:user},{
            }).then(response=>{
                this.updatecredit = response.data.message
                if (this.updatecredit=='Plan is Active'){
                    this.$swal({
                        icon: 'error',
                        title: 'Plan is Active',
                        text:'',
                    });
                }else{
                    this.$swal({
                        icon: 'success',
                        title: 'Plan Updated Successfully',
                        text:'',
                    });
                }
                this.clientlist()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        }
    },
}


</script>
<style>
.column_width{
    width: 150px;
}
.link{
  color:blue;
  font-weight: bold;
}
.gap_admin{
  margin-right: 5px;
  margin-left: 20px;
  border-color:black;
  width: 250px;
}
.td:hover{
    border-bottom: thin solid #ddd;
    background-color: #6bafa0;
    color: #fff !important;
}
.width{
    width:500px !important;
}
.basic_cursor{
    cursor: pointer;
}
</style>
