<template>
    <div>
        <div class="position-relative">
            <button class="remoteButtons" @click="previouspage">Previous Page</button>
        </div>
      <gmap-map
        :center="center"
        :options="mapStyle"
        :zoom="11" class="map_full">
        <gmap-polygon :paths="paths"></gmap-polygon>
      </gmap-map>
   
    </div>
  </template>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/gmap-vue@1.2.2/dist/gmap-vue.min.js"></script>
  <script>
  import axios from '@axios'
  export default {
    data() {
      return {
        latitude:null,
        longitude:null,
        center: { 
            lat: this.latitude, 
            lng: this.longitude,
        },      
        locations: [],
        setpolygonline:null,
        paths: [],
        Editable:false,
        mapStyle: {
          fullscreenControl: false, 
          mapTypeControl: false,
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
    }
      }
    },
    mounted() {
        this.setLocationLatLng()
    },
   
    methods: {
      setLocationLatLng: function() {
        axios.post('showcustompolygon',{
                location:this.$route.params.location,
                city: this.$route.params.city,
                country: this.$route.params.country,
                state:this.$route.params.state,
                // time:this.$route.params.time,
                datetime:this.$route.params.datetime,
                info:this.$route.params.info,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.center.lat=response.data.Coordinates.latitude
                this.center.lng=response.data.Coordinates.longitude
                this.setpolygonline=response.data.Coordinates.coordinates
                this.paths = this.setpolygonline  
            }).catch(error => {
                console.log("Something went wrong in setpolygon()")
            })
        },
        previouspage() {
          console.log("working properly");
          if (this.$route.params.info !== 'Default') {
            this.$router.go(-1);
          } else {
            this.$router.push({ name: 'viewcustompolygon' });
          }
        },
    } 
  }
  </script>
<style>
.back{
    position: absolute !important;
    top: 20px;
    left:25px;
}
</style>
  