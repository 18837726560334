<template>
  <div>
      <b-row>
          <b-col md="12">
              <b-card >
                      <h2 class='pageHeading'>USER DETAILS</h2>
                      <b-row>
                          <b-col md="9" class='mt-3'>
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex justify-content-start w-50 px-2">
                                      <p class="headBase">User Name:</p>
                                      <p class="subHeadBase">{{ userData.userview }}</p>
                                  </div>
                                  <div class="d-flex justify-content-start w-50 px-2">
                                      <p class="headBase">Email:</p>
                                      <p class="subHeadBase" >{{ userData.email }}</p>
                                  </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex justify-content-start w-50 px-2">
                                  <p class="headBase">Daily Request:</p>
                                      <p class="subHeadBase">{{ userData.daily_request }}</p>
                                  </div>
                                  <div class="d-flex justify-content-start w-50 px-2">
                                      <p class="headBase">Joining Date:</p>
                                      <p class="subHeadBase">{{ userData.joining_date }}</p>
                                  </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                  <div class="d-flex justify-content-start w-50 px-2">
                                  <p class="headBase">Last Request Time:</p>
                                      <p class="subHeadBase">{{ userData.last_hit }}</p>
                                  </div>
                              </div>
                          </b-col>
                      </b-row>
                      <!-- <b-row>
                          <b-col md="6" class='mt-3'>
                              <b-card class="bg-blue2 mb-2">
                                  <p class="headDetails2" v-if="prevTokenCheck" ref="tokendb">{{ userData.token }}</p>
                                  <p class="headDetails2" v-else>Token Copied...</p>
                              </b-card>
                          </b-col>
                          <b-col md="6" class='mt-3'>
                            <b-button class="gap5" variant="primary" @click="copyToCurrentClipboard">Copy Token</b-button>
                          </b-col>
                      </b-row> -->
                      <b-row>
                          <b-col md="6" class='mt-3'>
                            <b-card class="bg-blue2 mb-2">
                                <p class="headDetails2" v-if="this.clienttoken=='' && ClientTokenCheck" ref="generatedtoken">{{ userData.token }}</p>
                                <p class="headDetails2" v-else-if="ClientTokenCheck" ref="generatedtoken">{{ this.clienttoken }}</p>
                                <p class="headDetails2" v-else>Token Copied...</p>
                            </b-card>
                          </b-col>
                          <b-col md="6" class='mt-3'>
                            <b-button class="gap5" variant="primary" @click="copyToClipboard">Copy Token</b-button>
                            <b-button class="gap5" variant="primary" @click="showModal">Generate Token</b-button>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col md="12">
                              <b-button class="gap6 previous" variant="primary" :to="{name: 'setting'}">Previous Page</b-button>
                          </b-col>
                      </b-row>
                      <b-modal ref="my-modal" hide-footer title="Generate New Token">
                        <div class="d-block text-center">
                            <h6 class="text-left">Are you sure, you want to generate new TOKEN KEY?</h6>
                        </div>
                        <div class="d-flex justify-content-end">
                            <b-button class="mt-3 mx-2" variant="outline-danger" block @click="hideModal">Close</b-button>
                            <b-button class="mt-3 mx-2" variant="outline-primary" block @click="generateToken">Generate Token</b-button>
                        </div>
                    </b-modal>
              </b-card>
          </b-col>
      </b-row>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.8/clipboard.min.js"></script>
<script>
import axios from '@axios'
import {
    BRow,
    BCol,
    BCard,
    BButton
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton
    },
    data() {
      return {
        userData : {},
        // prevTokenCheck: true,
        clienttoken:'',
        ClientTokenCheck:true,

      }
    },
    methods: {
      // copyToCurrentClipboard() {
      //   this.prevTokenCheck =false;
      //   const paragraphElement = this.$refs.tokendb;
      //   const range = document.createRange();
      //   range.selectNode(paragraphElement);
      //   const selection = window.getSelection();
      //   selection.removeAllRanges();
      //   selection.addRange(range);
      //   document.execCommand('copy');
      //   selection.removeAllRanges();
      //   setTimeout(() => {
      //   this.prevTokenCheck =true;
      //                   }, 2000);
      //   },
      showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      toggleModal() {
        this.$refs['my-modal'].toggle('#toggle-btn')
      },
        copyToClipboard() {
        this.ClientTokenCheck=false;
        const paragraphElement = this.$refs.generatedtoken;
        const range = document.createRange();
        range.selectNode(paragraphElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        setTimeout(() => {
        this.ClientTokenCheck =true;
                        }, 2000);
        },
        generateToken:function(){
            axios.post('clienttoken',{username:this.$route.params.name},{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response=>{
              this.clienttoken=response.data.token
                // if(this.baseToken != ''){
                //     this.prevToken= this.baseToken
                // }else{
                //     this.prevToken= localStorage.getItem('token')
                // }
                // localStorage.setItem('token', response.data.token)
                // this.baseToken = localStorage.getItem('token')
            }).catch(error=>{
                alert("some error")
            })
            this.toggleModal();
        },
    },
    async mounted(){
      // console.log(this.$route.params.user);
            try{
                const userRequests = [
                    axios.post('userinfo', { username: this.$route.params.user }),
                    axios.post('userinfo1', { username: this.$route.params.user })
                    // Add more POST requests as needed
                ];
                const userResponses = await Promise.all(userRequests);
                this.userData = {
                    email: userResponses[1].data.Email,
                    token: userResponses[1].data.Token,
                    userview: userResponses[0].data.User,
                    daily_request: userResponses[0].data.daily_request,
                    joining_date: userResponses[1].data.joining_date,
                    last_hit: userResponses[1].data.last_hit
                }
                // console.log(response.data);
                } catch (error) {
                console.error(error);
            }
    }
  };
</script>
<style>
.previous {
  background-color: #f1f1f1;
  color: black;
}
.headDetails2{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}
.bg-blue2{
    background-color: #e5f1ff !important;
    height:50px !important;
    width: 700px;
}
.gap6{
  margin-left:10px;
  margin-top: 20px;
  width: 200px;
  height: 48px;
}
</style>