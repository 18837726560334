<template>
    <div>
        <div class="main__section">
            <div class="sidebar__left">
                <div class="position-relative">
                    <div class="logo__company"><img src="../../assets/img/bizlogo.png" alt="bizlogo"></div>
                    <div class="menu__items">
                        <div class="menu__item__single active__menu__item"><b-icon icon="speedometer2"></b-icon>
                            <p>Dashboard</p>
                        </div>
                        <div class="menu__item__single"><b-icon icon="cloud-arrow-up"></b-icon>
                            <p>API</p>
                        </div>
                        <div class="menu__item__single"><b-icon icon="geo"></b-icon>
                            <p>Locations</p>
                        </div>
                        <div class="menu__item__single"><b-icon icon="gear"></b-icon>
                            <p>Settings</p>
                        </div>
                    </div>
                    <div class="menu__bottom__items">
                        <div class="menu__bottom_item__single"><b-icon icon="question-lg"></b-icon>
                            <p>FAQ</p>
                        </div>
                        <div class="menu__bottom_item__single"><b-icon icon="box-arrow-right"></b-icon>
                            <p>Sign off</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sidebar__right">
                <div class="navbar__section">
                    <div class="row">
                        <div class="col-lg-6 p-0">
                            <!-- <h3>Destination</h3>
                            <p>Get all destinations on map!</p> -->
                        </div>
                        <div class="col-lg-6 p-0">
                            <div class="other__navpart">
                                <div class="search__icon nav__div"><b-icon icon="search"></b-icon></div>
                                <div class="notification__icon nav__div"><b-icon icon="bell"></b-icon><span>3</span></div>
                                <div class="profile__icon nav__div">
                                    <img src="../../assets/img/picture-profile-girl.png" alt="image">
                                    <div class="profile__box">
                                        <span class="shp_tri"></span>
                                        <div class="pt-0"><b-icon icon="person-check"></b-icon>Profile</div>
                                        <div><b-icon icon="gear"></b-icon>Settings</div>
                                        <div><b-icon icon="box-arrow-right"></b-icon>Logout</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map__section">
                    <div class="map__filter" :class="[showfilter ? 'collapse__menu' : '']">
                        <h3>Destination</h3>
                        <p>Enter location details here!</p>
                        <hr>
                        <div class="form__map">
                            <div class="form__group">
                                <label for="">Select City</label>
                                <v-select
                                    :options="city">
                                </v-select>
                            </div>
                        </div>
                        <div class="navigation__filter text-end">
                            <button @click="applymapfilter">Apply &nbsp;<b-icon icon="arrow-right"></b-icon></button>
                        </div>
                        <div class="close__map__filter" @click="showfilter=!showfilter"><b-icon icon="list" class="trans90"></b-icon></div>                                
                    </div>
                    <LeafletMap /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select"
import LeafletMap from '../googlemap/Leafletmap.vue'

export default {
    data() {
    return{
        countries: ["India", "Australia", "USA"],
        state:[],
        city:["Mumbai","Delhi","Pune"],
        showfilter: false,
    }
  },
  components: {
    vSelect,
    LeafletMap
  },
  methods:{
    applymapfilter(){
        this.showfilter=!this.showfilter
    }
  }
}

</script>

