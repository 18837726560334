import axios from 'axios';
import router from '../../router/index';
const api = axios.create({
  baseURL: window.location.origin+'/',
  // baseURL:'http://127.0.0.1:8000/',
  headers: {
    // 'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(config=> {
  const token=localStorage.getItem('token');
  if (token){
    config.headers.Authorization=`Token ${token}`;
    // config.headers.refresh = localStorage.getItem('refesh_token');
  }
  return config;
});
api.interceptors.response.use(
  response=>response,
  error=>{
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
    //   localStorage.removeItem('refesh_token');
      router.push({ name:'login'});
    }
    return Promise.reject(error);
  }
);

export default api;