<template>
    <div>
      <div v-if="loading" class="loader"></div>
      <h3 align="center" v-if="loading" >Loading Data...</h3>
      <div v-if="!loading">
        <div>
          <b-row>
            <b-col lg="12">
              <h3 align="center">List Of Customized Polygon</h3>
              <input class="gap" type="text" v-model="filterText2" placeholder="Country..." />
              <input class="gap" type="text" v-model="filterText1" placeholder="Locations..." />
              <table>
                <thead>
                  <tr>
                    <th v-for="(col) in activelogs.columns" :key="col.id">
                      {{ col.toUpperCase() }}
                    </th>
                  </tr>
                </thead>          
                <tbody>
                  <tr v-for="(rw, item) in paginatedData" :key="item">
                    <td>{{ rw.location }}</td>
                    <td>{{ rw.city }}</td>
                    <td>{{ rw.country }}</td>
                    <td>{{ rw.date }}</td>
                    <td>{{ rw.time }}</td>
                    <td>
                      <b-button v-if="rw.action ==''" class="gap3" variant="primary" :to="{name:'viewpolygon',params:{location:rw.location,city:rw.city,country:rw.country,datetime:rw.datetime,info:'Default'}}">View Polygon</b-button>
                      <b-button v-if="rw.action =='' && rw.index_value==102" class="gap3 width" variant="primary" :to="{name:'boundary', params: { location: rw.location, country: rw.country , index:rw.index_value}}">Default</b-button>
                      <b-button v-if="rw.action ==''  && rw.index_value!=102" class="gap3 width" variant="primary" @click="DeleteCustomPolygon(rw,item)">Delete</b-button>
                      <p v-if="rw.action =='null'">{{ rw.action }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <b-button variant="primary" @click="previousPage" :disabled="currentPage === 1" class="mx-2 py-1">Previous</b-button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <b-button variant="primary" @click="nextPage" :disabled="currentPage === totalPages"  class="mx-2 py-1">Next</b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from '@axios'
  import { BRow, BCol, BButton, BFormSelect, BFormSelectOption, BPopover, BIcon}from "bootstrap-vue"
  export default {
    components: {
      BRow,
      BCol,BButton,
    //   BFormSelect,
    //   BFormSelectOption,
    //   BPopover, BIcon
    },
    data() {
      return {
        loading: true,
        activelogs:null,
        deleteresponse:null,
        filterText1: '',
        filterText2: '',// Filter text
        currentPage: 1,
        itemsPerPage: 100, // Number of items per page
      }
    },
    methods:{
      previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    handleModalClose(){
      window.location.reload();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    CustomPolygon: function(){
        axios.get('fetchcreatedpolygon', {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
            this.activelogs = response.data.logs
            this.loading = false;
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        })
    },
    DeleteCustomPolygon(row, index){
        axios.post('deletecustompolygon', {
            city:row.location,
            country:row.country,
            datetime:row.datetime,
        },{
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
          this.deleteresponse=response.data
          this.$swal({
              icon: 'success',
              title: 'Data Deleted successfully',
              text: this.response,
          });
          this.CustomPolygon()
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        }) 
    },
    },
    mounted() {
      // this.Error()
        this.CustomPolygon()
    },
    computed: {
    filteredData() {
      if (this.filterText1 && this.filterText2 ) {
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower1) &&  
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText1) {
        const filterTextLower1 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower1)
        );
      } else if (this.filterText2) {
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else {
        return this.activelogs.data;
      }
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    },
    getColumnClass(index) {
      // Add a specific class to the second column
      return index === 3 ? "modified-column" : "";
    },
    getColumnContent(col, index) {
      // Modify the content of the second column
      if (index === 3) {
        return `<i class="fas fa-user"></i> ${col}`;
      } else {
        return col;
      }
    },
  },
  }
  </script>
  <style>
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
    margin-top: 200px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .gap{
  margin-right: 5px;
  border-color:black;
  width: 250px;
  }
  .gap2{
  margin-top: 10px;
  border-color:black;
  width:15%;
  background:white;
  }
  .gap3{
  margin-left: 5px;
  margin-top: 5px;
  }
  .gap4{
  margin-left:20px;
  width: 100px;
  height: 48px;
  }
  .gap5{
  margin-left:20px;
  width: 120px;
  height: 48px;
  }
  .info{
    padding-left:20px;
  }
  .width{
    width: 100px;
  }
  </style>
  <script setup>
  const userType = localStorage.getItem('userType');
  </script>
  