<template>
  <div>
    <b-row>
      <b-col md="9" class="pt-4">
        <b-row>
      <!-- <b-col md="auto">
            <b-calendar v-model="value" @context="onContext" locale="en-US"></b-calendar>
          </b-col> -->
      <b-col lg="4">
        <div class="ssbx bg_1 dashboard_height">
          <h5><b-avatar icon="file-bar-graph" class="space-between"></b-avatar>
            Total Hits</h5>
          <!-- <datepicker v-model="selectedDate"></datepicker> -->
          <h5>{{ allCountValue }}<span class="fontsize" v-if="this.allCountValue!=null">Hits</span></h5>
          <!-- <p>Api Total Hits</p> -->
          <p>Date : {{ startDateValue }} - {{ endDateValue }}</p>
          <!-- <span class="red_date"></span> -->
          <!-- <span class="sml_lin">1 May 2023</span> -->
        </div>
        <!-- <div class="ssbx bg_1 dashboard_height" v-if="userType == 'Admin'">
          <h5><b-avatar icon="person" class="space-between"></b-avatar>
          Total Clients</h5>
          <h5>{{ client_count }}<span class="fontsize" v-if="this.allCountValue!=null">Clients</span></h5>
        </div> -->
      </b-col>
      <b-col lg="4">
        <div class="ssbx bg_2 dashboard_height">
          <!-- <h5><b-avatar icon="sort-numeric-up-alt" class="space-between"></b-avatar><span>Daily Hits</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b-button variant="primary" :to="{name:'clienthits'}" v-if="userType == 'Admin'">View Client</b-button></h5> -->
          <h5><b-avatar icon="sort-numeric-up-alt" class="space-between"></b-avatar><span>Daily Hits</span></h5>
          <!-- <h5>{{dailyCountDate}}</h5> -->
          <h5>{{ dailyCountValue }}<span class="fontsize" v-if="this.dailyCountValue!=null">Hits</span></h5>
          <!-- <p>Api Daily Hits</p> -->
          <p>Date : {{ dailyCountDate }}</p>
          <!-- <span class="sml_lin">Count date wise</span> -->
        </div>
        <!-- <div class="ssbx bg_2 dashboard_height" v-if="userType == 'Admin'">
          <h5><b-avatar icon="sort-numeric-up-alt" class="space-between"></b-avatar>Total Client Hits</h5>
          <h5>{{ client_total_hits }}<span class="fontsize" v-if="this.client_total_hits!=null">Hits</span></h5>
          <p>Date : {{ client_total_hits_startDateValue }} - {{ client_total_hits_endDateValue }}</p>
        </div> -->
      </b-col>
      <b-col lg="4">
        <div class="ssbx bg_3 dashboard_height">
          <h5><b-avatar icon="geo-alt" class="space-between"></b-avatar>Trending City</h5>
          <h5>{{ trending_city }} <span class="fontsize" v-if="this.trending_city!=null">Hits : {{ trending_city_count }}</span></h5>
          <p><span>Date : {{ trending_city_date }}</span></p>
          <!-- <span class="sml_lin">citywise</span> -->
        </div>
        <!-- <div class="ssbx bg_3 dashboard_height" v-if="userType == 'Admin'">
          <h5><b-avatar icon="geo-alt" class="space-between"></b-avatar>Daily Client Hits</h5>
          <h5>{{ client_daily_hits }} <span class="fontsize" v-if="this.trending_city!=null">Hits</span></h5>
          <p><span>Date : {{ client_daily_hits_Date }}</span></p>
        </div> -->
      </b-col>
      <!-- <b-col lg="3">
        <div>
          <b-form-datepicker v-model="bdatevalue" @context="onContext" locale="en-US"></b-form-datepicker>

        </div>
      </b-col> -->
    </b-row>

    <b-row>
      <!-- <b-col lg="4" class="mb-4">
        <div class="sscard mt-4"> -->
          <!-- <b-row>
            <b-col lg="12">
              <h4>Total Hits</h4> -->
              <!-- <span class="subtitle">Summary</span> -->
              <!-- <b class="font_size">Date : {{ startdate }} - {{ enddate }}</b> -->
            <!-- </b-col>
            <b-col lg="12">
              <vue-apex-charts v-if="datewiseSeries.length > 0 && datewiseChartOptions.xaxis.categories.length > 0"
                type='bar' height="350px" :options="datewiseChartOptions" :series="datewiseSeries"></vue-apex-charts>
            </b-col>
          </b-row> -->
        <!-- </div>
      </b-col> -->
      <b-col lg="4" class="mb-4">
        <div class="sscard mt-4">
          <b-row>
            <b-col lg="12">
              <h4>Todays Top Cities</h4>
              <!-- <span class="subtitle">Summary</span> -->
            </b-col>
            <b-col lg="12">
              <!-- {{ topCitiesSeries }} -->
              <vue-apex-charts type='donut' height="350px" :options="topCitiesChartOptions" :series="topCitiesSeries"
                v-if="topCitiesSeries.length > 0 && topCitiesChartOptions.labels.length > 0"></vue-apex-charts>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col lg="4" class="mb-4 d-flex">
        <div class="sscard mt-4">
          <b-row>
            <b-col lg="12">
              <h4>7-Days Hit Count</h4>
              <!-- <span class="subtitle">Summary</span> -->
            </b-col>
            <b-col lg="12">
              <vue-apex-charts v-if="datewiseBarSeries.length > 0 && datewiseBarChartOptions.xaxis.categories.length > 0"
                type='bar' height="350px" :options="datewiseBarChartOptions"
                :series="datewiseBarSeries"></vue-apex-charts>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>


      </b-col>
      <b-col md="3" class="pt-4 blueBase">
        <div>
          <!-- <input type="date" class="w-100" v-model="bdatevalue" @context="onContext" @input="formatDate" /> -->
          <b-form-datepicker 
          v-model="bdatevalue" 
          @context="onContext" 
          locale="en-US" 
          date-format="yyyy-MM-dd"
        ></b-form-datepicker>
        </div>
        <div class="mt-2">
          <h4 class="headingAttire down">Suppliers List <span>[</span>{{ total_supplier_count }}<span class="static_gap">]</span></h4>
          <div>
            <h4 class="headingAttire down">Suppliers Master :</h4>
            <div class="scroller1">
              <ul class="DSList">
                <li class="list-group-items d-flex justify-content-start align-items-center" v-for="item in supplier_data" :key="item" :style="{ fontSize: '12px',color: '#000000',fontWeight: 'bold'}" >
                  <div class="doubleBorder">
                    <b-avatar icon="people-fill" ></b-avatar >
                  </div>
                  <div class="supplierDetails">
                    <span class="supplierName">{{ item.supplier }}</span>
                    <span class="supplierCount">({{ item.Total_count }})</span>
                    <span >Updated On : {{ item.date_updated }}</span> 
                  </div>
                  </li>
              </ul>
            </div>
          </div>
          <h4 class="headingAttire down">Hotels Master (Otrams) :</h4>
            <div class="scroller1">
              <ul class="DSList">
                <li class="list-group-items d-flex justify-content-start align-items-center" v-for="item2 in hotels_supplier_data" :key="item2" :style="{ fontSize: '12px',color: '#000000',fontWeight: 'bold'}" >
                  <div class="doubleBorder">
                    <b-avatar icon="people-fill" ></b-avatar >
                  </div>
                  <div class="supplierDetails">
                    <span class="supplierName">{{ item2.supplier }}</span>
                    <span class="supplierCount">({{ item2.Total_count }})</span>
                    <span >Updated On : {{ item2.date_updated }}</span> 
                  </div>
                  </li>
              </ul>
            </div>
          <!-- <h4 class="headingAttire down" v-if="userType != 'Admin'">Suppliers List</h4>
          <ul class="list-group" v-if="userType != 'Admin'"> -->
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div class="ssbx bg_2" v-if="polygonlogs[0].Location != 'null' && userType != 'Admin'">
          <h4><b-avatar icon="card-list" class="space-between"></b-avatar>Custom Polygon List</h4>
          <div class="supplier__listbox d-flex align-items-center height" v-for="(item, index) in polygonlogs" :key="index">
            <div class="mb-0 magnitude">
                <p><b class="">Location: </b>{{ item.location }}</p>
                <p><b class="">City: </b>{{ item.city }}</p>
                <p><b class="">Country: </b> {{ item.country }}</p>
                <p><b class="">Date: </b> {{ item.date }}</p>
                <!-- <p><b class="">Time: </b> {{ item.Time }}</p> -->
                <b-button  v-if="item.location !='null'" :to="{name:'checkpolygon',params:{location:item.location,city:item.city,country:item.country,state:item.state,datetime:item.datetime}}">View</b-button>
            </div>
          </div>
          <h6 ><b>View more</b>&nbsp;<b-icon icon="arrow-right"></b-icon>&nbsp;<b-button variant="primary" :to="{name:'viewcustompolygon'}">View</b-button></h6>
        </div>
    <!-- <h5><b class="color_view">View Customized Polygon </b>&nbsp;<b-icon icon="arrow-right"></b-icon>&nbsp;<b-button class="shape" variant="primary" :to="{name:'viewcustompolygon'}">View</b-button></h5> -->
        <div class="ssbx bg_2" v-if="polygoncountlogs[0].location != 'null' && userType == 'Admin'">
          <h4><b-avatar icon="card-list" class="space-between"></b-avatar>Client Custom Polygon Info</h4>
            <!-- <h4><b-avatar icon="card-list" class="space-between"></b-avatar>Client Custom Polygon Info <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b-button variant="primary" :to="{name:'viewclientcustompolygon'}">View</b-button></span></h4> -->
            <div class="supplier__listbox d-flex align-items-center height" v-for="(item, index) in polygoncountlogs" :key="index">
              <div class="mb-0 magnitude">
                <p><b class="">User ID: </b>{{ item.user_id}}</p>
                  <p><b class="">Location: </b>{{ item.location }}</p>
                  <p><b class="">Country: </b> {{ item.country }}</p>
                  <!-- <p><b class="">Date: </b> {{ item.date }}</p> -->
                  <p><b class="">Count: </b> {{ item.count }}</p>
                  <!-- <b-button  v-if="item.Location !='null'" :to="{name:'viewpolygon',params:{location:item.Location,city:item.City,country:item.Country}}">View</b-button> -->
              </div>
            </div>
          </div>
          <h6  v-if="userType == 'Admin'"><b>View Custom Polygon</b>&nbsp;<b-icon icon="arrow-right"></b-icon>&nbsp;<b-button variant="primary" :to="{name:'viewcustompolygon'}">View</b-button></h6>
      </b-col>
    </b-row>
    <!-- <b-col lg="5">
        <b-row>
          <b-col lg="6">
            <div class="ssbx bg_5">
              <b-avatar icon="people-fill"></b-avatar>
              <h5>$1K</h5>
              <p>Total</p>
              <span class="sml_lin">+8% from yesterday</span>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="ssbx bg_5">
              <b-avatar icon="people-fill"></b-avatar>
              <h5>$1K</h5>
              <p>Total</p>
              <span class="sml_lin">+8% from yesterday</span>
            </div>
          </b-col>
        </b-row>
      </b-col> -->
    
    <!-- <b-row>
      <b-col lg="7">
        <div class="sscard mt-4 px-3">
          <h4>Supplier</h4>
          <span class="subtitle">Difference in count supplier wise.</span>

          <b-table small :fields="fields" :items="items" responsive="sm">
            
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(name)="data">
              <b class="text-info">{{ data.value.last.toUpperCase() }}</b
              >, <b>{{ data.value.first }}</b>
            </template>

            <template #cell(nameage)="data">
              {{ data.item.name.first }} is {{ data.item.age }} years old
            </template>

            <template #cell()="data">
              <i>{{ data.value }}</i>
            </template>
          </b-table>
        </div>
      </b-col>
      <b-col lg="5">
        <div class="sscard mt-4 px-3">
          <h4>Search citywise</h4>
          <span class="subtitle">Count number of search citywise</span>

          <b-table small :fields="fields" :items="items" responsive="sm">
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(name)="data">
              <b class="text-info">{{ data.value.last.toUpperCase() }}</b
              >, <b>{{ data.value.first }}</b>
            </template>

            <template #cell(nameage)="data">
              {{ data.item.name.first }} is {{ data.item.age }} years old
            </template>

            <template #cell()="data">
              <i>{{ data.value }}</i>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
import axios from '@axios'
import { BRow, BCol, BAvatar, BFormDatepicker } from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    VueApexCharts,
    // BFormDatepicker,
    // BTable,
  },
  data() {
    return {
      
      // bdatevalue: new Date().toISOString(),
      bdatevalue: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
      context: null,
      datewiseSeries: [{
        name:'Total Hits',
        data: []
      }],
      datewiseChartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
              fontSize: '12px'
            }
          }
        }
      },

      datewiseBarSeries: [{
        name: 'Today Hit Count',
        data: []
      }],
      datewiseBarChartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
              fontSize: '12px'
            }
          }
        }
      },

      topCitiesSeries: [],
      topCitiesChartOptions: {
        chart: {
          height: 350,
        },
        labels: [],
        legend: {
          position: 'bottom'
        }

      },

      //   topCitiesChartOptions: {
      //     chart: {
      //       type: "donut",
      //     },
      //     legend: {
      //       position: "bottom",
      //     },
      //     labels: [],
      //     responsive: [
      //       {
      //         breakpoint: 480,
      //         options: {
      //           chart: {
      //             width: 200,
      //           },
      //           legend: {
      //             position: "bottom",
      //           },
      //         },
      //       },
      //     ],
      //   },

      series2: [
        {
          name: "Income",
          type: "column",
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
        },
        {
          name: "Cashflow",
          type: "column",
          data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5],
        },
        {
          name: "Revenue",
          type: "line",
          data: [20, 29, 37, 36, 44, 45, 50, 58],
        },
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [1, 1, 4],
        },
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#008FFB",
            },
            labels: {
              style: {
                colors: "#008FFB",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          {
            seriesName: "Income",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#00E396",
            },
            labels: {
              style: {
                colors: "#00E396",
              },
            },
          },
          {
            seriesName: "Revenue",
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FEB019",
            },
            labels: {
              style: {
                colors: "#FEB019",
              },
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: "bottom",
          offsetX: 40,
        },
      },

      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "name", label: "Full Name" },
        // A regular column
        "age",
        // A regular column
        "sex",
        // A virtual column made up from two fields
        { key: "nameage", label: "First name and age" },
      ],
      items: [
        { name: { first: "John", last: "Doe" }, sex: "Male", age: 42 },
        { name: { first: "Jane", last: "Doe" }, sex: "Female", age: 36 },
        { name: { first: "Rubin", last: "Kincade" }, sex: "Male", age: 73 },
        {
          name: { first: "Shirley", last: "Partridge" },
          sex: "Female",
          age: 62,
        },
      ],
      masterdata: '',
      allCountValue: null,
      dailyCountValue: null,
      startDateValue: null,
      startdate:null,
      enddate:null,
      endDateValue: null,
      dailyCountDate: null,
      selectedDate: null,
      polygonlogs:null,
      polygoncountlogs:null,
      supplier_list:[],
      supplier_count:[],
      supplier_data:[],
      total_supplier_count:[],
    }
  },
  methods: {
    onContext(ctx) {
      // alert("onContext")

      this.context = ctx

      // this.masterDatacall()
      setTimeout(() => {
      this.thirdSubmit()
    }, 2000); 
    },
    masterDatacall: function () {
      axios.get('masterdata', {
        // headers: { 'Access-Control-Allow-Origin': '*' }
      }).then(response => {
        this.masterdata = response.data
        console.log("masrterdataresponse", this.masterdata)
      }).catch(error => {
      })
    },
    CustomPolygon: function(){
        axios.get('fetchcreatedpolygondashboard', {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
            this.polygonlogs = response.data.logs.data
            console.log('this.activelogs',response.data)
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        })
    },
    CustomPolygoncount: function(){
        axios.get('clientcustompolygonlist', {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
            this.polygoncountlogs = response.data.info.data
            console.log('this.activelogs',response.data)
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        })
    },
    AssignedSupplierList: function(){
        axios.post('supplier_list', {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
            this.supplier_list = response.data.supplier_list
            this.supplier_count=response.data.supplier_count
            this.supplier_data=response.data.data
            this.hotels_supplier_data=response.data.data2
            this.total_supplier_count=response.data.count
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        })
    },
    thirdSubmit() {
      this.startdate=[];
      this.enddate=[];
      this.allCountValue = [];
      this.startDateValue = [];
      this.endDateValue = [];
      this.dailyCountValue = [];
      this.dailyCountDate = [];
      this.datewiseChartOptions.xaxis.categories = [];
      this.datewiseSeries[0].data = [];
      this.topCitiesSeries = [];
      this.topCitiesChartOptions.labels = [];
      this.datewiseBarChartOptions.xaxis.categories = [];
      this.datewiseBarSeries[0].data = [];
      this.trending_city = [];
      this.trending_city_date = [];
      this.trending_city_count=[];
      this.client_count=[];
      this.client_total_hits=[];
      this.client_daily_hits=[];
      this.client_total_hits_startDateValue=[];
      this.client_total_hits_endDateValue=[];
      this.client_daily_hits_Date=[];

      const urls = [
        "allcount", //0
        "dailycount", //1
        "topcities", //2
        "topcities_datewise", //3
        "datewise_count", //4
        "trending_city",//5
        // "supplier_list",//6
        // "clientcount",//7
        // "clienttotalhits",//8
        // "clientdailyhits",//9
        //, "/api/hotelstable", //0
      ]

      console.log(this.context.selectedYMD);


      Promise.all(urls.map(url => axios.post(url, { selectedDate: this.context.selectedYMD }))).then(responses => {
        // console.log(responses)
        this.allCountValue = responses[0].data.all_data.total_count
        this.startDateValue = responses[0].data.all_data.start_date
        this.endDateValue = responses[0].data.all_data.end_date
        this.dailyCountValue = responses[1].data.daily_data.count_for_date
        this.dailyCountDate = responses[1].data.daily_data.date
        this.datewiseChartOptions.xaxis.categories = responses[2].data.city
        this.datewiseSeries[0].data = responses[2].data.count
        this.startdate=responses[2].data.start_date
        this.enddate=responses[2].data.end_date
        this.topCitiesSeries = responses[3].data.count
        this.topCitiesChartOptions.labels = responses[3].data.city
        this.datewiseBarChartOptions.xaxis.categories = responses[4].data.date
        this.datewiseBarSeries[0].data = responses[4].data.count
        this.trending_city = responses[5].data.trending_data.trending_city
        this.trending_city_date = responses[5].data.trending_data.date
        this.trending_city_count=responses[5].data.trending_data.count
        // this.client_count=responses[7].data.count_client
        // this.client_total_hits=responses[8].data.client_total_hits.total_count
        // this.client_total_hits_startDateValue=responses[8].data.client_total_hits.start_date
        // this.client_total_hits_endDateValue=responses[8].data.client_total_hits.end_date
        // this.client_daily_hits=responses[9].data.client_daily_hits.daily_count
        // this.client_daily_hits_Date=responses[9].data.client_daily_hits.date
        console.log('kljgf',this.supplier_count);
      }).catch(error => {
        // console.log(error)
      })
    },
  },
  mounted() {
    // alert("Mounted")
    this.CustomPolygon()
    this.masterDatacall()
    this.CustomPolygoncount()
    this.AssignedSupplierList()

  },

}
</script>

<script setup>
  import { onMounted } from 'vue';
  import store from '../../store';

  const userType = localStorage.getItem('userType');
  
  const fetchApikey = async () => {
    try {
      const response = await axios.get("fetchgoogleapi");  // Replace with your actual API endpoint
      store.commit('setApikey', response.data.APIkey); // Commit mutation to store API key in Vuex store
      // store.commit('setApikey', response.data.APIkey)
      // console.log(response.data.APIkey)
      // console.log(setApikey.value)
      error.value = null; // Reset error if the request is successful
    } catch (error) {
      console.error('Error fetching counter value:', error);
      error.value = 'Failed to fetch counter value';
    }
  };
  
  onMounted(() => {
    fetchApikey();
  });

</script>
<!-- <script setup>
  import { onMounted } from 'vue';
  import store from '../../store';

  const fetchApikey = async () => {
    try {
      const response = await axios.get("fetchgoogleapi");  // Replace with your actual API endpoint
      store.commit('setApikey', response.data.APIkey)
      console.log(response.data.APIkey)
      // console.log(counter.value)
      error.value = null; // Reset error if the request is successful
    } catch (error) {
      console.error('Error fetching counter value:', error);
      error.value = 'Failed to fetch counter value';
    }
  };
  
  onMounted(() => {
    fetchApikey();
  });
  
  </script> -->
  
<style>
.span{
  padding-left: 20px;
}
.font_size{
  font-size: 13;
}
.down{
  margin-top: 20px;
}
.space{
  margin-left: auto;
}
.space-between{
  margin-right: 10px;
}
.fontsize{
  font-size: medium;
  margin-left: 10px;
  font-weight: 500;
}
.scroller1{
  overflow-y: auto;
  max-height: 220px;
}
.color_view{
  color: blue;
}
.shape{
  height: 40px;
  border-radius: 100% !important;
  width: 70px;
}
.height{
  height:40px !important;
}
.dashboard_height{
  height:125px !important;
}
.datespace{
  margin-left: 30px;
}
</style>
