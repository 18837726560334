<template>
    <div>
      <div v-if="loading" class="loader"></div>
      <h3 align="center" v-if="loading" >Loading Data...</h3>
      <div v-if="!loading">
        <div>
          <b-row>
            <b-col lg="12">
              <h3 align="center">Client LOGS</h3>
              <!-- <p align="center">Error logs are data records that document success,errors,unexpected events within software or systems.</p> -->
              <!-- <p><b><u>Response_Status</u> </b><b class="info"><u>200</u> : </b>indicates success<b class="info"><u>403</u> : </b>indicates Error</p>
              <p><b><u>Action Button</u> : </b> Clicking on Action Button redirect user to another page where the user can save polygon coordinates according to him selecting the OSM Response.</p> -->
              <!-- <input v-if=" userType =='Admin'" class="gap" type="number" v-model="filterText3" min="1" placeholder="User_id..." /> -->
              <input class="gap" type="date" v-model="filterText5" placeholder="Date..." />
              <!-- <input v-if="userType =='Admin'" class="gap" type="text" v-model="filterText6" placeholder="Username..." /> -->
              <input class="gap" type="text" v-model="filterText1" placeholder="Locations..." />
              <input class="gap" type="text" v-model="filterText2" placeholder="Country..." />
              <b-form-select class="gap2" type="text" v-model="filterText4" id="statusDropdown">
                <b-form-select-option value="" disabled>Select Status</b-form-select-option>
                <b-form-select-option value="Alert">Alert</b-form-select-option>
                <b-form-select-option value="Failed">Failed</b-form-select-option>
                <b-form-select-option value="Request">Request</b-form-select-option>
                <b-form-select-option value="Success">Success</b-form-select-option>
              </b-form-select>
              <b-button class="gap4" variant="primary" @click="handleModalClose">Reset</b-button>
              <!-- <b-button v-if="userType =='Client'" class="gap5" variant="primary" :to="{name:'agenterror'}">Agent Logs</b-button> -->
              <table>
                <thead>
                  <tr>
                    <th v-for="(col) in activelogs.columns" :key="col.id">
                      {{ col.toUpperCase() }}
                      <b-icon v-if="col == 'response_status'" id="link-button" icon="exclamation-circle"></b-icon>
                      <b-popover v-if="col == 'response_status'" target="link-button" triggers="hover">
                      <p><b class="info"><u>200</u> : </b>Indicates Success</p>
                      <p><b class="info"><u>403</u> : </b>Indicates Error</p>
                      </b-popover>
                      <b-icon v-if="col == 'action'" id="link-button2" icon="exclamation-circle"></b-icon>
                      <b-popover v-if="col == 'action'" target="link-button2" triggers="hover">
                        <p><b><u>Open Map Button</u> : </b> Clicking on Open Map Button redirect user to another page where the user can save polygon coordinates according to him selecting the OSM Response.</p>
                      </b-popover>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rw, item) in paginatedData" :key="item">
                    <!-- <td v-if="userType =='Admin'">{{ rw.user_id }}</td>
                    <td v-if="userType =='Admin'">{{ rw.username }}</td> -->
                    <td>{{ rw.date }}<span class="info2"></span>{{ rw.time }}</td>
                    <td>{{ rw.username.toUpperCase() }}</td>
                    <td>{{ rw.country }}</td>
                    <td><b-link target="_blank" :to="{name:'boundary', params: {location:rw.location,state:rw.state,country:rw.country,index:'null'}}">{{ rw.location }}</b-link><span class="info2" v-if="rw.state !='na'">{{ rw.state }}</span></td>
                    <!-- <td>{{ rw.date }}<span class="info"></span>{{ rw.time }}</td> -->
                    <!-- <td>{{ rw.datetime }}</td> -->
                    <!-- <td v-if="userType !='Admin' && userType !='Client' " >{{ rw.date }}</td> -->
                    <!-- <td>{{ rw.response_status }}</td> -->
                    <td ><span v-if="rw.suppliers=='none'" class="span_space">{{ "All Assigned Suppliers" }}</span><span v-if="rw.suppliers!='none'" class="span_space">{{ rw.suppliers }}</span><span :class="[rw.status == 'Success' ? 'greentxt' : 'redtxt']">({{ rw.status.toLowerCase() }})</span> {{ rw.error.toLowerCase() }}</td>
                    <!-- <td>{{ rw.error }}</td> -->
                    <td >{{ rw.flag }} ({{ rw.source.toUpperCase() }})</td>
                    <!-- <td >{{ rw.source.toUpperCase() }}</td> -->
                    <td >{{ rw.duration }} sec</td>
                    <!-- <td v-if="userType !='Admin'">{{ rw.flag }}</td> -->
                    <!-- <td >{{ rw.flag }}</td> -->
                    <td>
                      <!-- <b-link class="h6 me-2 d-inline-block" v-if="rw.action =='None'" :to="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></b-link> -->
                      <a class="h6 me-2 d-inline-block" v-if="rw.action =='None' && rw.state !='na'" :href="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.state)}+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></a>
                      <a class="h6 me-2 d-inline-block" v-if="rw.action =='None' && rw.state =='na'" :href="`https://www.google.com/maps/place/${encodeURIComponent(rw.location)},+${encodeURIComponent(rw.country)}`" target="_blank" title="Map"><b-icon icon="geo-alt"></b-icon></a>
                      <b-link title="info" class="h6 me-2 d-inline-block" :to="{name:'custommap', params: {city:rw.location,country:rw.country,state:rw.state}}" target="_blank" rel="noopener noreferrer"><b-icon icon="pentagon"></b-icon></b-link>
                      <b-link title="info" class="h6 me-2 d-inline-block" :to="{name:'destinationcustom', params: {city:rw.location,state:rw.state,country:rw.country,supplier:rw.suppliers,userid:rw.encrypted_username}}" target="_blank" rel="noopener noreferrer"><b-icon icon="geo"></b-icon></b-link>
                      <b-link class="h6 me-2 d-inline-block" v-if="rw.action !='null'" @click="reportInfo(rw.location,rw.country,rw.user_id,rw.date,rw.time,rw.suppliers)" title="Logs"><b-icon icon="file-text"></b-icon></b-link>
                      <!-- <b-link class="h6 me-2 d-inline-block" v-if="rw.action !='null'" title="info" :to="{name:'hotellist', params: {location:rw.location,country:rw.country,user:rw.user_id,username:rw.encrypted_username,state:rw.state}}"><b-icon icon="bounding-box-circles"></b-icon></b-link> -->
                      <b-link v-if="rw.action !='null' && rw.flag =='API'" title="info" class="h6" :to="{name:'hotellist', params: {location:rw.location,country:rw.country,user:rw.user_id,username:rw.encrypted_username,state:rw.state,supplier:rw.suppliers}}" target="_blank" rel="noopener noreferrer"><b-icon icon="info-circle"></b-icon></b-link>
                      <!-- <b-button v-if="rw.action =='None'" class="gap3" variant="primary" @click="fetchData(rw, item)">Action</b-button> -->
                      <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button> -->
                      <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                      <!-- <b-button v-if="rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                      <!-- <b-button v-if="rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Custom</b-button> -->
                      <p v-if="rw.action =='null'">{{ rw.action }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <b-button variant="primary" @click="previousPage" :disabled="currentPage === 1" class="mx-2 py-1">Previous</b-button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <b-button variant="primary" @click="nextPage" :disabled="currentPage === totalPages"  class="mx-2 py-1">Next</b-button>
              </div>
              <!-- <b-table :items="paginatedData" >
                <template #cell(action)="data">
                  <b-button v-if="rw.action =='None'" class="gap3" variant="primary" :to="{name:'boundary', params: {location:data.item.location,country:data.item.country}}">Action</b-button>
                      <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button>
                      <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:data.item.location,country:data.item.country,user:data.item.user_id,time:data.item.time,date:data.item.date}}">View</b-button>
                      <p v-if="data.item.action =='null'">{{ data.item.action }}</p>
                </template>
                </b-table> -->
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from '@axios'
  import JSZip from 'jszip';
  import { BRow, BCol, BButton, BFormSelect, BFormSelectOption, BPopover, BIcon}from "bootstrap-vue"
  export default {
    components: {
      BRow,
      BCol,BButton,
      BFormSelect, BFormSelectOption, BPopover, BIcon
    },
    data() {
      return {
        loading: true,
        activelogs:null,
        status:null,
        userName:'',
        city:'',
        country:'',
        date:'',
        time:'',
        error:'',
        status2:'',
        suppliers:'',
        flag:'',
        search:'',
        response:'',
        response_status:'',
        end_time:'',
        time_taken:'',
        source:'',
        osm_response:'',
        jsonData1:{
            Title:'Search Logs',
            Time:'',
            Date:'',
            Search:'',
        },
        jsonData2:{
            Title:'Response Logs',
            Time:'',
            Date:'',
            Time_Taken:'',
            Response:'',
        },
        jsonData3:{
            Response:''
        },
        filterText1: '',
        filterText2: '',
        filterText3:'',
        filterText4:'',
        filterText5:'',
        filterText6: '',
        currentPage: 1,
        itemsPerPage: 100, // Number of items per page
      }
    },
    methods:{
      previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    downloadData1() {
      console.log("download data 1")
        const jsonData1={
          Date:this.date,
          Time:this.time,
          Search:this.search,
        };
        return JSON.stringify(jsonData1, null, 2);
    },
    downloadData2() {
      console.log("download data 2")
        const jsonData2={
          Date:this.date,
          Start_Time:this.time,
          End_Time:this.end_time,
          Time_Taken:this.time_taken,
          Response:this.response,
        }
        return JSON.stringify(jsonData2, null, 2);
    },
    osmresponse(){
        this.jsonData3.Response=this.osm_response
        const jsonData = JSON.stringify(this.jsonData3, null, 2); // Convert JSON object to a nicely formatted JSON string
        this.downloadTextFile(jsonData,`${this.userName}_osmresponse_${this.city}_${this.country}.json`);

    },
    downloadTextFile(text, filename) {
        const blob = new Blob([text], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    },
    downloadZip() {
        console.log("downloadzip function is called")
        const zip = new JSZip();

        // Adding JSON data to zip
        zip.file(`${this.userName}_search_log_${this.city}_${this.date}.json`, this.downloadData1());
        zip.file(`${this.userName}_response_log_${this.city}_${this.date}.json`, this.downloadData2());

        // Generate the zip file
        console.log("function is called for zip")
        zip.generateAsync({ type: "blob" })
        .then(content => {
            // Create a link element
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = `${this.userName}_logs_${this.city}_${this.date}.zip`;

            // Trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    },
    handleModalClose(){
      this.filterText1=''
      this.filterText2=''
      this.filterText3=''
      this.filterText4=''
      this.filterText5=''
      this.filterText6=''
    },
    reportInfo(location,country,user,date,time,suppliers){
      console.log(location,country,user,date,time)
      axios.post('searchreport',{
          location:location,
          country:country,
          user:user,
          time:time,
          date:date,
          suppliers:suppliers,
        },{
        // { headers: { 'Access-Control-Allow-Origin': '*' }}
        }).then(response => {
            this.userName = response.data.report.username
            this.city = response.data.report.city
            this.country = response.data.report.country
            this.date = response.data.report.date
            this.time = response.data.report.time
            this.error = response.data.report.error
            this.status2 = response.data.report.status
            this.suppliers = response.data.report.suppliers
            this.flag = response.data.report.flag
            this.search=response.data.report.search
            this.response=response.data.report.response
            this.response_status=response.data.report.response_status
            this.end_time=response.data.report.end_time
            this.time_taken=response.data.report.time_taken
            this.source=response.data.report.source
            this.osm_response=response.data.report.osm_response
            this.downloadZip()
        }).catch(error => {
            console.log("Something went wrong in reportInfo()")
        })
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
        Error: function(){
            axios.post('allclientlogs', {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.activelogs = response.data.logs
                console.log('this.activelogs',response.data)
            }).catch(error => {
                // console.log("Something went wrong in countryGetList()")
            })
        },
        // reportinfo(row, index){
        //   this.Reportdata={
        //     location:row.location,
        //     country: row.country,
        //     user: row.user_id,
        //     time:row.time,
        //     date:row.date,
            
        //   }
        //   this.$router.push({ name: 'report', params:{ id:this.Reportdata}});
        // },
        Refreshcount: function(){
        axios.post('testrestart', {
            // headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(response => {
            const resetdata=0;
            this.$store.commit('setCounter',resetdata)
            this.status = response.data
        }).catch(error => {
            // console.log("Something went wrong in countryGetList()")
        })
      },
    },
    mounted() {
      // this.Error()
      this.Refreshcount()
      setTimeout(() => {
        this.Error()
        this.loading = false; // Set loading state to false after the data is fetched or processed
      }, 1000); // Simulating a delay (2 seconds) for data fetching, replace this with your actual data fetching logic
    
    },
    computed: {
    filteredData() {
      if (this.filterText3 && this.filterText2 && this.filterText1 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3  && this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText1  && this.filterText2 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&  
          item.country.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText2 && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.country.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText1  && this.filterText2) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&  
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText6 && this.filterText1  && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) &&  
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText6 && this.filterText2 ) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower2 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.country.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText6 && this.filterText1) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterTextLower1 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.location.toLowerCase().includes(filterTextLower1) 
        );
      } else if (this.filterText6  && this.filterText5) {
        const filterTextlower6 = this.filterText6.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextlower6) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1  && this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if ( this.filterText1  && this.filterText4 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText2 && this.filterText4 && this.filterText5) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item => 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText1 && this.filterText2 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if ( this.filterText1 && this.filterText2 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 &&  this.filterText4 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText2 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4) 
        );
      } else if (this.filterText3 && this.filterText1  && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText1 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText1) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText3 && this.filterText2) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText3 && this.filterText4) {
        const filterTextlower1 = Number(this.filterText3);
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText3 && this.filterText5) {
        const filterTextlower1 = Number(this.filterText3);
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText1 && this.filterText2) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText1 && this.filterText4) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&  
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText1 && this.filterText5) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText2 && this.filterText4) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.status.toLowerCase().includes(filterTextLower4)
        );
      } else if (this.filterText2 && this.filterText5) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText4 && this.filterText5) {
        const filterTextLower4 = this.filterText4.toLowerCase();
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4) &&
          item.date.includes(filterDatelower5)
        );
      } else if (this.filterText3) {
        const filterTextlower1 = Number(this.filterText3);
        return this.activelogs.data.filter(item =>
          (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1
        );
      } else if (this.filterText1) {
        const filterTextLower2 = this.filterText1.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.location.toLowerCase().includes(filterTextLower2)
        );
      } else if (this.filterText2) {
        const filterTextLower3 = this.filterText2.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.country.toLowerCase().includes(filterTextLower3)
        );
      } else if (this.filterText4) {
        const filterTextLower4 = this.filterText4.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.status.toLowerCase().includes(filterTextLower4)
        );
      }
      else if (this.filterText6) {
        const filterTextLower6 = this.filterText6.toLowerCase();
        return this.activelogs.data.filter(item =>
          item.username.toLowerCase().includes(filterTextLower6)
        );
      }  else if (this.filterText5) {
        const filterDatelower5 = this.filterText5;
        return this.activelogs.data.filter(item =>
          item.date.includes(filterDatelower5)
        );
      } else {
        console.log("this is else condition")
        return this.activelogs.data;
      }
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredData.slice(startIndex, endIndex);
    },
    getColumnClass(index) {
      // Add a specific class to the second column
      return index === 3 ? "modified-column" : "";
    },
    getColumnContent(col, index) {
      // Modify the content of the second column
      if (index === 3) {
        return `<i class="fas fa-user"></i> ${col}`;
      } else {
        return col;
      }
    },
  },
  }
  </script>
  <style>
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
    margin-top: 200px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .gap{
  margin-right: 5px;
  border-color:black;
  width: 250px;
  }
  .gap2{
  margin-top: 10px;
  border-color:black;
  width:15%;
  background:white;
  }
  .gap3{
  margin-left: 5px;
  margin-top: 5px;
  }
  .gap4{
  margin-left:20px;
  width: 100px;
  height: 48px;
  }
  .gap5{
  margin-left:20px;
  width: 120px;
  height: 48px;
  }
  .info{
    padding-left:20px;
  }
  .info2{
    padding-left:5px;
  }
  .span_space{
    margin-right: 10px;
  }
  </style>
  <script setup>
  const userType = localStorage.getItem('userType');
  </script>
  