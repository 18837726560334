<template>
  <div>
    <gmap-map
      :center="center"
      :options="mapStyle"
      :zoom="11" class="map_full">
      <gmap-polygon :paths="paths" ></gmap-polygon>
    </gmap-map>
  </div>
</template>
   
<script>
export default {
  data() {
    return {
      center: { 
          lat: this.setpolygonline[0].lat, 
          lng: this.setpolygonline[0].lng,
      },  
      paths: [],
      mapStyle: {
        fullscreenControl: false, 
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
    styles: [
        {elementType: 'geometry', stylers: [{color: '#e5e4e0'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#e5e4e0'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '#000'}]},
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#526e84'}]
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#000'}]
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{color: '#b8dc9c'}]
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#000'}]
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#f9a664'}]
        },
        {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{color: '#bf9343'}]
        },
        {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{color: '#6f5126'}]
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#f7fabf'}]
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{color: '#b1b864'}]
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{color: '#818181'}]
        },
        {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{color: '#3d4e9e'}]
        },
        {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{color: '#fff'}]
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#8accec'}]
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#fff'}]
        },
        {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{color: '#fff'}]
        }
      ]
    }
      }
  },
  
  props: {
    setpolygonline:[Object, Array, Number],
  },

  mounted() {
    this.setLocationLatLng()
  },
  
  methods: {
    setLocationLatLng: function() {
      this.paths= []
      this.center.lat= '';
      this.center.lng= '';


      this.paths = this.setpolygonline
      this.center.lat= this.setpolygonline[0].lat;
      this.center.lng= this.setpolygonline[0].lng;
      // console.log('filtergooglemap',this.paths)
    },
  },
}
</script>
  
  