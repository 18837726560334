<template>
    <div>
      <gmap-map
        :center="center"
        :options="mapStyle"
        :zoom="11" class="map_full">
        <gmap-marker        
          v-for="(gmp, index) in locations"
          :key="index"
          :position="gmp"
          :clickable="true"
          :draggable="false"
          :icon="{ url: require('../../assets/img/pingreen.png')}"
          @click="openWindow(index)"
        ></gmap-marker>
        <gmap-info-window 
              @closeclick="infoWindow.open=false" 
              :opened="infoWindow.open" 
              :position="infoWindow.position"
              :options="{
                pixelOffset: {
                  width: 0,
                  height: -35
                }
              }"
          >
              <div v-html="infoWindow.template"></div>
            </gmap-info-window>
        <gmap-polygon v-for="(object, index) in setpolygonline" :key="index" :paths="object.paths"></gmap-polygon>
        <gmap-polygon :paths="paths"></gmap-polygon>
      </gmap-map>
   
    </div>
  </template>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/gmap-vue@1.2.2/dist/gmap-vue.min.js"></script>
  <script>
  export default {
    data() {
      return {
        center: { 
            lat: this.mapdata[0].latitude, 
            lng: this.mapdata[0].longitude,
        },      
        locations: [],
        paths: [],
        Editable:false,
      //   markerOptions: {
      //   url: require('../../assets/img/pin.png'),
      //   size: {width: 60, height: 90, f: 'px', b: 'px',},
      //   scaledSize: {width: 30, height: 45, f: 'px', b: 'px',},
      // },
      infoWindow: {
            position: {lat: 0, lng: 0},
            open: false,
            template: ''
          },
        mapStyle: {
          fullscreenControl: false, 
          mapTypeControl: false,
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
      // styles: [
      //     {elementType: 'geometry', stylers: [{color: '#e5e4e0'}]},
      //     {elementType: 'labels.text.stroke', stylers: [{color: '#e5e4e0'}]},
      //     {elementType: 'labels.text.fill', stylers: [{color: '#000'}]},
      //     {
      //         featureType: 'administrative.locality',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#526e84'}]
      //     },
      //     {
      //         featureType: 'poi',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#000'}]
      //     },
      //     {
      //         featureType: 'poi.park',
      //         elementType: 'geometry',
      //         stylers: [{color: '#b8dc9c'}]
      //     },
      //     {
      //         featureType: 'poi.park',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#000'}]
      //     },
      //     {
      //         featureType: 'road',
      //         elementType: 'geometry',
      //         stylers: [{color: '#f9a664'}]
      //     },
      //     {
      //         featureType: 'road',
      //         elementType: 'geometry.stroke',
      //         stylers: [{color: '#bf9343'}]
      //     },
      //     {
      //         featureType: 'road',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#6f5126'}]
      //     },
      //     {
      //         featureType: 'road.highway',
      //         elementType: 'geometry',
      //         stylers: [{color: '#f7fabf'}]
      //     },
      //     {
      //         featureType: 'road.highway',
      //         elementType: 'geometry.stroke',
      //         stylers: [{color: '#b1b864'}]
      //     },
      //     {
      //         featureType: 'road.highway',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#818181'}]
      //     },
      //     {
      //         featureType: 'transit',
      //         elementType: 'geometry',
      //         stylers: [{color: '#3d4e9e'}]
      //     },
      //     {
      //         featureType: 'transit.station',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#fff'}]
      //     },
      //     {
      //         featureType: 'water',
      //         elementType: 'geometry',
      //         stylers: [{color: '#8accec'}]
      //     },
      //     {
      //         featureType: 'water',
      //         elementType: 'labels.text.fill',
      //         stylers: [{color: '#fff'}]
      //     },
      //     {
      //         featureType: 'water',
      //         elementType: 'labels.text.stroke',
      //         stylers: [{color: '#fff'}]
      //     }
      // ]
    }
      }
    },
  
    props: {
      mapdata:[Object, Array],
      setpolygonline:[Object, Array, Number],
    },
   
    mounted() {
        this.setLocationLatLng()
    },
   
    methods: {
      setLocationLatLng: function() {
        this.locations=[]
        this.setpolygonline.forEach((arrayitm, arridx)=>{
    });
    // console.log("this.setpolygonline",this.setpolygonline)
    // this.center = {
    //   lat :this.setpolygonline[0].paths[0].lat,
    //   lng :this.setpolygonline[0].paths[0].lng,
    // }
        // this.paths = this.setpolygonline              
          // this.mapdata.forEach(element => {
          
            
          //   this.locations.push({
          //     lat: element.latitude,
          //     lng: element.longitude
          //   })                    
          // })
          // this.mapdataout.forEach(element => {
          //   this.locationsout.push({
          //     lat: element.latitude,
          //     lng: element.longitude
          //   })                    
          // })
          // this.mapdataout.map_inout.inside.forEach(element => {
          //   this.locations.push({
          //     lat: element.latitude,
          //     lng: element.longitude
          //   })                    
          // })
          // console.log(this.mapdataout.map_inout.inside)
          this.mapdata.forEach(element => {
              
              this.locations.push({
                lat: element.latitude,
                lng: element.longitude
              })                    
            })
            this.paths = this.setpolygonline
      },
      openWindow(index) {
          this.infoWindow.position = { lat: this.mapdata[index].latitude, lng: this.mapdata[index].longitude }
          this.infoWindow.template = `<p><b>Hotel : </b> ${this.mapdata[index].hotel_name}</p><p><b>Supplier : </b> ${this.mapdata[index].supplier}</p><p><b>City : </b> ${this.mapdata[index].city_name}</p><p><b>Latitude : </b> ${this.mapdata[index].latitude}</p><p><b>Longitude : </b> ${this.mapdata[index].longitude}</p><p><b>Rating : </b> ${this.mapdata[index].rating}</p>`
          this.infoWindow.open = true
      },
      testFun: function(item,index){
        this.openWindow(index)
      },
    }
  }
  </script>
  