<template>
    <div>
        <div class="map__section">
            <div class="map__filter2" :class="[showfilter ? 'collapse__menu' : '']">
                <h4>Boundary</h4>
                <p>Select Your Response</p>
                <hr><div class="close__map__filter" @click="showfilter = !showfilter"><b-icon icon="list"
                    class="trans90"></b-icon></div>
                <b-card no-body class="mb-1" v-if="osmlist != 'null'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="primary" class="w-100 d-flex justify-content-between align-items-center">
                            <h5 v-if="osmlist != 'null'" class="city-text"><b-icon icon="geo-alt"></b-icon> Response for {{ this.$route.params.location }}</h5>
                            <b-icon id="popover-target-3" icon="exclamation-circle"></b-icon>
                            <b-popover target="popover-target-3" triggers="hover" placement="top">
                                RESPONSE FOR <b class="city-text">{{ this.$route.params.location }}</b>
                            </b-popover>
                            <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                        </b-button>
                    </b-card-header>
                    <b-collapse visible id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div v-if="osmmlist != 'null'"  class="osm__listing">
                                <div v-for="(item3, index) in osmlist" :key="index">                    
                                    <!-- <div class="supplier__listbox basicFinger"  @click="getIndex(index,item3)" :class="index === this.$route.params.index ? 'activeBG' : ''" > -->
                                    <div class="supplier__listbox basicFinger"  @click="getIndex(index,item3)" :class="index == $route.params.index || index == index_value || index == current_index ? 'activeBG' : ''">    
                                        <!-- <label class="catdivid" :for="`catdivid${index}`">
                                            <div>
                                                <p><b>Type : </b> <span>{{ item3.Type }}</span></p>
                                                <p><b>Location :</b> <span>{{ item3.Location }}</span></p>
                                            </div>
                                            <div>
                                                <input type="radio" name="locatn" :id="`catdivid${index}`">
                                            </div>
                                        </label> -->
                                        <div>
                                            <p><b>Type : </b> <span>{{ item3.Type }} </span> <span class="numrightspan">{{ item3.sq_km}}km²</span></p>
                                            <p><b>Location :</b> <span>{{ item3.Location }}</span></p>
                                            <!-- <p class="current">
                                                <b class=" verify" v-if="index == item3.current">current</b>
                                            </p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div>
                    <b-button variant="primary" class="save" @click="final">Save</b-button>
                    <!-- <b-button v-if="userType =='Admin'" variant="primary" class="save" @click="multiplefunction">Draw Custom Polygon</b-button> -->
                </div>
                <!-- <div>
                    <b-button variant="primary" class="save" @click="semi_final">Delete</b-button>
                </div> -->
            </div>
           
            <FilterGoogleMap :setpolygonline="setboundarydata" v-if="showFilterGoogleMap" />
            <PolygonMap ref="PolygonMapComponent" :citydata="city" :countrydata="country" v-if="showPolygonMap" />
            <div class="text-center" v-if="showloader">
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <div>
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
            </div>
            <div class="text-center" v-else>
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <p class="map__message">Please select appropriate polygon Co-ordinates.</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@axios'
import PolygonMap from '../googlemap/PolygonMap.vue'
import FilterGoogleMap from '../googlemap/FilterGoogleMap.vue'
import {BButton, BSpinner,
BCard,
BCardHeader,
BCollapse,
BCardBody,
BPopover,} from 'bootstrap-vue'

export default {
    // setup() {
    //     // const list = this.$route.params.id;
    //     // console.log("setup list", list)
    //     // console.log("setup this.$route", this.$route)
    //     // return{
    //     //     list
    //     // }

    // },
    data() {
        return {
            showfilter: false,
            showFilterGoogleMap:false,
            showPolygonMap:false,
            showloader:false,
            alertmsg:null,
            nullBase:null,
            osmlist:null,
            getclickindex:{
                city:null,
                country:null,
                state:null,
                index:null
            },
            list: {
                country: null,
                location: null,
                user: null
            },
            setboundarydata:[],
            savepolygon:[],
            deletedata:null,
            city:null,
            country:null,
            testlocation:null,
            testcountry:null,
            teststate:null,
            testindex:null,
            current_index:null,
            index_value:null,
        }
    },
    components: {
        BButton,
        FilterGoogleMap,
        PolygonMap,
        BSpinner,
        BCard,
        BCardHeader,
        BCollapse,
        BCardBody,
        BPopover,
    },
    methods: {
        osmdata:function(){
            axios.post('/osmresponse',{
                location:this.$route.params.location,
                state:this.$route.params.state,
                country:this.$route.params.country,
                index:this.$route.params.index,
                user:this.$route.params.user,
            },{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.osmlist = response.data.locations;
                this.testlocation = this.$route.params.location;
                this.testcountry = this.$route.params.country;
                this.teststate=this.$route.params.state;
                this.testindex = this.$route.params.index;
                this.current_index = response.data.index;
                this.index_value = response.data.index_value;

                if (this.testindex != 'null') {
                    console.log('condition 1')
                    this.finaltest(this.testlocation, this.testcountry,this.teststate, this.testindex);
                } else if (this.current_index !='null') {
                    console.log('condition 2')
                    this.finaltest(this.testlocation, this.testcountry,this.teststate, this.current_index);
                } else if (this.index_value !='null') {
                    console.log('condition 3')
                    this.finaltest(this.testlocation, this.testcountry,this.teststate, this.index_value);
                }
                if (response.data.locations) {
                    this.showloader = false;
                }
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        getIndex(ind,item){
            this.showloader=true
            this.showFilterGoogleMap = false;
            this.getclickindex.city = item.City
            this.getclickindex.state = item.State
            this.getclickindex.country = item.Country
            this.getclickindex.index = ind
            axios.post('/checkpolygon',this.getclickindex).then(response => {
                this.setboundarydata=response.data
                if(this.setboundarydata.length > 0 ){
                    this.showFilterGoogleMap = true;
                    this.list = this.$route.params.id;
                    this.showloader=false
                }            
            }).catch(error => {
                // console.log("Something went wrong in setpolygon()")
            })
        
        },
        finaltest(location,country,state,index){
            this.showloader=true
            this.showFilterGoogleMap = false;
            this.getclickindex.city = location,
            this.getclickindex.country = country,
            this.getclickindex.state=state,
            this.getclickindex.index = index,
            axios.post('/checkpolygon',this.getclickindex).then(response => {
                this.setboundarydata=response.data
                if(this.setboundarydata.length > 0 ){
                    this.showFilterGoogleMap = true;
                    this.list = this.$route.params.id;
                    this.showloader=false
                }            
            }).catch(error => {
                // console.log("Something went wrong in setpolygon()")
            })
        
        },
        final :function(){
            this.showloader=true
            this.showFilterGoogleMap = false;
            axios.post('/savecoordinates',this.getclickindex
                // headers: { 'Access-Control-Allow-Origin': '*' }
            ).then(response => {
                this.savepolygon=response.data
                this.showFilterGoogleMap = true;
                this.list = this.$route.params.id;
                this.showloader=false
                this.$swal({
                    icon: 'success',
                    title: 'Polygon Updated Successfully',
                    text: this.response,
                });
                // console.log("savepolygon",this.savepolygon)
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        semi_final :function(){
            axios.post('/deletedata',this.getclickindex
                // headers: { 'Access-Control-Allow-Origin': '*' }
            ).then(response => {
                this.deletedata=response
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        callChildFunction() {
            this.city=this.$route.params.location
            this.country=this.$route.params.country
            setTimeout(() => {
                this.$refs.PolygonMapComponent.CenterCoordinates();
            }, 1000);
        },
        worldpolygon: function(){
            axios.post('/worldpolygon',{
                city:this.$route.params.location,
                country:this.$route.params.country,
            },{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.worldpolygondata = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        multiplefunction() {
            this.showPolygonMap=true
            this.callChildFunction()
        },
    },
    mounted() {
        // this.list = this.$route.params.id;
        this.osmdata()
    }
}

</script>

<style>
select{
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
input:focus~label, 
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.input-field input, .input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
.bg-under{
    background-color: #00ae08 !important;
}
.bg-outside{
    background-color: #c81b00 !important;
}
.city-text{
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
}
#accordion-2{
    height: 450px;
    overflow-y: auto;
}
.map__filter p {
    font-size: 12px;
    font-weight: 500;
    color: inherit !important;
}
.supplier__listbox{
    border-bottom: thin solid #ddd;
    margin-bottom: 18px;
    padding: 12px;
    border-radius: 12px;
}
.supplier__listbox:hover{
    border-bottom: thin solid #ddd;
    background-color: #0d6efd;
    color: #fff !important;
}
.supplier__listbox:hover .numrightspan{
    color: #fff;
}
.basicFinger{
    cursor: pointer;
}
.card-body{
    padding: 8px !important;

    
}
.save{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1px;
}
.current{
    text-align: end;
}
.verify{
    color: green;
}
.activeBG{
    border-bottom: thin solid #ddd !important;
    background-color: #49f357 !important;
    color: #fff !important;
}
</style>
<script setup>
const userType = localStorage.getItem('userType');
</script>

