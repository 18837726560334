<template>
    <div class="row">
        <div class="col-lg-3">
            <div class="map__section">
                <h3 class="headingMain">HotelSearch API (HotelName)</h3>
                <p class="subheadingMain">Enter Details Here To Fetch Json Response Of HotelCode List Along With Suppliers</p>
                <hr>
                <div class="form__map">
                    <div class="form__group">
                        <div>
                            <label class="margBase">
                                <input type="radio" class="h-auto" v-model="selectedOption" value="Hotels Master" /><b
                                    class="checkmark">Hotels Master (Otrams)</b>
                            </label>
                        </div>
                        <div>
                            <label class="margBase">
                                <input type="radio" class="h-auto" v-model="selectedOption" value="Suppliers Data" /><b
                                    class="checkmark">Suppliers Data</b>
                            </label>
                        </div>
                        <div class="input-field">
                            <b-form-select v-model="selectedCountry" class="form-control" :options="countryData"
                                @change="getcustompolygonlist"></b-form-select>
                            <label>Select Country</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedValue" @keyup.enter="applymapfilter" required
                                spellcheck="false" autocomplete="off" list="optionsList"></b-form-input>
                            <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData"></b-form-datalist>
                            <label>Enter City</label>
                        </div>
                    </div>
                    <div class="form__group">
                        <div class="input-field">
                            <b-form-input type="text" v-model="selectedHotelname" @keyup.enter="applymapfilter"
                                required spellcheck="false" autocomplete="off" list="optionsList"></b-form-input>
                            <b-form-datalist id="optionsList" v-if="showDatalist" :options="cityData"></b-form-datalist>
                            <label>Enter HotelName</label>
                        </div>
                    </div>
                </div>
                <div class="navigation__filter text-end">
                    <b-button @click="applymapfilter">Show Data &nbsp;</b-button>
                </div>
                <div class="close__map__filter" @click="showfilter = !showfilter"><b-icon icon="list"
                        class="trans90"></b-icon></div>
            </div>
        </div>
        <div class="col-lg-9 p-4">
            <div class="card p-2 baseheight">
                <div v-if="loading" >
                    <div class="loader"></div>
                    <h3 align="center" v-if="loading" >Loading Data...</h3>
                </div>
                <div v-if="!loading">
                    <h4>Response data:</h4>
                    <pre>{{ this.hoteldatabyname }}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const userType = localStorage.getItem('userType');
</script>

<script>
import axios from '@axios'
import GoogleMap from '../googlemap/GoogleMap.vue'
import {
    BButton,
    BFormInput,
    BFormSelect,
    BPopover,
} from 'bootstrap-vue'

export default {
    data() {
        return {
            loading: false,
            selectedHotelname: null,
            selectedOption: "Hotels Master",
            selectedValue: null,
            selectedCountry: null,
            min_lat: '',
            max_lat: '',
            min_long: '',
            max_long: '',
            showfilter: false,
            showfilter2: true,
            countryData: [],
            hoteldatabyname: null,
        }
    },
    components: {
        BFormInput,
        BButton,
        BFormSelect,
    },
    methods: {
        callMethodFromOtherComponent() {
            GoogleMap.openWindow();
        },
        countryGetList: function () {
            axios.get('/country_list', {
            }).then(response => {
                this.countryData = response.data
            }).catch(error => {
                console.log("Something went wrong in countryGetList()")
            })
        },
        applymapfilter() {
            this.loading=true,
            axios.post('/hotelname', {
                city: this.selectedValue,
                country: this.selectedCountry,
                hotelname: this.selectedHotelname,
                data: this.selectedOption,
            }, {
            }).then(response => {
                this.loading=false,
                this.hoteldatabyname = response.data
            }).catch(error => {
                this.showloader = true
                if (this.selectedValue != null && this.selectedCountry != null && this.setpolygondata == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Special Character Not allowed"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null && this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City and Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedValue == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "City not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    // this.showGoogleMap = false
                    this.showloader = false
                } else if (this.selectedCountry == null) {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Country not selected"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = false
                    this.showloader = false
                } else {
                    document.getElementById("alertshowhide").classList.toggle("active__alert")
                    this.alertmsg = "Suppliers Data Not Found"
                    setTimeout(function () {
                        this.showalertmsg = false
                        document.getElementById("alertshowhide").classList.toggle("active__alert")
                    }, 8000)
                    // this.showfilter = !this.showfilter
                    // this.showfilter2 = !this.showfilter2
                    this.showGoogleMap = true
                    this.showloader = false
                }
            })
        },
        closelist() {
            this.showfilter2 = !this.showfilter2
            this.showfilter = !this.showfilter
        },
    },
    mounted() {
        this.countryGetList();
    }
}

</script>

<style>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  margin-top: 200px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.infogap {
    margin-left: 10px;
}

input:focus~label,
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.checkHeight {
    height: 12px !important;
}

.margBase {
    margin: 0 0 1rem 0;
}

.checkHeight input:checked~.checkmark {
    background-color: #2196F3 !important;
}

.input-field input,
.input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}

.checkmark {
    color: rgb(29, 81, 224);
    margin-left: 10px;
}

.bg-under {
    background-color: #00ae08 !important;
}

.bg-outside {
    background-color: #c81b00 !important;
}

.bg-info {
    background-color: blue !important;
}

.city-text {
    text-transform: uppercase;
}

.green {
    color: #00ae08;
}

.red {
    color: #c81b00;
}

.blue {
    color: blue
}

input[type="checkbox"] {
    transform: scale(1.5);
}

.destination_save {
    width: 140px;
    margin-left: 10px;
    margin-right: 10px;
}

.look {
    color: black;
}

.search1 {
    height: 35px;
    width: 290px;
}

.red {
    color: #c81b00;
}

.black {
    color: black;
}
.baseheight{
    height:80vh !important;
    overflow-y: auto;
}
</style>