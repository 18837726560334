<template>
    <div class="navbar__section">
        <div class="menu__toggle d-block d-md-none" id="menu__toggles" @click="togglemenu">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="row">
            <div class="col-lg-9 pad25rem bbThin">
                <!-- <h3>Hi, {{ username }}</h3>
                <p>Let's track your reports daily!</p> -->
            </div>
            <div class="col-lg-3 d-none d-md-block pad25rem blueBase bbThin">
                <div class="other__navpart">
                    <!-- <div class="search__icon nav__div"><b-icon icon="search"></b-icon></div>
                    <div class="notification__icon nav__div"><b-icon icon="bell"></b-icon><span>3</span></div> -->
                    <h3 class="d-none">Hi, {{ username }}</h3>
                    <div class="profile__icon nav__div">
                        <img class="image_size" :src="Imageurl" alt="">
                        <div class="profile__box">
                            <span class="shp_tri"></span>
                            <div class="pt-0" ><router-link  to="/userprofile"><b-icon icon="person-check"></b-icon>Profile</router-link></div>
                            <div><b-icon icon="gear"></b-icon>Settings</div>
                            <div><b-button class="size_button" variant="primary" @click="logout"><b-icon icon="box-arrow-right"></b-icon>Logout</b-button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from '@axios'
import { BButton }from "bootstrap-vue"
export default {
    components: {
        BButton,
    },
    data() {
    return {
        username: 'Test User',
        logged:null,
        }
    
    },
    methods: {
        logout: function(){
            axios.post('/logout/',{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.logged = response.data
                localStorage.removeItem('token');
                this.$router.push({name: 'login'}) 
            }).catch(error => {
                console.log("Something went wrong during logout process")
            })
        },
        togglemenu(){
            document.getElementById("toggleMenu").classList.toggle("sid__active")
            window.addEventListener('click', function(e){   
            if (document.getElementById('menu__toggles').contains(e.target)){
                // Clicked in box
            } else{
                // Clicked outside the box
                document.getElementById("toggleMenu").classList.remove("sid__active")
            }
            })
        },
        userName(){
            // axios.post('Username_active', {
            //     headers: { 'Access-Control-Allow-Origin': '*' }
            // }).then(response => {
            //     this.username = response.data.username
            //     console.log("response.data", this.username)
            // }).catch(error => {
            //     console.log("Something went wrong in countryGetList()")
            // })
            var userData = localStorage.getItem('userData')
            if(userData){
                this.username = userData;
            }
            var userImage = localStorage.getItem('image_url')
            if(userImage){
                this.Imageurl=userImage;
            }
        }
    
    },
    mounted() {
        this.userName()
    }
}
</script>
<style>
.image_size{
    border-radius:50px;
    height: 40px !important;
    width: 40px !important;
}
.size_button{
    width:90px;
    height:30px;
    font-size: x-small !important;
}
</style>