<template>
    <div>
        <b-row>
            <b-col lg="5">
                <div class="ssbx bg_w bor_lgreen">
                    <div class="d-flex justify-content-end">
                        <div class="text-end marglef" @click="generateToken"><span class="cop__txt btn__sm">Generate Token
                                <b-icon icon="clipboard-check"></b-icon></span>
                        </div>
                        <div class="text-end marglef" @click="copyToClipboard"><span class="cop__txt btn__sm">Click to copy
                                <b-icon icon="clipboard-check"></b-icon></span>
                        </div>
                    </div>
                    <b-overlay :show="overlayShow" rounded="sm">
                        <template #overlay>
                            <div class="text-center">
                                <p v-if="blank" id="cancel-label">Please generate the token.</p>
                                <p v-else-if="pass" id="cancel-label">Text copied to clipboard!</p>
                                <p v-else id="cancel-label">Failed to copy text to clipboard.</p>
                            </div>
                        </template>
                        <b-input-group class="mt-3">
                            <b-form-input v-model="baseToken" class="cus__input" type="text" ref="textField"
                                readonly></b-form-input>

                        </b-input-group>
                    </b-overlay>
                    <h5 class="basecurrent" v-if="prevTokenCheck">Current Token : 
                        <span ref="textToCopy">{{ prevToken }}</span> 
                        
                        <span @click="copyToCurrentClipboard" class="basicMarginLeft"><b-icon icon="clipboard-check" class="ml-2"></b-icon></span></h5>

                        <h5 class="basecurrent" v-else>Code Copied...</h5 > 
                </div>
            </b-col>
            <b-col lg="7"></b-col>
        </b-row>

    </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.8/clipboard.min.js"></script>
<script>
import axios from '@axios'
// import Clipboard from 'clipboard';
import { BRow, BCol, BFormInput, BInputGroup, BIcon, BOverlay } from 'bootstrap-vue'
export default {
    components: {
        BRow,
        BCol,
        BFormInput,
        BInputGroup,
        BIcon,
        BOverlay
    },
    data() {
        return {
            baseToken: '',
            text: 'This will get copied!',
            blank: false,
            overlayShow: false,
            pass:true,
            prevToken:localStorage.getItem('token'),
            newtoken:'',
            prevTokenCheck: true,
        }
        
    },
    methods: {
        generateToken:function(){
            axios.post('createtoken/',{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response=>{
                if(this.baseToken != ''){
                    this.prevToken= this.baseToken
                }else{
                    this.prevToken= localStorage.getItem('token')
                }
                localStorage.setItem('token', response.data.token)
                this.baseToken = localStorage.getItem('token')
            }).catch(error=>{
                alert("some error")
            })
        },
        copyToCurrentClipboard() {
            // Get the paragraph element using the "ref" attribute
            this.prevTokenCheck =false;
      const paragraphElement = this.$refs.textToCopy;

// Create a range to select the text in the paragraph
const range = document.createRange();
range.selectNode(paragraphElement);

// Get the Selection object to interact with the clipboard
const selection = window.getSelection();
selection.removeAllRanges();
selection.addRange(range);

// Copy the selected text to the clipboard
document.execCommand('copy');

// Remove the selection to avoid unwanted highlighting
selection.removeAllRanges();
setTimeout(() => {
    this.prevTokenCheck =true;
                    }, 2000); 


// You can optionally provide user feedback here (e.g., show a tooltip or alert)
// alert('Text has been copied to the clipboard: ' + paragraphElement.textContent);
            
        },
        copyToClipboard() {
            const textField = this.$refs.textField;
            textField.select(); // Select the text field content
            

            if(this.baseToken == ''){
                
                this.blank=true
                setTimeout(() => {
                    this.blank=false;
                    }, 2000); 
            }
            else{

                try {
                    document.execCommand('copy'); // Copy the selected text to clipboard
                    // this.baseToken = 'Text copied to clipboard!'
                    this.overlayShow = true
                    this.pass = true
                    setTimeout(() => {
                        this.overlayShow = false
                        this.pass = false
                    }, 2000);
                } catch (error) {
                    // this.baseToken = 'Failed to copy text to clipboard.'
                    this.overlayShow = true
                    this.pass = false
                    setTimeout(() => {
                        this.overlayShow = false
                        this.pass = false
                    }, 2000);
                }
            }
            
        },
        // showToken() {
        //     this.baseToken = localStorage.getItem('token')
        // }
    },
    mounted() {
        // this.showToken()
    }

}
</script>
<style>
.marglef {
    margin-left: 10px;
}

.basecurrent {
    font-size: 16px !important;
}
.basicMarginLeft{
    margin-left: 10px !important;
}
</style>
