<template>
    <div>
        <div class="map__section">
            <div class="map__filter" :class="[showfilter ? 'collapse__menu' : '']">
                <h4>verifyplotting</h4>
                <hr>
                <b-card no-body class="mb-1" v-if="osmlist != 'null'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="primary" class="w-100 d-flex justify-content-between align-items-center">
                            <h5 v-if="osmlist != 'null'" class="city-text"><b-icon icon="geo-alt"></b-icon> Response for {{ this.$route.params.city }}</h5>
                            <b-icon id="popover-target-3" icon="exclamation-circle"></b-icon>
                            <b-popover target="popover-target-3" triggers="hover" placement="top">
                                RESPONSE FOR <b class="city-text">{{ this.$route.params.city }}</b>
                            </b-popover>
                            <b-icon class="text-white" icon="chevron-double-down"></b-icon>
                        </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div v-if="osmmlist != 'null'"  class="osm__listing">
                                <div v-for="(item, index) in agentdatatoshow" :key="index">                    
                                    <div class="supplier__listbox basicFinger" @click="openwindowdata(item,index)">
                                        <div>
                                            <p><span class="look1">{{ item.hotel_name }} <span class="look2">({{ item.supplier }})</span></span></p>
                                            <!-- <p><b>Hotel_Name : </b> <span>{{ item.hotel_name }}</span></p>
                                            <p><b>Supplier :</b> <span>{{ item.supplier }}</span></p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <b-button class="button_size d-none" variant="primary" @click="fetchaccurateplottingdata">Search</b-button>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
           
            <ShowPlotting ref="showPlottingComponent" :mapdata="agentdatatoshow" :setpolygonline="setpolygondata" :openpopup="popupdata"   v-if="showShowPlotting" />

            <div class="text-center" v-if="showloader">
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <div>
                    <b-spinner variant="primary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="secondary" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="danger" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="warning" :small="true" type="grow"></b-spinner> &nbsp;
                    <b-spinner variant="success" :small="true" type="grow"></b-spinner>
                </div>
            </div>
            <div class="text-center" v-else>
                <img src="/static/img/map.jpg" class="map__img" alt="map">
                <p class="map__message">Please verify your suppliers</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@axios'
import ShowPlotting from '../googlemap/ShowPlotting.vue'
import {BButton, BSpinner,
BCard,
BCardHeader,
BCollapse,
BCardBody,
BPopover,} from 'bootstrap-vue'

export default {
    data() {
        return {
            showfilter: false,
            showShowPlotting:false,
            showloader:false,
            alertmsg:null,
            nullBase:null,
            agentdatatoshow:null,
            agentdatatoshowbackup:null,
            filterplotting:null,
            setpolygondata:[],
            selectedValueKM: null,
        }
    },
    components: {
        BButton,
        ShowPlotting,
        BSpinner,
        BCard,
        BCardHeader,
        BCollapse,
        BCardBody,
        BPopover,
    },
    methods: {

        updateGraph(){
            console.log("ref", this.$refs.showPlottingComponent)
        },
        fetchtoshow:function(){
            console.log("this is fetchtoshow function")
            axios.post('fetchagentdata',{
                city:this.$route.params.city,
                country:this.$route.params.country,
                agentname:this.$route.params.name,
            },{
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.agentdatatoshow=response.data.data
                this.agentdatatoshowbackup=response.data.data
                this.showloader=false;
                this.showShowPlotting = true;
            }).catch(error => {
                // console.log("Something went wrong in action response")
            })
        },
        setpolygon: function(){
            this.showloader=true
            axios.post('/polygon/', {
                city: this.$route.params.city,
                country: this.$route.params.country,
                KM:this.selectedValueKM,
            }, {
                // headers: { 'Access-Control-Allow-Origin': '*' }
            }).then(response => {
                this.setpolygondata=response.data.coordinates
                this.min_lat=response.data.min_lat
                this.max_lat=response.data.max_lat
                this.min_long=response.data.min_long
                this.max_long=response.data.max_long
                if (this.setpolygondata != null){
                    this.fetchtoshow()
                }        
            }).catch(error => {
                console.log("Something went wrong in setpolygon()")
            })
        },
        openwindowdata(item,index){
            this.$refs.showPlottingComponent.testFun(item,index)
        }
    },
    // computed:{
    //     fetchaccurateplottingdata(){
    //     this.agentdatatoshow=null
    //     this.agentdatatoshow=this.agentdatatoshowbackup
    //     if(this.filterplotting){
    //         const datafiltered = Object.fromEntries(
    //             Object.entries(this.agentdatatoshow)
    
    //             .filter(([key,value]) => value.hotel_name.toLowerCase().includes(this.filterplotting.toLowerCase()))
    //             // .filter(([key,value]) => value.hotel_name.toLowerCase() === this.filterhotel1.toLowerCase())
    //         );
    //         this.agentdatatoshow=datafiltered
    //         return this.agentdatatoshow

    //     }else{
    //         this.agentdatatoshow=this.agentdatatoshowbackup
    //         return this.agentdatatoshow;
    //     }
    //     },
    // },
//     computed: {
//     fetchaccurateplottingdata() {
//         let filteredData = this.agentdatatoshow; // Make a copy of the original data

//         if (this.filterplotting) {
//             console.log(this.filterplotting)
//             console.log(filteredData)
//             filteredData = Object.fromEntries(
//                 Object.entries(this.agentdatatoshow)
//                     .filter(([key, value]) => value.hotel_name.toLowerCase().includes(his.filterplotting.toLowerCase()))
//             );
//         }
//         return filteredData;
//     }
// },
    mounted() {
        this.setpolygon() 
    },
}

</script>

<style>
select{
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
input:focus~label, 
input:valid~label,
select:focus~label,
select:valid~label {
    top: 0;
    left: 15px;
    font-size: 13px;
    padding: 0 2px;
    background: #fff;
}

.input-field input, .input-field select {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    font-size: 15px;
    padding: 0 15px;
    border: 2px solid #378ad6;
    background: transparent;
    color: #000;
    outline: none;
}
.bg-under{
    background-color: #00ae08 !important;
}
.bg-outside{
    background-color: #c81b00 !important;
}
.city-text{
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
}
#accordion-2{
    height: 450px;
    overflow-y: auto;
}
.map__filter p {
    font-size: 12px;
    font-weight: 500;
    color: inherit !important;
}
.supplier__listbox{
    border-bottom: thin solid #ddd;
    margin-bottom: 18px;
    padding: 12px;
    border-radius: 12px;
}
.supplier__listbox:hover{
    border-bottom: thin solid #ddd;
    background-color: #0d6efd;
    color: #fff !important;
}
.basicFinger{
    cursor: pointer;
}
.card-body{
    padding: 8px !important;
}
.save{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1px;
}
.current{
    text-align: end;
}
.verify{
    color: green;
}
.look1{
    color: #00ae08;
}
.look2{
    color:black;
}
</style>

// this.$refs.showPlottingComponent.openpopup = this.popupdata
// console.log("popupdata",this.popupdata)
// console.log("popupindex",this.popupindex)
// console.log("ref", this.$refs.showPlottingComponent.testFun())