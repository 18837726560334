<template>
  <div id="app">
    <div class="main__section">
      <sidemenu v-if="$route.name!='login' && $route.name!='register'"/>      
      <div :class="$route.name=='login' ? '':'sidebar__right' && $route.name=='register' ? '':'sidebar__right'" >
        <topnavbarmenu v-if="$route.name!='login' && $route.name!='register'"/>
      <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidemenu from '../src/views/layout/Sidemenu.vue'
import Topnavbarmenu from '../src/views/layout/Topnavbar.vue'
export default {
  name: 'App',
  components: {
    Sidemenu,
    Topnavbarmenu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
